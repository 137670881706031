import React from 'react'
import * as S from '../study-plans.styles'

function StudyPlansExpandComponent(props) {
  const { isActive } = props

  return isActive ? (
    <S.StudyPlansUpOutlinedIcon />
  ) : (
    <S.StudyPlansDownOutlinedIcon />
  )
}

export default StudyPlansExpandComponent
