import React, { useCallback, useEffect, useState } from 'react'
import { Typography, Skeleton, Form } from 'antd'
import Select from '~/shared/Select'
import Card from './mentorship-card.component'
import services from '../mentorship.services'

import * as S from '../mentorship.style'

function MentorShipComponent(props) {
  const { teacherList, setStep, setSelectData } = props
  const { Title, Text } = Typography
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [disciplinesCombobox, setDisciplinesCombobox] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [lastPage, setLastPage] = useState(null)
  const [discipline, setDiscipline] = useState(null)

  const getData = useCallback(async () => {
    setLoading(true)

    const response = await services.getDisciplines(discipline, pageNumber)
    const { data } = response

    if (discipline !== null && pageNumber === 1) {
      setData([])
      teacherList.length = 0
      data.content.map((p) => teacherList.push(p))
      setData(teacherList)
    } else {
      data.content.map((p) => teacherList.push(p))
      setData(teacherList)
    }

    setLastPage(data.totalPages)
    setLoading(false)
  }, [discipline, pageNumber, teacherList])

  useEffect(() => {
    getData()
  }, [getData])

  useEffect(() => {
    async function getDisciplinesCombobox() {
      const response = await services.getDisciplinesCombobox()
      setDisciplinesCombobox(response.data)
    }
    getDisciplinesCombobox()
  }, [])

  const handleMoreTeachers = () => {
    if (pageNumber < lastPage) {
      setPageNumber(pageNumber + 1)
    }
  }

  return (
    <S.EssayWrapper>
      <Title level={4} style={{ color: '#404040' }}>
        Mentoria
      </Title>

      <Text style={{ color: '#404040' }}>
        Selecione o perfil do professor que deseja agendar uma mentoria.
      </Text>
      <Form.Item
        label={<Text fontFamily="Open Sans">Filtrar por disciplina</Text>}
        style={{
          width: '300px',
          margin: '0',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Select
          size="large"
          defaultValue={null}
          bordered={false}
          onChange={(disciplineName) => {
            if (disciplineName === null) {
              setData([])
              teacherList.length = 0
            }
            setDiscipline(disciplineName)
            setPageNumber(1)
          }}
          listItemHeight={10}
          listHeight={250}
        >
          <Select.Option value={null}>Todas</Select.Option>
          {disciplinesCombobox.map((discipline) => (
            <Select.Option key={discipline.id} value={discipline.name}>
              {discipline.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <S.ListContainer>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            {data.length === 0 ? (
              <Title level={4} style={{ color: '#404040' }}>
                Ainda não existem professores cadastrados no momento
              </Title>
            ) : (
              <>
                {data.map((info) => (
                  <Card
                    key={info.id}
                    data={info}
                    setStep={setStep}
                    setSelectData={setSelectData}
                    resetArray={teacherList}
                    resetData={setData}
                  />
                ))}
                {pageNumber < lastPage && (
                  <S.Footer>
                    <S.ButtonLoadMore
                      type="default"
                      size="large"
                      shape="round"
                      onClick={handleMoreTeachers}
                    >
                      Carregar Mais
                    </S.ButtonLoadMore>
                  </S.Footer>
                )}
              </>
            )}
          </>
        )}
      </S.ListContainer>
    </S.EssayWrapper>
  )
}

export default MentorShipComponent
