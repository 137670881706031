import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import MathJax from 'react-mathjax-preview'

import { Row, Col, Form, Input, Divider, Space, Skeleton, Grid } from 'antd'

import api from '../student-form.services'

import Layout from '~/shared/Layout'
import { Text } from '~/shared/Typography'

import EditorTinyAlternatives from './editor-tinyAlternatives'

import * as S from '../student-form.styles'
import useAuth from '~/hooks/useAuth'

function StudentFormTakeQuestionsViewModal(props) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({ tags: [] })
  const auth = useAuth()

  const { id } = props
  const [form] = Form.useForm()
  const history = useHistory()

  const { tags, replyMessage, message } = data

  const { useBreakpoint } = Grid
  const screen = useBreakpoint()
  const { md } = screen

  const getDiscipline = async () => {
    setLoading(true)
    try {
      const response = await api.get(id)
      const { data } = response
      setData(data)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDiscipline()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data) {
      const createDate = data.createDate?.split(' ')[0]
      const replyDate = data.replyDate?.split(' ')[0]

      form.setFieldsValue({ ...data, createDate, replyDate })
    }
  }, [data, form])

  return (
    <>
      {loading && <Skeleton />}

      {!loading && (
        <>
          <Layout className="min-height-empty">
            <S.StyledContentWrapper className="view-doubts">
              <Form
                form={form}
                layout="vertical"
                initialValues={{ active: true }}
              >
                <Row gutter={16}>
                  <Col xs={18} md={19} sm={18}>
                    <Text
                      fontSize="32px"
                      fontWeight="bold"
                      color="#434343"
                      style={{
                        marginBottom: '15px',
                        background: '#fff',
                        display: 'block',
                        textTransform: 'capitalize',
                      }}
                    >
                      {data.subject?.toLowerCase()}
                    </Text>
                  </Col>

                  {!auth.user.studentFreemium && (
                    <Col xs={6} md={5} sm={6}>
                      <S.StudentFormTag
                        backgroundColor="#403edc"
                        cursor="pointer"
                        padding="10px"
                        width={md ? '170px' : '40px'}
                        marginBottom="15px"
                        onClick={() => {
                          history.push('/duvidas/tirar')
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <S.StudentFormQuestionOutlined />

                        {md && (
                          <Text style={{ color: '#fff', fontSize: '15px' }}>
                            Tirar uma dúvida
                          </Text>
                        )}
                      </S.StudentFormTag>
                    </Col>
                  )}
                </Row>

                <Row gutter={16}>
                  <Col xs={24} md={8}>
                    <Form.Item name="disciplineName" label="Disciplina">
                      <Input placeholder="Disciplina" maxLength={50} disabled />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    <Form.Item name="status" label="Status">
                      <Input placeholder="Status" maxLength={50} disabled />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16} align="middle" className="message-wrapper">
                  <Col xs={24} md={24}>
                    <Form.Item name="message" label="Dúvida">
                      <MathJax math={message} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16} align="middle">
                  <Col xs={24} md={8}>
                    <Form.Item name="createDate" label="Criado em">
                      <Input placeholder="Criado em" maxLength={50} disabled />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={8}>
                    {tags?.length > 0 && (
                      <>
                        <Space>
                          <Text
                            style={{
                              fontSize: '15px',
                              color: '#000',
                              marginRight: '10px',
                            }}
                          >
                            Termos:
                          </Text>
                        </Space>

                        {tags.map((tag, i) => {
                          return (
                            <S.StudentFormTag key={i} backgroundColor="#939393">
                              <Text style={{ color: '#fff' }}>{tag}</Text>
                            </S.StudentFormTag>
                          )
                        })}
                      </>
                    )}
                  </Col>
                </Row>

                {replyMessage && (
                  <>
                    <Row gutter={16} align="middle">
                      <Col xs={24} md={6} className="col-answer">
                        <Text fontSize="18px" fontWeight="bold" color="#434343">
                          Resposta
                        </Text>
                      </Col>
                    </Row>

                    <Divider />

                    <Row gutter={16} align="middle">
                      <Col xs={24} md={24}>
                        <Form.Item name="replyMessage" label="Resposta">
                          <EditorTinyAlternatives
                            valueResposta={replyMessage}
                            disabled
                            view
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16} align="middle">
                      <Col xs={24} md={8}>
                        <Form.Item name="repliedBy" label="Respondido por">
                          <Input
                            placeholder="Respondido por"
                            maxLength={50}
                            disabled
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} md={8}>
                        <Form.Item name="replyDate" label="Respondido em">
                          <Input
                            placeholder="Respondido em"
                            maxLength={50}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </Form>
            </S.StyledContentWrapper>
          </Layout>
        </>
      )}
    </>
  )
}

export default StudentFormTakeQuestionsViewModal
