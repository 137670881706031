import ShiftsContainer from './containers/shifts.container'

const routes = [
  {
    path: '/plantoes',
    component: ShiftsContainer,
    authenticated: true,
  },
]

export default routes
