import React from 'react'
import { Row, Col } from 'react-flexbox-grid'

import useData from '~/pages/subscription/hooks/useData'
import LoaderButton from '~/shared/LoaderButton'

import * as S from './styles'

function StepsButton(props) {
  const {
    validateInputs,
    currentStep,
    previousStep,
    nextStep,
    style,
    setCurrentStep,
  } = props

  const { checkoutFinishStep } = useData()

  function previous(event) {
    event.preventDefault()

    // previousStep()
    setCurrentStep((state) => state - 1)
  }

  async function next(event) {
    event.preventDefault()
    if (await validateInputs()) {
      nextStep()
    }
  }

  return (
    <S.StepsButtonWrapper>
      <Row end="xs" style={style}>
        <Col xs={6} md={2}>
          <S.StepsButtonPrevious
            disabled={currentStep === 1}
            onClick={previous}
          >
            Voltar
          </S.StepsButtonPrevious>
        </Col>

        <Col xs={6} md={3} className={checkoutFinishStep && 'loading-active'}>
          {currentStep === 2 ? (
            <S.StepsButtonNext type="submit" disabled={checkoutFinishStep}>
              {checkoutFinishStep && <LoaderButton />} Finalizar Compra
            </S.StepsButtonNext>
          ) : (
            <S.StepsButtonNext onClick={next}>Avançar</S.StepsButtonNext>
          )}
        </Col>
      </Row>
    </S.StepsButtonWrapper>
  )
}

export default StepsButton
