import styled, { css } from 'styled-components'
import media from 'styled-media-query'

const SubscriptionPlansWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(1fr);
    gap: ${theme.spacings.small};
    margin-top: ${theme.spacings.medium};

    ${media.greaterThan('medium')`
      grid-template-columns: repeat(2, 1fr);
    `}

    ${media.greaterThan('large')`
      grid-template-columns: repeat(3, 1fr);
    `}
  `}
`

const SubscriptionPlansItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: ${theme.border.radius};
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    transition: all 0.5s ease;

    :hover {
      transform: scale(1.02);
    }
  `}
`

const SubscriptionPlansDivider = styled.div``

const SubscriptionPlansHeader = styled.div`
  ${({ theme, backgroundColor }) => css`
    display: flex;
    justify-content: space-between;
    padding: ${theme.spacings.xsmall};
    border-top-left-radius: ${theme.border.radius};
    border-top-right-radius: ${theme.border.radius};
    background-color: ${theme.colors[backgroundColor]};
  `}
`

const SubscriptionPlansTitle = styled.h3`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.small};
    color: ${theme.colors.white};
    margin: 0;
    padding: 0;
    padding-right: 15px;
  `}
`

const SubscriptionPlansSubtitle = styled.p`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${color === 'secondary'
      ? `${theme.colors.white}`
      : `${theme.colors.secondary}`};
    margin: 0;
    padding: 0;
    padding-right: 15px;
  `}
`

const SubscriptionPlansContent = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall};
    text-align: center;
  `}
`

const SubscriptionPlansMonetary = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacings.xxsmall};
    vertical-align: super;
  `}
`

const SubscriptionPlansPrice = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.large};
    font-weight: ${theme.font.bold};
  `}
`

const SubscriptionPlansMonth = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxsmall};
  `}
`

const SubscriptionPlansMonthBold = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
  `}
`

const SubscriptionPlansDescont = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.white};
    opacity: 0.8;
  `}
`

const SubscriptionPlansDescontLine = styled.span`
  text-decoration: line-through;
`

const SubscriptionPlansBenefitsList = styled.ul`
  ${({ theme }) => css`
    padding: 0 ${theme.spacings.xxsmall};
    padding-top: ${theme.spacings.xsmall};
    padding-bottom: ${theme.spacings.xlarge};
    text-align: left;
    list-style: none;
  `}
`

const SubscriptionPlansBenefitsItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacings.xxsmall} 0px;
    font-size: ${theme.font.sizes.xsmall};

    ::before {
      content: '';
      width: 5px;
      height: 5px;
      margin-right: ${theme.spacings.xxsmall};
      border-radius: 50%;
      background-color: ${theme.colors.secondary};
    }
  `}
`

const SubscriptionPlansFooter = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall};
    color: ${theme.colors.text};
    text-align: center;
  `}
`

const SubscriptionPlansDuration = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xxsmall};
    font-size: ${theme.font.sizes.xxsmall};
    opacity: 0.8;
  `}
`

const SubscriptionPlansButton = styled.button`
  ${({ theme, backgroundColor }) => css`
    width: 100%;
    padding: ${theme.spacings.xsmall};
    border-radius: ${theme.border.radius};
    background-color: ${theme.colors[backgroundColor]};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.white};
    cursor: pointer;
  `}
`

export {
  SubscriptionPlansWrapper,
  SubscriptionPlansItem,
  SubscriptionPlansDivider,
  SubscriptionPlansHeader,
  SubscriptionPlansTitle,
  SubscriptionPlansSubtitle,
  SubscriptionPlansContent,
  SubscriptionPlansMonetary,
  SubscriptionPlansPrice,
  SubscriptionPlansMonth,
  SubscriptionPlansMonthBold,
  SubscriptionPlansDescont,
  SubscriptionPlansDescontLine,
  SubscriptionPlansBenefitsList,
  SubscriptionPlansBenefitsItem,
  SubscriptionPlansFooter,
  SubscriptionPlansDuration,
  SubscriptionPlansButton,
}
