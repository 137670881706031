import React, { useCallback, useEffect, useState } from 'react'
import Card from './modalLessonsCombo-card.component'
import api from '~/services/api'
import * as S from '../modalLessonsCombo.styles'

function ModalLessonsCombo({ setCurrentStep }) {
  const [select1, setSelect1] = useState(false)
  const [select2, setSelect2] = useState(true)
  const [loading, setLoading] = useState(false)
  const [subscriptionPlans, setSubscriptionPlans] = useState([])

  const classCombo = [
    { id: 1, value: 3, amount: 180, title: 'Aulas Particulares' },
    { id: 2, value: 5, amount: 250, title: 'Aulas Particulares' },
    { id: 3, value: 8, amount: 360, title: 'Aulas Particulares' },
  ]

  const singleClass = [
    { id: 1, value: 1, amount: 100, title: 'Aula Particular' },
  ]

  const getSubscriptionPlans = useCallback(async () => {
    setLoading(true)
    const { data } = await api.get('/subscriptionplan/active/38')

    // const formattedData = data?.filter(
    //   (arg) => arg.displayInstallmentAmount === true && arg.id !== 38,
    // )

    setSubscriptionPlans(data)
    setLoading(false)
  }, [])

  // useEffect(() => {
  //   getSubscriptionPlans()
  // }, [getSubscriptionPlans])

  // console.log('subscriptionPlans', subscriptionPlans)

  if (loading) {
    return <></>
  }
  return (
    <S.Container>
      <S.Header>
        <S.BigText>
          Você não possui créditos de Aulas Particulares disponíveis
        </S.BigText>
      </S.Header>
      <S.EmptySpace />
      <S.SmallText>Como deseja contratar?</S.SmallText>
      <S.EmptySpace />
      <S.SelectContainer>
        {/* <S.SelectButton
          type="primary"
          size="large"
          shape="round"
          isSelected={select1}
          onClick={() => {
            setSelect1(true)
            setSelect2(false)
          }}
        >
          Pacote de Aulas
        </S.SelectButton> */}
        <S.SelectButton
          type="primary"
          size="large"
          shape="round"
          isSelected={select2}
          onClick={() => {
            setSelect1(false)
            setSelect2(true)
          }}
        >
          Aula Única
        </S.SelectButton>
      </S.SelectContainer>
      <S.CombosContainer>
        {select1 &&
          classCombo.map((info) => (
            <Card key={info.id} data={info} setCurrentStep={setCurrentStep} />
          ))}
        {select2 &&
          singleClass.map((info) => (
            <Card key={info.id} data={info} setCurrentStep={setCurrentStep} />
          ))}
      </S.CombosContainer>
    </S.Container>
  )
}

export default ModalLessonsCombo
