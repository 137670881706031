import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import { Skeleton } from 'antd'

import services from '../test.services'
import TestFeedback from '../components/test-feedback.component'
import notification from '~/shared/Notification'

function TestQuestionsContainer() {
  const history = useHistory()
  const [loadData, setLoadingData] = useState(true)
  const [data, setData] = useState({})
  const studentTestId = localStorage.getItem('studentTestId')

  async function getFeedback(studentTestId) {
    try {
      const response = await services.feedback(studentTestId)
      const { data } = response

      setData(data)
      setLoadingData(false)
    } catch {
      history.push('/simulado')
    }
  }

  useEffect(() => {
    setLoadingData(true)
    if (studentTestId) getFeedback(studentTestId)
    else {
      notification.error('O simulado não foi finalizado.')
      history.push('/simulado')
    }
  }, [studentTestId])

  return (
    <Fragment>
      {loadData && <Skeleton active />}
      {!loadData && <TestFeedback data={data} />}
    </Fragment>
  )
}

export default TestQuestionsContainer
