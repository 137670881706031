import * as yup from 'yup'
import { cpf as cpfValidator } from 'cpf-cnpj-validator'

function installmentsTransform(value, originalValue) {
  if (originalValue === '') return 0
  return value
}

const schema = yup.object().shape({
  cep: yup
    .string()
    .notRequired()
    .when('paymentMethodId', {
      is: 1 || 2,
      then: yup.string().required('CEP obrigatório.'),
    }),
  address: yup
    .string()
    .notRequired()
    .when('paymentMethodId', {
      is: 1 || 2,
      then: yup.string().required('Endereço obrigatório.'),
    }),
  addressNumber: yup
    .string()
    .notRequired()
    .when('paymentMethodId', {
      is: 1 || 2,
      then: yup.string().required('Número obrigatório.'),
    }),
  addressComplement: yup.string(),
  addressDistrict: yup
    .string()
    .notRequired()
    .when('paymentMethodId', {
      is: 1 || 2,
      then: yup.string().required('Bairro obrigatório.'),
    }),
  addressCity: yup
    .string()
    .notRequired()
    .when('paymentMethodId', {
      is: 1 || 2,
      then: yup.string().required('Cidade obrigatória.'),
    }),
  addressState: yup
    .string()
    .notRequired()
    .when('paymentMethodId', {
      is: 1 || 2,
      then: yup.string().required('Estado obrigatório.'),
    }),

  paymentMethodId: yup.number(),
  name: yup.string().when('paymentMethodId', {
    is: 1,
    then: yup.string().required('Nome do Cartão obrigatório.'),
  }),
  holderCpf: yup.string().when('paymentMethodId', {
    is: 1,
    then: yup
      .string()
      .required('CPF obrigatório.')
      .test('cpfValidator', 'CPF inválido.', cpfValidator.isValid),
  }),
  holderCpfTicket: yup.string().when('paymentMethodId', {
    is: 2,
    then: yup
      .string()
      .required('CPF obrigatório.')
      .test('cpfValidator', 'CPF inválido.', cpfValidator.isValid),
  }),
  number: yup.string().when('paymentMethodId', {
    is: 1,
    then: yup.string().required('Número do Cartão obrigatório.'),
  }),
  expiry: yup.string().when('paymentMethodId', {
    is: 1,
    then: yup.string().required('Validade do Cartão obrigatória.'),
  }),
  cvc: yup.string().when('paymentMethodId', {
    is: 1,
    then: yup.string().required('Código de Segurança obrigatório.'),
  }),
  installments: yup
    .number()
    .transform(installmentsTransform)
    .when('paymentMethodId', {
      is: 1,
      then: yup
        .number()
        .required('Número de Parcelas obrigatório.')
        .min(1, 'Número de Parcelas obrigatório.'),
    }),
  termsConditions: yup
    .boolean()
    .oneOf([true], 'Termos e Condições obrigatório.'),
})

export default schema
