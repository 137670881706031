import React, { useEffect } from 'react'

import Loader from '../shared/Loader'

import SubscriptionComponent from '../components/SubscriptionPlans'

import useData from '../hooks/useData'

function SubscriptionContainer() {
  const { loading, getSubscriptionPlans } = useData()

  useEffect(() => {
    getSubscriptionPlans()
  }, [getSubscriptionPlans])

  if (loading) return <Loader />
  return <SubscriptionComponent />
}

export default SubscriptionContainer
