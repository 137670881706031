import React from 'react'
import { Form } from 'antd'

import InputSearch from '~/shared/InputSearch'

function StudyPlansFilterComponent(props) {
  const { onSubmit: onFinish } = props

  return (
    <Form name="study-plans">
      <Form.Item name="filters" style={{ marginBottom: 0 }}>
        <InputSearch
          placeholder="Filtrar uma aula, matéria ou disciplina"
          onSearch={onFinish}
        />
      </Form.Item>
    </Form>
  )
}

export default StudyPlansFilterComponent
