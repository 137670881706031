import React from 'react'
import { Link } from 'react-router-dom'

import toast from '~/pages/subscription/shared/Toast'

import * as S from '../styles'

function PaymentAction(props) {
  const { payment, setIsModalVisible } = props

  function copyPaymentSlipBarCode() {
    const textarea = document.createElement('textarea')
    document.body.appendChild(textarea)

    textarea.value = payment.paymentSlipBarcode
    textarea.select()

    document.execCommand('copy')
    document.body.removeChild(textarea)

    toast.success('Linha Digitável copiada com sucesso.')
  }

  function handleButton() {
    if (payment.status === 1 || payment.status === 2) {
      return (
        <>
          {payment.paymentSlipUrl && (
            <Link
              to={{
                pathname: payment.paymentSlipUrl,
              }}
              target="_blank"
            >
              <S.DetailsButton
                onClick={copyPaymentSlipBarCode}
                style={{ marginRight: 8 }}
              >
                Visualizar Boleto
              </S.DetailsButton>
            </Link>
          )}

          {payment.paymentSlipBarcode && (
            <S.DetailsButton onClick={copyPaymentSlipBarCode}>
              Copiar Linha Digitável
            </S.DetailsButton>
          )}
        </>
      )
    }
    if ([4, 7].includes(payment.status)) {
      return (
        <S.DetailsButton
          onClick={() => {
            setIsModalVisible(false)
          }}
        >
          Continuar agendamento
        </S.DetailsButton>
      )
    }

    return null
  }

  return <S.DetailsInfoWrapper>{handleButton()}</S.DetailsInfoWrapper>
}

export default PaymentAction
