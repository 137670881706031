import styled, { css } from 'styled-components'
import { InfoCircleOutlined } from '@ant-design/icons'

const Container = styled.section`
  ${({ theme }) => css`
    margin: ${theme.spacings.xxsmall} 0;
    margin-bottom: ${theme.spacings.xxsmall};
    padding: ${theme.spacings.xsmall};
    width: ${theme.width.large};
    color: ${theme.colors.text};
    font-size: ${theme.font.xsmall};
    background-color: ${theme.colors.bgGreen};
    border: 1px solid ${theme.colors.borderGreen};
    border-radius: 2px;
    display: flex;

    &.payment-finish-wrapper {
      margin-top: ${theme.spacings.xsmall};
    }
  `}

  span {
    margin-right: 10px;
  }
`

const Wrapper = styled.div`
  display: block;
`

const TextWrapper = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxsmall};
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  svg {
    ${({ theme }) => css`
      font-size: ${theme.font.sizes.small};
    `}
  }
`

const Title = styled.p`
  margin: 0;
  font-weight bold;
`

const Text = styled.p`
  margin: 0;
`

const TastingMessageInfoCircleOutlined = styled(InfoCircleOutlined)`
  ${({ theme }) => css`
    width: ${theme.font.sizes.small};
    color: ${theme.colors.secondary};
  `}
`

export {
  Container,
  Wrapper,
  TextWrapper,
  Title,
  Text,
  TastingMessageInfoCircleOutlined,
}
