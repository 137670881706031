import React from 'react'
import { useFormContext } from 'react-hook-form'

import * as S from './styles'

import ErrorMessage from '../ErrorMessage'

function Select(props) {
  const { register, errors } = useFormContext()

  const { name, placeholder, items, value, onChange } = props

  return (
    <S.SelectWrapper>
      <S.Select name={name} value={value} onChange={onChange} ref={register}>
        {placeholder && (
          <S.Option value="" disabled>
            {placeholder}
          </S.Option>
        )}

        {items.map((item) => (
          <S.Option key={item.value} value={item.value}>
            {item.description}
          </S.Option>
        ))}
      </S.Select>

      {errors[name] && <ErrorMessage>{errors[name]['message']}</ErrorMessage>}
    </S.SelectWrapper>
  )
}

export default Select
