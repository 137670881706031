import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Alert, Grid, Skeleton } from 'antd'
import { LaptopOutlined } from '@ant-design/icons'
import LayoutAntd from '~/shared/Layout'
import Card from '~/shared/Card'
import { Text } from '~/shared/Typography'

import useData from '~/pages/subscription/hooks/useData'

import * as S from '../shifts.styles'

function ShiftsComponent() {
  const { useBreakpoint } = Grid

  const screen = useBreakpoint()
  const { xxl } = screen

  const [visibleQuimica, setVisibleQuimica] = useState(false)
  const [visibleMatematica, setVisibleMatematica] = useState(false)
  const [visibleFisica, setVisibleFisica] = useState(false)
  const [visibleBiologia, setVisibleBiologia] = useState(false)
  const [visibleHistoria, setVisibleHistoria] = useState(false)
  const [visibleGeografia, setVisibleGeografia] = useState(false)
  const [visiblePortugues, setVisiblePortugues] = useState(false)
  const [visibleRedacao, setVisibleRedacao] = useState(false)
  const [visibleFilosofiaSociologia, setVisibleFilosofiaSociologia] = useState(
    false,
  )

  const { loading, allowLiveFaq } = useData()

  function ruleForDayHour(d, h) {
    // Quimica
    if ((h >= 20 && h <= 21 && d === 1) || (h >= 18 && h <= 20 && d === 3)) {
      setVisibleQuimica(true)
    }

    // Matematica
    if ((h >= 17 && h <= 19 && d === 1) || (h >= 20 && h <= 21 && d === 3)) {
      setVisibleMatematica(true)
    }

    // Fisica
    if ((h >= 19 && h <= 21 && d === 2) || (h >= 20 && h <= 21 && d === 5)) {
      setVisibleFisica(true)
    }

    // Biologia
    if ((h >= 17 && h <= 18 && d === 4) || (h >= 20 && h <= 21 && d === 4)) {
      setVisibleBiologia(true)
    }

    // História
    if (h >= 19 && h <= 20 && d === 1) {
      setVisibleHistoria(true)
    }

    // Geografia
    if (h >= 17 && h <= 18 && d === 3) {
      setVisibleGeografia(true)
    }

    // Portugues
    if (h >= 16 && h < 17 && d === 4) {
      setVisiblePortugues(true)
    }

    // Redacao
    if (h >= 16 && h < 17 && d === 2) {
      setVisibleRedacao(true)
    }

    // Filosofia / Sociologia
    if (h >= 18 && h <= 19 && d === 2) {
      setVisibleFilosofiaSociologia(true)
    }
  }

  function getEnableButtons() {
    const now = new Date()

    const diaSemana = now.getDay()
    const horaDia = now.getHours()

    ruleForDayHour(diaSemana, horaDia)
  }

  useEffect(() => {
    getEnableButtons()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const MessagePermission = () => {
    return (
      <LayoutShift
        message=""
        description="O seu plano de assinatura não possui acesso aos plantões."
      />
    )
  }

  const LayoutShift = (props) => {
    const { children, message, description } = props

    let messageMain = message
    let descriptionMain = description

    if (!message && !description) {
      messageMain = 'Sobre os plantões'
      descriptionMain =
        'Na grade abaixo estão listadas as disponibilidades dos plantões oferecidos. Cada disciplina possui um horário semanal específico em que a opção de entrada estará disponível. Caso o plantão que você deseja não esteja sendo transmitido agora, você pode publicar sua dúvida no fórum e um professor irá respondê-la.'
    }

    return (
      <LayoutAntd>
        <S.ShiftsWrapper>
          <Row
            style={{ marginLeft: 26, marginBottom: 20, display: 'block' }}
            className="row-alert"
          >
            <Text fontSize="25px" fontWeight="bold" color="#434343">
              Plantões
            </Text>

            <Alert
              message={messageMain}
              description={descriptionMain}
              type="info"
              showIcon
            />
          </Row>

          <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
        </S.ShiftsWrapper>
      </LayoutAntd>
    )
  }

  const Essay = () => {
    return (
      <Col xs={24} md={8} className="card-col">
        <Card size="small" padding="30px">
          <Row justify="center">
            <LaptopOutlined
              style={{
                fontSize: '35px',
                color: '#08c',
              }}
            />
          </Row>

          <Row
            justify="center"
            style={{
              marginBottom: '10px',
            }}
          >
            <Text fontSize="20px" color="#434343">
              Redação
            </Text>
          </Row>

          <Row justify="center">
            <S.InformationShifts>
              <Text fontWeight="bold">Plantão disponível às:</Text>

              <S.InformationDate marginLeft={xxl ? '5px' : null}>
                <Text>Terças-feiras das 16h às 17h.</Text>
              </S.InformationDate>
            </S.InformationShifts>
          </Row>

          {visibleRedacao === true ? (
            <Row justify="center" className="button-zoom">
              <Button
                type="primary"
                shape="round"
                href="https://us06web.zoom.us/j/84440721629?pwd=RDM2WjBWeExUZkxlMVRiZWhtTE9PUT09"
              >
                Acessar
              </Button>
            </Row>
          ) : (
            <></>
          )}
        </Card>
      </Col>
    )
  }

  const Shifts = () => {
    return (
      <LayoutShift>
        <Row
          gutter={16}
          align="center"
          style={{ marginLeft: 15, marginBottom: 25 }}
        >
          <Col xs={24} md={8} className="card-col">
            <Card size="small" padding="30px">
              <Row justify="center">
                <LaptopOutlined
                  style={{
                    fontSize: '35px',
                    color: '#08c',
                  }}
                />
              </Row>

              <Row
                justify="center"
                className="text-bottom"
                style={{
                  marginBottom: '10px',
                }}
              >
                <Text fontSize="20px" color="#434343">
                  Química
                </Text>
              </Row>

              <Row justify="center">
                <S.InformationShifts>
                  <Text fontWeight="bold">Plantão disponível às:</Text>

                  <S.InformationDate marginLeft={xxl ? '5px' : null}>
                    <Text>Segundas-feiras das 20h às 21h.</Text>
                    <Text>Quartas-feiras das 18h às 20h.</Text>
                  </S.InformationDate>
                </S.InformationShifts>
              </Row>

              {visibleQuimica === true ? (
                <Row justify="center" className="button-zoom">
                  <Button
                    type="primary"
                    shape="round"
                    href="https://us06web.zoom.us/j/85930077500?pwd=M1g1VHUwdCs4aU5RM0lxcDVWWHdCUT09"
                  >
                    Acessar
                  </Button>
                </Row>
              ) : (
                <></>
              )}
            </Card>
          </Col>

          <Col xs={24} md={8} className="card-col">
            <Card size="small" padding="30px">
              <Row justify="center">
                <LaptopOutlined
                  style={{
                    fontSize: '35px',
                    color: '#08c',
                  }}
                />
              </Row>

              <Row
                justify="center"
                className="text-bottom"
                style={{
                  marginBottom: '10px',
                }}
              >
                <Text fontSize="20px" color="#434343">
                  Matemática
                </Text>
              </Row>

              <Row justify="center">
                <S.InformationShifts>
                  <Text fontWeight="bold">Plantão disponível às:</Text>

                  <S.InformationDate marginLeft={xxl ? '5px' : null}>
                    <Text>Segundas-feiras das 17h às 19h.</Text>
                    <Text>Quartas-feiras das 20h às 21h.</Text>
                  </S.InformationDate>
                </S.InformationShifts>
              </Row>

              {visibleMatematica === true ? (
                <Row justify="center" className="button-zoom">
                  <Button
                    type="primary"
                    shape="round"
                    href="https://us06web.zoom.us/j/89565885348?pwd=SVhkbEdoQ0xvYzBuMzdmRENlcWpOQT09"
                  >
                    Acessar
                  </Button>
                </Row>
              ) : (
                <></>
              )}
            </Card>
          </Col>

          <Col xs={24} md={8} className="card-col">
            <Card size="small" padding="30px">
              <Row justify="center">
                <LaptopOutlined
                  style={{
                    fontSize: '35px',
                    color: '#08c',
                  }}
                />
              </Row>

              <Row
                justify="center"
                className="text-bottom"
                style={{
                  marginBottom: '10px',
                }}
              >
                <Text fontSize="20px" color="#434343">
                  Física
                </Text>
              </Row>

              <Row justify="center">
                <S.InformationShifts>
                  <Text fontWeight="bold">Plantão disponível às:</Text>

                  <S.InformationDate marginLeft={xxl ? '5px' : null}>
                    <Text>Terças-feiras das 19h às 21h.</Text>
                    <Text>Sextas-feiras das 20h às 21h.</Text>
                  </S.InformationDate>
                </S.InformationShifts>
              </Row>

              {visibleFisica === true ? (
                <Row justify="center" className="button-zoom">
                  <Button
                    type="primary"
                    shape="round"
                    href="https://us06web.zoom.us/j/81989866778?pwd=N2tRV055c1FJOGVLU0lRd0ZtZ29KUT09"
                  >
                    Acessar
                  </Button>
                </Row>
              ) : (
                <></>
              )}
            </Card>
          </Col>
        </Row>

        <Row
          gutter={16}
          align="center"
          style={{ marginLeft: 15, marginBottom: 25 }}
        >
          <Col xs={24} md={8} className="card-col">
            <Card size="small" padding="30px">
              <Row justify="center">
                <LaptopOutlined
                  style={{
                    fontSize: '35px',
                    color: '#08c',
                  }}
                />
              </Row>

              <Row
                justify="center"
                className="text-bottom"
                style={{
                  marginBottom: '10px',
                }}
              >
                <Text fontSize="20px" color="#434343">
                  Biologia
                </Text>
              </Row>

              <Row justify="center">
                <S.InformationShifts>
                  <Text fontWeight="bold">Plantão disponível às:</Text>

                  <S.InformationDate marginLeft={xxl ? '5px' : null}>
                    <Text>Quintas-feiras das 17h às 18h.</Text>
                    <Text>Quintas-feiras das 20h às 21h.</Text>
                  </S.InformationDate>
                </S.InformationShifts>
              </Row>

              {visibleBiologia === true ? (
                <Row justify="center" className="button-zoom">
                  <Button
                    type="primary"
                    shape="round"
                    href="https://us06web.zoom.us/j/81088127639?pwd=MGxmdnVxYTB0VVdkbjVVc1VMQ3oxZz09"
                  >
                    Acessar
                  </Button>
                </Row>
              ) : (
                <></>
              )}
            </Card>
          </Col>

          <Col xs={24} md={8} className="card-col">
            <Card size="small" padding="30px">
              <Row justify="center">
                <LaptopOutlined
                  style={{
                    fontSize: '35px',
                    color: '#08c',
                  }}
                />
              </Row>

              <Row
                justify="center"
                className="text-bottom"
                style={{
                  marginBottom: '10px',
                }}
              >
                <Text fontSize="20px" color="#434343">
                  História
                </Text>
              </Row>

              <Row justify="center">
                <S.InformationShifts>
                  <Text fontWeight="bold">Plantão disponível às:</Text>

                  <S.InformationDate marginLeft={xxl ? '5px' : null}>
                    <Text>Segundas-feiras das 19h às 20h.</Text>
                  </S.InformationDate>
                </S.InformationShifts>
              </Row>

              {visibleHistoria === true ? (
                <Row justify="center" className="button-zoom">
                  <Button
                    type="primary"
                    shape="round"
                    href="https://us06web.zoom.us/j/83262865794?pwd=MG9VRDE3V0JsMmVPVnhNNndPN2ZNQT09"
                  >
                    Acessar
                  </Button>
                </Row>
              ) : (
                <></>
              )}
            </Card>
          </Col>

          <Col xs={24} md={8} className="card-col">
            <Card size="small" padding="30px">
              <Row justify="center">
                <LaptopOutlined
                  style={{
                    fontSize: '35px',
                    color: '#08c',
                  }}
                />
              </Row>

              <Row
                justify="center"
                className="text-bottom"
                style={{
                  marginBottom: '10px',
                }}
              >
                <Text fontSize="20px" color="#434343">
                  Geografia
                </Text>
              </Row>

              <Row justify="center">
                <S.InformationShifts>
                  <Text fontWeight="bold">Plantão disponível às:</Text>

                  <S.InformationDate marginLeft={xxl ? '5px' : null}>
                    <Text>Quartas-feiras das 17h às 18h.</Text>
                  </S.InformationDate>
                </S.InformationShifts>
              </Row>

              {visibleGeografia === true ? (
                <Row justify="center" className="button-zoom">
                  <Button
                    type="primary"
                    shape="round"
                    href="https://us06web.zoom.us/j/86805829797?pwd=bnF4Q1BxenlUbDAxMnd4U0syUm9idz09"
                  >
                    Acessar
                  </Button>
                </Row>
              ) : (
                <></>
              )}
            </Card>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginLeft: 15, marginBottom: 15 }}>
          <Col xs={24} md={8} className="card-col">
            <Card size="small" padding="30px">
              <Row justify="center">
                <LaptopOutlined
                  style={{
                    fontSize: '35px',
                    color: '#08c',
                  }}
                />
              </Row>

              <Row
                justify="center"
                className="text-bottom"
                style={{
                  marginBottom: '10px',
                }}
              >
                <Text fontSize="20px" color="#434343">
                  Português
                </Text>
              </Row>

              <Row justify="center">
                <S.InformationShifts>
                  <Text fontWeight="bold">Plantão disponível às:</Text>

                  <S.InformationDate marginLeft={xxl ? '5px' : null}>
                    <Text>Quintas-feiras das 16h às 17h.</Text>
                  </S.InformationDate>
                </S.InformationShifts>
              </Row>

              {visiblePortugues === true ? (
                <Row justify="center" className="button-zoom">
                  <Button
                    type="primary"
                    shape="round"
                    href="https://us06web.zoom.us/j/81311987450?pwd=SlNPbzF4TUVjNmZZS3hDMUNSSncrZz09"
                  >
                    Acessar
                  </Button>
                </Row>
              ) : (
                <></>
              )}
            </Card>
          </Col>
          <Essay />
          <Col xs={24} md={8} className="card-col">
            <Card size="small" padding="30px">
              <Row justify="center">
                <LaptopOutlined
                  style={{
                    fontSize: '35px',
                    color: '#08c',
                  }}
                />
              </Row>

              <Row
                justify="center"
                className="text-bottom"
                style={{
                  marginBottom: '10px',
                }}
              >
                <Text fontSize="20px" color="#434343">
                  Filosofia / Sociologia
                </Text>
              </Row>

              <Row justify="center">
                <S.InformationShifts>
                  <Text fontWeight="bold">Plantão disponível às:</Text>

                  <S.InformationDate marginLeft={xxl ? '5px' : null}>
                    <Text>Terças-feiras das 18h às 19h.</Text>
                  </S.InformationDate>
                </S.InformationShifts>
              </Row>

              {visibleFilosofiaSociologia === true ? (
                <Row justify="center" className="button-zoom">
                  <Button
                    type="primary"
                    shape="round"
                    href="https://zoom.us/j/85741740821?pwd=RkRqWWlHK0lmdXZRQkErZ3RObmlhZz09"
                  >
                    Acessar
                  </Button>
                </Row>
              ) : (
                <></>
              )}
            </Card>
          </Col>
        </Row>
      </LayoutShift>
    )
  }

  if (loading) return <Skeleton active />

  return !allowLiveFaq ? <MessagePermission /> : <Shifts />
}

export default ShiftsComponent
