import React from 'react'
import * as S from './styles'

import corujaSabia from '../../../../assets/img/coruja-sabia.svg'

function Loader() {
  return (
    <S.LoaderWrapper>
      <S.LoaderImage src={corujaSabia} alt="Coruja Sábia" />
    </S.LoaderWrapper>
  )
}

export default Loader
