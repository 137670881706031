import React from 'react'
import { Empty } from 'antd'
import LiveClassesComponent from '../components/liveClasses-list.component'

import liveValidate from '~/pages/studyplans/components/live-validate'
import { Text } from '~/shared/Typography'

function LiveClassesContainer() {
  const liveShow = liveValidate()

  if (!liveShow)
    return (
      <Empty
        description={
          <Text fontSize="16px">
            A sua assinatura não possui acesso as aulas ao vivo.
          </Text>
        }
      />
    )

  return <LiveClassesComponent />
}

export default LiveClassesContainer
