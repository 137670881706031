import React from 'react'
import Card from 'react-credit-cards'

import * as S from './styles'

import { locale, placeholders } from './translation'

function CreditCard(props) {
  const { number = '', name = '', expiry = '', cvc = '', focused } = props

  return (
    <S.CardWrapper>
      <Card
        number={number}
        name={name}
        expiry={expiry}
        cvc={cvc}
        focused={focused}
        locale={locale}
        placeholders={placeholders}
      />
    </S.CardWrapper>
  )
}

export default CreditCard
