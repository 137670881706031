const theme = {
  fontFamily: 'Open Sans',
  primaryColor: '#46d83f',
  secondaryColor: '#403edc',
  grid: {
    container: {
      large: '130rem',
      small: '90rem',
    },
    gutter: '3.2rem',
  },
  border: {
    radius: '.8rem',
  },
  font: {
    family: 'Open Sans',
    familySecondary: 'Open Sans',
    bold: 600,
    sizes: {
      x14: '14px',
      xxxsmall: '1rem',
      xxsmall: '1.4rem',
      xsmall: '1.6rem',
      small: '1.8rem',
      medium: '2.2rem',
      large: '2.6rem',
      xlarge: '3.4rem',
      xxlarge: '5.2rem',
    },
  },
  colors: {
    primary: '#403edc',
    secondary: '#46d83f',
    dark: '#383838',
    background: '#f0f2f5',
    text: '#030517',
    white: '#fff',
    black: '#000',
    input: '#ced4da',
    inputBorder: '#d9d9d9',
    close: '#ff4d4f',
    bgGreen: '#f6ffed',
    borderGreen: '#b7eb8f',
    placeholder: '#bfbfbf',
    googleButton: '#4285F4',
  },
  spacings: {
    xxxsmall: '0.35rem',
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '6.0rem',
    xlarge: '6.4rem',
    xxlarge: '12.8rem',
  },
  width: {
    large: '100%',
  },
  empty: '0',
  padding: {
    small: '15px',
  },
}

module.exports = theme
