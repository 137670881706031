import React from 'react'
import { useParams } from 'react-router-dom'

import StudentFormTakeQuestionsViewModal from './student-form-view-modal.component'

function StudentFormTakeQuestions() {
  const params = useParams()
  const { id } = params

  return <StudentFormTakeQuestionsViewModal id={id} />
}

export default StudentFormTakeQuestions
