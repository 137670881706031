import React from 'react'
import * as S from './styles'

function Content(props) {
  const { children } = props

  return <S.Content>{children}</S.Content>
}

export default Content
