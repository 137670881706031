import Essay from './containers/essay-list.container'

const routes = [
  {
    path: '/redacoes',
    title: 'Minhas Redações',
    authenticated: true,
    component: Essay,
  },
]

export default routes
