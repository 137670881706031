import React from 'react'

import * as S from '../styles'

import Heading from '~/pages/subscription/shared/Heading'

import formatCurrency from '~/pages/subscription/utils/format-currency'

function PlanInfo(props) {
  const { subscription } = props

  return (
    <>
      <Heading lineBottom>Dados da compra</Heading>

      <S.DetailsCard>
        <S.DetailsInfo>{subscription.name}</S.DetailsInfo>
        <S.DetailsInfo>{subscription.description}</S.DetailsInfo>

        <S.DetailsInfo>
          <S.DetailsInfoStrong>Total da compra:</S.DetailsInfoStrong> R${' '}
          {formatCurrency(subscription.amount)}
        </S.DetailsInfo>
      </S.DetailsCard>
    </>
  )
}

export default PlanInfo
