import React from 'react'

import { LivePlayer, LessonsPlayer } from '../liveClasses.styles'

function LiveClassesVimeo({ id, isLive, className }) {  

  if(isLive) {

    return (
      <LivePlayer
        className={className}
        video={ `https://vimeo.com/event/1704476` }
        responsive
      />
        
    )  
  }
  else {
    
    return (
      <LessonsPlayer
          className={className}
          video={ `https://player.vimeo.com/video/${id}` }
          responsive
        />
    )
  }
}

export default LiveClassesVimeo
