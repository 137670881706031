import React from 'react'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import * as S from '../private-classes.style'

function PrivateClassesCardComponent(props) {
  const { data, setStep, setSelectData, resetArray, resetData } = props

  return (
    <S.CardContainer>
      <Avatar size={75} icon={<UserOutlined />} />
      <S.TitleCard>{data.name}</S.TitleCard>
      <S.TextCard>{data.discipline}</S.TextCard>
      <span role="img" aria-label="estrelas">
        ⭐️ ⭐️ ⭐️ ⭐️ ⭐️
      </span>
      <S.ButtonCard
        type="primary"
        size="large"
        shape="round"
        onClick={() => {
          setStep(2)
          setSelectData(data)
          resetArray.length = 0
          resetData([])
        }}
      >
        Agendar Aula
      </S.ButtonCard>
    </S.CardContainer>
  )
}

export default PrivateClassesCardComponent
