import styled, { css } from 'styled-components'
import Vimeo from '@u-wave/react-vimeo'

const Wrapper = styled.section``

const Content = styled.div``

const Header = styled.div`
  margin-top: 35px;
  text-align: center;
`

const Text = styled.span`
  margin: 25px 0 20px 0;
  font-size: 25px;
  font-weight: bold;
  color: #434343;
  display: block;
`

const ContainerCollapse = styled.div`
  padding: 15px;
  border: 1px solid #ffffff;
  border-radius: 20px;
  background: #ffffff;

  p {
    ${({ theme }) => css`
      margin: 0;
      padding: 0;
      font-size: ${theme.font.sizes.xxxsmall};
    `}
  }

  .collapse-main .ant-collapse-item {
    border: 0;
  }

  .collapse-main .ant-collapse-item .ant-collapse-header {
    ${({ theme }) => css`
      padding-left: 25px;
      padding-right: 0;
      font-size: ${theme.font.sizes.xxxsmall};
      font-weight: ${theme.font.bold};
      line-height: normal;
    `}
  }

  .collapse-main .anticon.ant-collapse-arrow {
    left: 0;
    font-size: 22px;
    color: #c3c3c3;
  }

  .collapse-main .ant-collapse-content {
    padding-left: 12px;
  }

  .collapse-main.ant-collapse.ant-collapse-borderless {
    background: transparent;
  }

  .collapse-main .ant-collapse-item.panel-text-half p {
    display: inline-block;
  }

  .collapse-main .ant-collapse-item.panel-text-half p:first-child {
    margin-right: 10px;
  }
`

const LivePlayer = styled(Vimeo)`
  position: relative;
  padding-top: 0;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.loading {
    display: none;
  }
`

const LessonsPlayer = styled(Vimeo)`
  position: relative;
  overflow: hidden;

  height: 0;

  padding-top: 0;
  padding-bottom: 56.25%;

  margin-bottom: 24px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
`

export { Wrapper, Content, Header, Text, ContainerCollapse, LivePlayer, LessonsPlayer }
