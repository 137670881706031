/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { Skeleton } from 'antd'

import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'

import Input from '~/pages/subscription/shared/Input'
import notification from '~/shared/Notification'

import services from '~/pages/subscription/components/Checkout/checkout.services'

import useData from '~/pages/subscription/hooks/useData'
import useAuth from '~/hooks/useAuth'

import formatCurrency from '~/pages/subscription/utils/format-currency'

import * as S from './styles'

export default function Coupon(subscriptionPlanId) {
  const [coupon, setCoupon] = useState('')
  const [couponValue, setCouponValue] = useState('')
  const [couponLoading, setCouponLoading] = useState(false)

  const {
    getInstallmentsAmount,
    studentSubscriptionCouponCheck,
    storageStudentSubscriptionCouponCode,
    setStorageStudentSubscriptionCouponCode,
    getTastingValidate,
    installmentInUntil,
    setInstallmentInUntilCheck,
    installmentInUntilCheck,
  } = useData()

  const auth = useAuth()
  const { studentId } = auth.user

  const planId = subscriptionPlanId.subscriptionPlanId

  async function getCouponData() {
    setCouponLoading(true)

    try {
      const response = await services.getCoupon({
        subscriptionPlanId: planId,
        couponValue: couponValue || storageStudentSubscriptionCouponCode || '',
      })

      setCoupon(response.data)

      getInstallmentsAmount(
        planId,
        couponValue || storageStudentSubscriptionCouponCode,
      )
      getTastingValidate(planId, couponValue, studentId)

      notification.success('Cupom aplicado')
      setCouponLoading(false)
    } catch (e) {
      setCoupon('')
      setStorageStudentSubscriptionCouponCode('')
      getInstallmentsAmount(planId, '')
      setCouponLoading(false)
      await getTastingValidate(planId, '', studentId)
    }
  }

  useEffect(() => {
    async function getStorageStudentSubscriptionCouponCode() {
      setCouponValue(storageStudentSubscriptionCouponCode)
      await getCouponData()
    }

    if (studentSubscriptionCouponCheck && storageStudentSubscriptionCouponCode)
      getStorageStudentSubscriptionCouponCode()
  }, [studentSubscriptionCouponCheck, storageStudentSubscriptionCouponCode])

  useEffect(() => {
    setInstallmentInUntilCheck(false)

    if (coupon?.subscriptionPlanWithCouponAmount > 0)
      setInstallmentInUntilCheck(true)
  }, [coupon, installmentInUntil])

  async function handleApplyCoupon(data, action) {
    setStorageStudentSubscriptionCouponCode('')

    if (data) await getCouponData()

    if (!data || action === 'removed') {
      setCoupon('')
      setCouponValue('')
      getInstallmentsAmount(planId, '')
      await getTastingValidate(planId, '', studentId)
    }

    if (action === 'removed') notification.warning('Cupom excluído')
  }

  return (
    <S.CouponRow>
      <Row className="coupon-row">
        <Col md={6}>
          <S.CouponWrapper className="relative discount-coupon">
            <Input
              name="discountCouponCode"
              placeholder="Cupom de Desconto"
              value={couponValue}
              onChange={(e) => setCouponValue(e.target.value)}
              onBlur={(e) => handleApplyCoupon(e.target.value)}
            />

            {!coupon && !couponValue && (
              <S.CouponIcon
                className="coupon-icon applied"
                onClick={() => handleApplyCoupon(couponValue || '')}
              >
                <PlusCircleOutlined />
              </S.CouponIcon>
            )}

            {!!couponValue && (
              <S.CouponIcon
                className="coupon-icon removed"
                onClick={() => handleApplyCoupon('', 'removed')}
              >
                <MinusCircleOutlined />
              </S.CouponIcon>
            )}
          </S.CouponWrapper>
        </Col>

        <Col md={6}>
          {couponLoading ? (
            <Skeleton active />
          ) : (
            !!coupon && (
              <S.CouponWrapper className="coupon-active">
                <S.CouponInformation>
                  <S.CouponText className="title-discount-applied">
                    <S.CouponCheckCircleOutlined /> Desconto aplicado
                  </S.CouponText>

                  <S.CouponText>
                    Valor da Assinatura{' '}
                    <b>R$ {formatCurrency(coupon.subscriptionPlanAmount)}</b>
                  </S.CouponText>

                  <S.CouponText>
                    Desconto{' '}
                    <b>R$ {formatCurrency(coupon.discountCouponAmount)}</b>
                  </S.CouponText>

                  <S.CouponText>
                    Total{' '}
                    <b>
                      R${' '}
                      {formatCurrency(coupon.subscriptionPlanWithCouponAmount)}
                    </b>
                  </S.CouponText>

                  {!!installmentInUntilCheck && installmentInUntil && (
                    <S.CouponTextSecondary>
                      em até {installmentInUntil[0]} {installmentInUntil[1]}{' '}
                      <b>R$ {formatCurrency(installmentInUntil[2])}</b>
                    </S.CouponTextSecondary>
                  )}
                </S.CouponInformation>
              </S.CouponWrapper>
            )
          )}
        </Col>
      </Row>
    </S.CouponRow>
  )
}
