/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Row, Col, Space } from 'antd'

import TestEmpty from './test-empty.component'

import Graphic from '~/shared/Graphic'

import * as S from '../test.styles'

function TestListComponent(props) {
  const { data } = props
  const { Title, Paragraph, Text } = Typography
  const history = useHistory()

  const convertToHoursMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60

    return { hours, minutes }
  }

  const onClick = async (url) => {
    history.push(url)
  }

  const ItemTestContent = ({ started, questions, resultConvert, children }) => {
    return (
      <>
        <S.TextWrapper>
          <S.StudyPlansFileTextOutlinedIcon
            color={!started ? '#939393' : '#4287f5'}
            marginLeft="5px"
          />

          <Text style={{ marginLeft: '5px' }}>{questions} questões</Text>
        </S.TextWrapper>

        <S.TextWrapper marginRightEmpty={!children && true}>
          <S.StudyPlansHourglassOutlinedIcon
            color={!started ? '#939393' : '#52c41a'}
          />

          <Text style={{ marginLeft: '5px' }}>
            {resultConvert.hours
              ? `${resultConvert.hours} horas e ${resultConvert.minutes} minutos`
              : resultConvert.minutes
                ? `${resultConvert.minutes} minutos`
                : 'Livre'}
          </Text>
        </S.TextWrapper>

        {!!children && children}
      </>
    )
  }

  const ItemTest = ({
    name,
    duration,
    questions,
    started,
    canAccess,
    testUrlForWeb,
    studentTestScore,
    done,
  }) => {
    let durationCheck = duration

    if (done) durationCheck = 30

    const resultConvert = convertToHoursMinutes(durationCheck)

    return (
      <>
        <Row>
          <Col xs={24} md={24}>

            {!canAccess && (
              <Space>
                <S.StudyPlansLockOutlinedIcon color={'#939393'}></S.StudyPlansLockOutlinedIcon>

                <Text>{name} </Text>

                {studentTestScore ? <Text>-</Text> : null}

                {studentTestScore ? <Text>{studentTestScore}%</Text> : null}
              </Space>
            )}
            {canAccess && (
              <Space

                onClick={async () =>
                  !started ? onClick(`/${testUrlForWeb}`) : null
                }
                style={{ cursor: !started ? 'pointer' : 'default' }}
              >
                <S.StudyPlansCheckOutlinedIcon
                  fontSize="20px"
                  color={!started ? '#939393' : '#52c41a'}
                />
                <Text>{name} </Text>

                {studentTestScore ? <Text>-</Text> : null}

                {studentTestScore ? <Text>{studentTestScore}%</Text> : null}
              </Space>
            )}




          </Col>
        </Row>

        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '25px',
          }}
        >
          <Col xs={24} md={24}>
            {done ? (
              <>
                <ItemTestContent
                  started={started}
                  questions={questions}
                  studentTestScore={studentTestScore}
                  resultConvert={resultConvert}
                >
                  <S.TextWrapper>
                    <S.StudyPlansPercentageOutlined
                      color={!started ? '#939393' : '#4287f5'}
                    />

                    <Text style={{ marginLeft: '5px' }}>
                      {studentTestScore}% sua pontuação
                    </Text>
                  </S.TextWrapper>

                  <S.TextWrapper marginRightEmpty>
                    <S.StudyPlansPercentageOutlined
                      color={!started ? '#939393' : '#4287f5'}
                    />

                    <Text style={{ marginLeft: '5px' }}>
                      {studentTestScore}% pontuação geral dos alunos
                    </Text>
                  </S.TextWrapper>
                </ItemTestContent>
              </>
            ) : (
              <>
                <ItemTestContent
                  started={started}
                  questions={questions}
                  resultConvert={resultConvert}
                />
              </>
            )}
          </Col>
        </Row>
      </>
    )
  }

  return (
    <Row gutter={25}>
      <Col xs={24} md={24}>
        <Row>
          <Col xs={24} md={12}>
            <Title level={4} style={{ color: '#403edc' }}>
              Simulados
            </Title>

            <Paragraph>Selecione o simulado:</Paragraph>
          </Col>


        </Row>
      </Col>

      <Col xs={24} md={24}>
        {data.length > 0 && (
          <Row gutter={40}>
            <Col xs={24} md={24}>
              <Row>
                <Col xs={24} md={10}>
                  {data.map((group) => {
                    const key = 1 + Math.random() * (9999999 - 1)

                    return (
                      <>
                        <Title level={5}>{group.groupDescription}</Title>

                        {group.tests.map((item) => {
                          return (
                            <Col
                              xs={24}
                              md={24}
                              key={item.testId}
                              style={{ marginBottom: '10px' }}
                            >
                              <ItemTest {...item} />
                            </Col>
                          )
                        })}
                      </>
                    )
                  })}
                </Col>
                {/* <Col xs={24} md={14} style={{paddingRight:"1rem"}}>
                  <Row>
                    <Col xs={24} md={10}>
                      <Paragraph>Você visualizou: 0 simulados</Paragraph>
                      <Paragraph>Seu índice de acertos: %</Paragraph>
                      <Paragraph>Índice de acertos geral: %</Paragraph>
                    </Col>

                    <Col xs={24} md={14}>
                      <Paragraph>Sua evolução</Paragraph>

                      <Graphic />
                    </Col>
                  </Row>
                </Col> */}
              </Row>
            </Col>

          </Row>
        )}

        {data.length === 0 && (
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <TestEmpty />
          </Row>
        )}
      </Col>
    </Row>
  )
}

export default TestListComponent
