import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Form, Input, Button, Typography } from 'antd'
import { UserOutlined, LockOutlined, GoogleOutlined } from '@ant-design/icons'
import { GoogleLogin } from 'react-google-login'
import { GoogleButton } from '../login.styles'
import useAuth from '~/hooks/useAuth'
import api from '~/services/api'
import notification from '~/shared/Notification'

function LoginFormComponent(props) {
  const auth = useAuth()
  const { loading, onSubmit: onFinish } = props

  const rules = [{ required: true }]

  async function createUser(data) {
    const dataLogin = {
      email: data?.email,
      googleId: data?.googleId,
    }

    const checkEmail = await api.get(
      `/student/check-email?email=${dataLogin?.email}`,
    )

    const { emailExists } = checkEmail?.data
    if (emailExists === true) {
      auth.signIn(dataLogin)
    } else {
      try {
        const createdata = {
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          googleId: data?.googleId,
        }

        await api.post('/student/register/freemium/', createdata)

        notification.success(
          'Cadastro realizado com sucesso, redirecionando para o portal.',
        )

        auth.signIn(dataLogin)
      } catch {
        notification.error('Erro ao finalizar cadastro.')
      }
    }
  }

  const responseGoogle = async (response) => {
    const infos = response.profileObj
    const dataGoogle = {
      firstName: infos?.givenName,
      lastName: infos?.familyName,
      email: infos?.email,
      googleId: infos?.googleId,
    }
    if (infos?.email) await createUser(dataGoogle)
  }
  return (
    <>
      <Row align="bottom" justify="start">
        <Col>
          <Typography>Já possui uma conta? </Typography>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <GoogleButton
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <GoogleOutlined />
                Logar com o Google
              </GoogleButton>
            )}
            back="#4285F4"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy="single_host_origin"
          />
        </Col>
      </Row>
      <Row gutter={24} align="middle" justify="space-between">
        <Col span={10}>
          <Typography style={{ border: '1px solid gray' }}> </Typography>
        </Col>
        <Col span={[3, 0]}>
          <span>Ou</span>
        </Col>
        <Col span={11}>
          <Typography style={{ border: '1px solid gray' }}> </Typography>
        </Col>
      </Row>
      <Row align="middle" justify="start" style={{ marginTop: '1rem' }}>
        <Col>
          <Typography>Entre com seu e-mail: </Typography>
        </Col>
      </Row>
      <Form name="login" size="large" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="email" rules={rules} hasFeedback>
              <Input
                prefix={<UserOutlined />}
                placeholder="E-mail"
                maxLength={50}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="password" rules={rules} hasFeedback>
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Senha"
                maxLength={50}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align="top" justify="end">
          <Col>
            <Link to="/esqueceu-senha">Esqueceu sua senha?</Link>
          </Col>
        </Row>
        <Button
          htmlType="submit"
          type="primary"
          size="large"
          shape="round"
          loading={loading}
          style={{ width: '100%', marginBottom: '1rem' }}
        >
          Entrar
        </Button>
        <Row gutter={24} align="middle" justify="space-between">
          <Col span={10}>
            <Typography style={{ border: '1px solid gray' }}> </Typography>
          </Col>
          <Col span={[3, 0]}>
            <span>Ou</span>
          </Col>
          <Col span={11}>
            <Typography style={{ border: '1px solid gray' }}> </Typography>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Button
              size="large"
              shape="round"
              href="https://www.corujasabia.com.br/#planos"
              target="_blank"
              style={{ lineHeight: '28px', width: '100%', marginTop: '1rem' }}
            >
              Escolha um plano
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default LoginFormComponent
