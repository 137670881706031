import styled from 'styled-components'
import { Card } from 'antd'

const StyledCard = styled(Card)`
  padding: ${(props) => props.padding};

  box-shadow: 0px 6px 6px #0000000a;
  border: 1px solid #e8e8e8;
  border-radius: 40px;
`

export default StyledCard
