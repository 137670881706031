import React, { useState, useEffect } from 'react'
import { Row, Col, Typography, Tooltip, Button, Empty, Skeleton } from 'antd'
import { EyeOutlined } from '@ant-design/icons'

import services from '../teachingMaterials.services'

import Box from '~/shared/Box'
import Card from '~/shared/Card'

function TeachingMaterialisListComponent() {
  const [studentAccess, setStudentAccess] = useState(false)
  const [teachingMaterials, setTeachingMaterials] = useState([])
  const [loading, setLoading] = useState(false)

  const { Title, Text } = Typography

  useEffect(() => {
    async function getStudentPermission() {
      setLoading(true)
      const response = await services.getPermission()

      setStudentAccess(response.data.includeTeachingMaterial)
      setLoading(false)
    }

    getStudentPermission()
  }, [])

  useEffect(() => {
    async function getStudentTeachingMaterials() {
      setLoading(true)

      const response = await services.getTeachingMaterials()

      setTeachingMaterials(response.data)
      setLoading(false)
    }

    if (studentAccess) getStudentTeachingMaterials()
  }, [studentAccess])

  if (loading) return <Skeleton active />

  return studentAccess && teachingMaterials ? (
    <Box>
      <Title level={4} style={{ color: '#403edc' }}>
        Materiais Didáticos
      </Title>

      <Card size="default">
        <Row gutter={16}>
          {!!teachingMaterials &&
            teachingMaterials.map((item, index) => (
              <Col xs={24} md={24} key={index}>
                <Text>{item.discipline}</Text>

                <Tooltip title="Visualizar material didático">
                  <Button
                    type="link"
                    icon={<EyeOutlined />}
                    href={item.urlMaterial}
                    target="_blank"
                  />
                </Tooltip>
              </Col>
            ))}
        </Row>
      </Card>
    </Box>
  ) : (
    <>
      <Empty
        description={<Text fontSize="16px">Nenhum material encontrado.</Text>}
      />
    </>
  )
}

export default TeachingMaterialisListComponent
