import api from '~/services/api'

const services = {
  getLive(eventId) {
    return api.get(`https://api.vimeo.com/live_events/${eventId}`)
  },
  getLessons() {
    return api.get('/liveclass/student/list')
  }
}

export default services
