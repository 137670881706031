import styled from 'styled-components'

const Box = styled.div`
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.flexDirection};
  grid-gap: ${(props) => props.gridGap};
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};

  margin: ${(props) => props.m};
  margin-top: ${(props) => props.mt};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  margin-bottom: ${(props) => props.mb};

  padding: ${(props) => props.p};
  padding-top: ${(props) => props.pt};
  padding-left: ${(props) => props.pl};
  padding-right: ${(props) => props.pr};
  padding-bottom: ${(props) => props.pb};

  max-width: ${(props) => props.mw};
  min-height: ${(props) => props.mh};
  width: ${(props) => props.w};
  height: ${(props) => props.h};

  border-bottom: ${(props) => props.borderBottom};
  background-color: ${(props) => props.backgroundColor};

  text-align: ${(props) => props.textAlign};
`

export default Box
