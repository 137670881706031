import React from 'react'
import { Empty } from 'antd'

import { Text } from '~/shared/Typography'

function StudyPlansEmptyComponent() {
  return (
    <Empty
      description={
        <Text fontSize="16px">
          Você ainda não possui planos de estudo por semana.
        </Text>
      }
    />
  )
}

export default StudyPlansEmptyComponent
