import api from '~/services/api'

const services = {
  getUserDetails() {
    return api.get('/student/perfil')
  },
  getDisciplinesCombobox() {
    return api.get('/student/study-plan/discipline/combobox')
  },
  getDisciplines(discipline, pageNumber) {
    return api.get('/user/teacher/student', {
      params: {
        Role: 2,
        PrivateClasses: true,
        MentorShip: false,
        discipline,
        pageNumber,
        pageSize: 9,
      },
    })
  },
  dataUpdateCredits(data) {
    return api.put('/student/update_credits', data)
  },
}

export default services
