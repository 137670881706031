import LessonsQuestionsContainer from './containers/lessons-questions.container'

const routes = [
  {
    path: '/:idStudyPlan/aula/:id/exercicio',
    component: LessonsQuestionsContainer,
    authenticated: true,
  },
]

export default routes
