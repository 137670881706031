import api from '~/services/api'

const services = {
  passwordUpdate(data) {
    const { oldPassword, newPassword } = data

    return api.post('/student/password', {
      password: oldPassword,
      newPassword,
    })
  },
}

export default services
