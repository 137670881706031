import api from '~/services/api'

const services = {
  getStudentSubscription() {
    return api.get('/student/subscription')
  },
  displayRenew() {
    return api.get('/student/subscription/display-renew')
  },
  getStudentSubscriptionId(data) {
    return api.get(`/subscriptionplan/active/${data}`)
  },
  tastingCancel(studentSubscriptionId) {
    return api.put(`/student/subscription/${studentSubscriptionId}/cancel`)
  },
}

export default services
