import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

import Zendesk from 'react-zendesk'
import Providers from './providers'
import Routes from './routes'
import Modal from '~/shared/Modal'

function App() {
  const [isMobile, setIsMobile] = useState(true)
  const [localStorageCheck, setLocalStorageCheck] = useState()
  const zendeskKey = 'eff62995-22c9-4093-ab01-a93397d4a7fd'

  useEffect(() => {
    if (window.location.search !== '') {
      const parameters = new URLSearchParams(window.location.search)

      if (parameters && parameters.get('isMb')) {
        localStorage.setItem('isMb', true)
      }
    }

    const isMbGet = localStorage.getItem('isMb')
    if (isMbGet) setLocalStorageCheck(isMbGet)

    if (isMbGet === null) setIsMobile(false)
  }, [isMobile])

  useEffect(() => {
    if (localStorageCheck) setIsMobile(localStorageCheck)
  }, [localStorageCheck])

  return (
    <>
      <BrowserRouter
        getUserConfirmation={(message, callback) => {
          return Modal.confirmation(message, callback)
        }}
      >
        <Providers>
          <Routes />
        </Providers>
      </BrowserRouter>

      {isMobile === false && <Zendesk zendeskKey={zendeskKey} />}
    </>
  )
}

export default App
