import React, { useState } from 'react'
import { Row, Col, Space, Form, Input, Button } from 'antd'

import services from '../userPasswordChange.services'

import Box from '~/shared/Box'
import Card from '~/shared/Card'
import { Text } from '~/shared/Typography'

import { Info } from '../userPasswordChange-styles'

import notification from '~/shared/Notification'

import PasswordStrenght from '~/shared/PasswordStrenght'

function UserPasswordChange() {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const requiredRule = [{ required: true }]
  const minLengthRule = [{ min: 6 }]
  const [form] = Form.useForm()

  async function dataFinish() {
    setLoadingSubmit(true)

    try {
      const data = {
        oldPassword,
        newPassword,
        newPasswordConfirm,
      }

      await services.passwordUpdate(data)

      notification.success('Senha alterada com sucesso.')
      setLoadingSubmit(false)
    } catch (e) {
      setLoadingSubmit(false)
      notification.error(e.message)
    }
  }

  const matchPasswordRule = [
    (instance) => ({
      validator(_, value) {
        if (!value || newPassword === value) return Promise.resolve()

        return Promise.reject(new Error('As senhas não coincidem.'))
      },
    }),
  ]

  return (
    <Box>
      <Card size="default">
        <Info>
          <Text className="title">Alterar senha</Text>

          <Form
            name="newPasswordForm"
            size="large"
            initialValues={{ active: true }}
            form={form}
            onFinish={dataFinish}
          >
            <Row gutter={16}>
              <Col xs={6} md={6}>
                <Form.Item name="OldPassword" hasFeedback>
                  <Input.Password
                    placeholder="Senha atual"
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col xs={6} md={6}>
                <Form.Item
                  name="passwordItem"
                  rules={[...requiredRule, ...minLengthRule]}
                  hasFeedback
                >
                  <Input.Password
                    placeholder="Nova senha"
                    maxLength={16}
                    onChange={(e) => setNewPassword(e.target.value)}
                    allowClear
                  />
                </Form.Item>

                {newPassword && (
                  <Box mb="24px">
                    <PasswordStrenght password={newPassword} />
                  </Box>
                )}
              </Col>

              <Col xs={6} md={6}>
                <Form.Item
                  name="passwordConfirmItem"
                  rules={[...requiredRule, ...matchPasswordRule]}
                  hasFeedback
                >
                  <Input.Password
                    placeholder="Confirmar nova senha"
                    maxLength={16}
                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                    allowClear
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={12} md={12}>
                <Space>
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    shape="round"
                    loading={loadingSubmit}
                  >
                    Alterar senha
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Info>
      </Card>
    </Box>
  )
}

export default UserPasswordChange
