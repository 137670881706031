import styled from 'styled-components'

const Info = styled.div`
  margin-bottom: 40px;
  width: 100%;
  display: block;

  .title {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
  }
  .margin {
    margin-bottom: 20px;
  }
  .cursor-not-allowed {
    &:hover {
      border-color: #d9d9d9 !important;
    }
  }
  .input {
    width: 100%;
    padding: 6.5px 11px;
    line-height: 1.8;
    border: 1px solid #d9d9d9;
    border-radius: 2px;

    &:hover,
    &:focus {
      border-color: #46d83f;
      outline: none;
    }
    &::placeholder {
      color: #bfbfbf;
    }
  }
  .ant-select-selection-item {
    font-weight: normal;
  }

  .ant-alert-error.alert-error {
    margin-bottom: 0;
    border: 0;
    background: transparent;
  }

  .ant-alert-error.alert-error .ant-input-number,
  .ant-alert-error.alert-error .ant-form-item-control-input input {
    border-color: #ff4d4f;
  }

  .ant-alert-error.alert-error .ant-form-item-control-input input:focus {
    box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
  }

  .alert-error-col {
    position: relative;
  }

  .alert-error-col .item-message-error {
    color: #ff4d4f;
  }

  .alert-error-col .ant-form-item-children-icon {
    display: none;
  }

  .alert-error-col .error-icon {
    position: absolute;
    top: 10px;
    right: 17px;
    color: #ff4d4f;
    display: block;
  }

  .ant-input-number {
    width: 100%;
  }

  @media (max-width: 1000px) {
    .ant-col-xs-12,
    .ant-space.ant-space-horizontal.ant-space-align-center {
      max-width: 100%;
      width: 100%;
    }
    .ant-col-xs-12 {
      flex: auto;
    }
    .ant-typography {
      margin-bottom: 20px;
      display: block;
    }
  }
`

export { Info }
