import './styles.css'
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faLockOpen,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Col, Row } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import useAuth from '~/hooks/useAuth'
import useData from '~/pages/subscription/hooks/useData'

import api from '~/services/api'
import notification from '~/shared/Notification'
import messageNotificationVerifyUserDataInSubscription from '~/utils/messageNotificationVerifyUserDataInSubscription'
import formatCurrency from '../../subscription/utils/format-currency'
import * as S from './styles'

function ModalPlansSelection() {
  const [subscriptionPlans, setSubscriptionPlans] = useState([])
  const [loading, setLoading] = useState(false)
  const { handleSubscriptionPlanClick } = useData()

  const auth = useAuth()

  const { verifyUserDataInSubscription } = auth

  const getSubscriptionPlans = useCallback(async () => {
    setLoading(true)
    const { data } = await api.get('/student/subscriptionplan/active')

    const formattedData = data?.map((dataItem) => {
      return {
        ...dataItem,
        displayTheme:
          dataItem.displayTheme.charAt(0).toLowerCase() +
          dataItem.displayTheme.slice(1),
      }
    })

    setSubscriptionPlans(formattedData)
    setLoading(false)
  }, [])

  useEffect(() => {
    getSubscriptionPlans()
  }, [getSubscriptionPlans])


  function handleSelectPlan(subscriptionPlanId) {
    if (verifyUserDataInSubscription)
      notification.info(
        messageNotificationVerifyUserDataInSubscription(),
        '/minha-conta',
      )

    if (!verifyUserDataInSubscription)
      handleSubscriptionPlanClick(subscriptionPlanId)
  }

  if (loading) {
    return <></>
  }
  return (
    <Col style={{ paddingTop: '10px' }} xs={24}>
      <Row
        xs={24}
        flex="1"
        justify="center"
        style={{
          paddingRight: '25px',
          fontSize: '40px',
          font: 'normal normal bold 30px/52px Futura PT',
        }}
      >
        Seja Premium
        <FontAwesomeIcon
          icon={faLockOpen}
          color="#5FCC58"
          style={{ fontSize: '40px', marginLeft: '10px' }}
        />
      </Row>

      <Row
        align="center"
        style={{
          margin: '5px 50px',
          fontSize: '16px',
          fontColor: '#262626',
          fontFamily: 'Open Sans',
          justifyContent: 'center',
          textAlign: 'center',
        }}
        justify="center"
        xs={24}
      >
        Selecione um plano para desbloquear esta aula e outros benefícios.
      </Row>

      <Col xs={24}>
        <Carousel
          showIndicators={false}
          showStatus={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
             <a
                 type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    zIndex: '99',
                    paddingLeft: '10px',
                    position: 'absolute',
                    top: 'calc(50%)',
                    left: '0px',
                  }}
                >
                  <FontAwesomeIcon
                    size="4x"
                    color="#D8D8D8"
                    icon={faArrowAltCircleLeft}
                  />
                </a>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <a
                  type="button"
                  onClick={onClickHandler}
                  title={label}
                  style={{
                    zIndex: '99',
                    position: 'absolute',
                    paddingRight: '10px',
                    top: 'calc(50%)',
                    right: '0px',
                  }}
                >
                  <FontAwesomeIcon
                    size="4x"
                    color="#D8D8D8"
                    icon={faArrowAltCircleRight}
                  />
                </a>
              )
            }
          >
            {subscriptionPlans.map((subscriptionPlan) => (
              <S.SubscriptionPlansWrapper>
                <S.SubscriptionPlansItem key={subscriptionPlan.id}>
                  <S.SubscriptionPlansHeader
                    style={{ backgroundColor: '#403EDC' }}
                  >
                    <S.SubscriptionPlansTitle>
                      {subscriptionPlan.name}
                    </S.SubscriptionPlansTitle>
                  </S.SubscriptionPlansHeader>

                <S.SubscriptionPlansContent>
                  <S.SubscriptionPlansPriceContainer>
                    <S.SubscriptionPlansMonetary>
                      R$
                    </S.SubscriptionPlansMonetary>
                    <S.SubscriptionPlansPrice>
                      {subscriptionPlan.amount ===
                        subscriptionPlan.amountWithDescount &&
                        (!subscriptionPlan.displayInstallmentAmount ||
                          subscriptionPlan.maximumInstallments === 1) &&
                        formatCurrency(subscriptionPlan.amount)}

                      {subscriptionPlan.amount ===
                        subscriptionPlan.amountWithDescount &&
                        subscriptionPlan.displayInstallmentAmount &&
                        subscriptionPlan.maximumInstallments > 1 &&
                        formatCurrency(subscriptionPlan.installmentValue)}

                      {subscriptionPlan.amount !==
                        subscriptionPlan.amountWithDescount &&
                        (!subscriptionPlan.displayInstallmentAmount ||
                          subscriptionPlan.maximumInstallments === 1) &&
                        formatCurrency(subscriptionPlan.amountWithDescount)}

                      {subscriptionPlan.amount !==
                        subscriptionPlan.amountWithDescount &&
                        subscriptionPlan.displayInstallmentAmount &&
                        subscriptionPlan.maximumInstallments > 1 &&
                        formatCurrency(
                          subscriptionPlan.installmentValueWithDescount,
                        )}
                    </S.SubscriptionPlansPrice>
                  </S.SubscriptionPlansPriceContainer>
                  {subscriptionPlan.displayInstallmentAmount &&
                    subscriptionPlan.maximumInstallments > 1 && (
                      <S.SubscriptionPlansMonth>
                        /mês por{' '}
                        <S.SubscriptionPlansMonthBold>
                          {subscriptionPlan.maximumInstallments} meses
                        </S.SubscriptionPlansMonthBold>
                      </S.SubscriptionPlansMonth>
                    )}
                </S.SubscriptionPlansContent>

                <S.SubscriptionPlansBenefitsList>
                  {subscriptionPlan.subscriptionPlanBenefits.map(
                    (subscriptionPlanBenefit) => (
                      <S.SubscriptionPlansDivider
                        key={subscriptionPlanBenefit.id}
                      >
                        <S.SubscriptionPlansBenefitsItem>
                          {subscriptionPlanBenefit.description}
                        </S.SubscriptionPlansBenefitsItem>
                      </S.SubscriptionPlansDivider>
                    ),
                  )}
                </S.SubscriptionPlansBenefitsList>

                <S.SubscriptionPlansFooter>
                  <S.SubscriptionPlansButton
                    style={{ backgroundColor: '#5FCC58' }}
                    onClick={() => handleSelectPlan(subscriptionPlan)}
                  >
                    <div>Selecionar Plano</div>

                    <div>
                      <ArrowForwardIcon
                        style={{
                          marginTop: '10px',
                          marginLeft: '15px',
                          fontSize: '30px',
                        }}
                      />
                    </div>
                  </S.SubscriptionPlansButton>
                </S.SubscriptionPlansFooter>
              </S.SubscriptionPlansItem>
            </S.SubscriptionPlansWrapper>
          ))}
        </Carousel>
      </Col>
    </Col>
    )

}

export default ModalPlansSelection
