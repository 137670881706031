import React from 'react'
import { useHistory } from 'react-router-dom'

import { studyPlansModeEnum } from '../study-plans.enums'

import liveValidate from '~/pages/studyplans/components/live-validate'

import Button from '~/shared/Button'

function StudyPlansModeComponent(props) {
  const history = useHistory()

  const { selectedMode } = props

  const idStudyPlans = studyPlansModeEnum.studyPlans
  const idSubjects = studyPlansModeEnum.subjects

  const liveShow = liveValidate()

  const modes = [
    {
      id: idStudyPlans,
      name: 'Planos de Estudos',
      index: 3,
      hidden: false,
      onClick: () => history.push('/painel'),
    },
    {
      id: idSubjects,
      name: 'Disciplinas',
      index: 2,
      hidden: false,
      onClick: () => history.push('/disciplina'),
    },
    {
      id: 3,
      name: 'Aulas Ao-Vivo',
      index: 1,
      hidden: !liveShow,
      onClick: () => history.push('/aulas-ao-vivo'),
    },
  ]

  return modes
    .filter(({ hidden }) => !hidden)
    .map((mode) => {
      const active = selectedMode === mode.id

      return (
        <Button
          key={mode.id}
          height="44px"
          borderColor={active ? 'transparent' : '#eaeaea'}
          backgroundColor={active ? '#fff' : '#f0f2f5'}
          fontSize="18px"
          fontWeight="bold"
          index={mode.index}
          onClick={mode.onClick}
          className="button-panel"
        >
          {mode.name}
        </Button>
      )
    })
}

export default StudyPlansModeComponent
