import React, { useState, useEffect } from 'react'

import { Skeleton } from 'antd'
import TestFormComponent from '../components/test-list.component'

import services from '../test.services'

function TestContainer() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    async function getData() {
      setLoading(true)
      try {
        const response = await services.getList()
        const { data } = response
        setData(data ? data : [])
        setLoading(false)
      } catch {
        setLoading(false)
      }
    }

    getData()
  }, [])

  if (loading) return <Skeleton active />
  return <TestFormComponent data={data} />
}

export default TestContainer
