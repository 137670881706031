import PrivateClasses from './containers/private-classes.container'

const routes = [
  {
    path: '/aulas-particulares',
    title: 'Aulas Particulares',
    authenticated: true,
    component: PrivateClasses,
  },
]

export default routes
