import React from 'react'
import formatCurrency from '../../subscription/utils/format-currency'
import useAuth from '~/hooks/useAuth'
import notification from '~/shared/Notification'
import messageNotificationVerifyUserDataInSubscription from '~/utils/messageNotificationVerifyUserDataInSubscription'
import * as S from '../modalLessonsCombo.styles'

function ModalLessonsComboCard(props) {
  const { data, setCurrentStep } = props

  const auth = useAuth()
  const { verifyUserDataInSubscription } = auth

  function handleVerifyUserData() {
    if (!verifyUserDataInSubscription) setCurrentStep(1)

    if (verifyUserDataInSubscription)
      notification.info(
        messageNotificationVerifyUserDataInSubscription(),
        '/minha-conta',
      )
  }

  return (
    <S.CardContainer>
      <S.InfoCardContent>
        <S.MediumText>{data.value}</S.MediumText>
        <S.SmallText isBold>{data.title}</S.SmallText>
      </S.InfoCardContent>
      <S.AmountCardContent>
        <S.MediumText>R$ {formatCurrency(data.amount)}</S.MediumText>
        <S.SmallText>
          {data.value === 1 ? 'Pagamento Único' : 'R$100/aula'}
        </S.SmallText>
      </S.AmountCardContent>
      <S.ButtonCard
        type="primary"
        size="large"
        onClick={() => {
          handleVerifyUserData()
        }}
      >
        COMPRAR
      </S.ButtonCard>
    </S.CardContainer>
  )
}

export default ModalLessonsComboCard
