import React, { useEffect, useState, useMemo } from 'react'
import { useHistory, Prompt } from 'react-router-dom'
import { Button, Space, Radio, Tabs, Row, Grid, Form, Col } from 'antd'
import CountDown from 'react-countdown'
import MathJax from 'react-mathjax-preview'
import { RightCircleOutlined, LeftCircleOutlined } from '@ant-design/icons'
import { Text } from '~/shared/Typography'
import notification from '~/shared/Notification'
import services from '../test.services'
import * as S from '../test.styles'
import Modal from '~/shared/Modal'

const { TabPane } = Tabs

const Timer = (props) => {
  const { timeLeftAsMilliseconds, finish } = props
  const renderer = ({ completed, formatted }) => {
    const { hours, minutes, seconds } = formatted
    if (completed) {
      finish()
    }

    if (hours != '00') {
      return (
        <span>
          {hours}:{minutes}:{seconds}
        </span>
      )
    }
    return (
      <span>
        {minutes}:{seconds}
      </span>
    )
  }

  return useMemo(
    () => (
      <Text>
        Duração:{' '}
        <CountDown
          zeroPadTime={2}
          date={Date.now() + timeLeftAsMilliseconds}
          renderer={renderer}
        />
      </Text>
    ),
    [timeLeftAsMilliseconds],
  )
}

function TestQuestionsComponent(props) {
  const { data } = props

  const {
    questions,
    testDescription,
    testGroupDescription,
    timeLeftAsMilliseconds,
  } = data
  const history = useHistory()
  const [open, setOpenModal] = useState(true)
  const [redirect, setRedirect] = useState(false)
  const [activeKey, setActiveKey] = useState(null)

  const { useBreakpoint } = Grid
  const screen = useBreakpoint()
  const { xl } = screen

  useEffect(() => {
    setActiveKey('1')
    window.onbeforeunload = (e) => {
      return true
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [])

  const finish = async () => {
    setOpenModal(false)
    await services.finish({
      studentTestId: localStorage.getItem('studentTestId'),
    })
    history.push('/resultado-simulado')
  }

  const onFinish = async () => {
    await Modal.confirmation(
      'Tem certeza que deseja encerrar o simulado?',
      async () => {
        try {
          await finish()
        } catch (err) {
          setOpenModal(false)
          setRedirect(true)
        }
      },
      true,
    )
  }

  async function saveAlternative(valueSelected, questionId, studentTestId) {
    try {
      await services.setAnswer({
        studentTestId,
        questionId,
        studentAnswerId: valueSelected,
      })
    } catch (err) {
      setOpenModal(false)
      setRedirect(true)
    }
  }

  const [form] = Form.useForm()

  useEffect(() => {
    if (redirect) {
      localStorage.removeItem('testId')
      localStorage.removeItem('studentTestId')
      notification.error('O tempo de duração do simulado acabou.')
      history.push('/simulado')
    }
  }, [redirect])

  // const sortedArray = questions.sort(function (a, b) {
  //   // eslint-disable-next-line no-nested-ternary
  //   return a.questionId < b.questionId
  //     ? -1
  //     : a.questionId > b.questionId
  //     ? 1
  //     : 0
  // })

  // console.log('questions', questions)
  // console.log('sortedArray', sortedArray)

  function previousExercise() {
    const numberQuestions = questions.length

    const actualKey = Number(activeKey)
    const keySelected = actualKey - 1
    if (keySelected <= numberQuestions && keySelected > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setActiveKey(String(keySelected))
    } else {
      notification.warning('Não há mais questões')
    }
  }

  function nextExercise() {
    const numberQuestions = questions.length

    const actualKey = Number(activeKey)
    const keySelected = actualKey + 1
    if (keySelected <= numberQuestions) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setActiveKey(String(keySelected))
    } else {
      notification.warning('Não há mais questões')
    }
  }

  function onChangeTabs(val) {
    setActiveKey(val)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <S.StyledLayout>
      <Prompt
        when={open}
        message="Você tem certeza que deseja sair do simulado? Caso saia esteja ciente que não será possível retornar ao mesmo."
      />
      <Space direction="vertical">
        <Text
          fontFamily="Open Sans"
          minFontSize="2.5vw"
          fontSize="12px"
          color="#434343"
        >
          {testGroupDescription} &gt; {testDescription}
        </Text>
        <Text fontSize="32px" fontWeight="bold" color="#434343">
          {testDescription}
        </Text>
        {timeLeftAsMilliseconds != 0 && (
          <Timer
            timeLeftAsMilliseconds={timeLeftAsMilliseconds}
            finish={finish}
          />
        )}
      </Space>

      <Tabs
        tabPosition="top"
        onChange={(val) => onChangeTabs(val)}
        activeKey={activeKey}
        className="row-alternative"
      >
        {questions.map((question, index) => (
          <TabPane key={index + 1} tab={`Questão ${index + 1}`}>
            <Form form={form}>
              <Text
                key={question.questionId}
                fontSize="14px"
                color="#434343"
                value={question.questionId}
              >
                <MathJax math={question.statement} />
              </Text>

              <Radio.Group>
                {question.answers.map((alternative) => (
                  <>
                    <Space size="large" direction="horizontal">
                      <S.StudyPlansCardFeedback
                        key={alternative.answerId}
                        width="960px"
                        size="small"
                        marginBottom="10px"
                      >
                        <Row
                          gutter={16}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Form.Item
                            name="idAlternative"
                            style={{ height: '10px' }}
                            className="radio-alternative"
                          >
                            <Col xs={1} md={1}>
                              <Radio
                                key={alternative.answerId}
                                value={alternative.answerId}
                                onChange={(event) =>
                                  saveAlternative(
                                    event.target.value,
                                    question.questionId,
                                    question.studentTestId,
                                  )
                                }
                              />
                            </Col>
                          </Form.Item>
                          <Col xs={20} md={23}>
                            <Text fontSize="14px">
                              <MathJax math={alternative.answer} />
                            </Text>
                          </Col>
                        </Row>
                      </S.StudyPlansCardFeedback>
                    </Space>
                  </>
                ))}
              </Radio.Group>
            </Form>
            <br />
            <Row justify={!xl ? 'start' : 'end'}>
              <Space direction="horizontal" size="middle">
                <Button
                  type="primary"
                  size="large"
                  onClick={previousExercise}
                  icon={<LeftCircleOutlined />}
                />
                <Button
                  type="primary"
                  size="large"
                  onClick={nextExercise}
                  icon={<RightCircleOutlined />}
                />
                <Button onClick={onFinish} type="primary" size="large">
                  Concluir Simulado
                </Button>
              </Space>
            </Row>
            <br />
          </TabPane>
        ))}
      </Tabs>
    </S.StyledLayout>
  )
}

export default TestQuestionsComponent
