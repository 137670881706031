import Test from './containers/test-list.container'
import TestQuestions from './containers/test-questions.container'
import TestConfirmation from './containers/test-confirmation.container'
import TestResultado from './containers/test-score.container'
import TestFeedback from './containers/test-feedback.container'

const routes = [
  {
    path: '/simulado',
    title: 'Simulado',
    authenticated: true,
    component: Test,
  },
  {
    path: '/confirmacao-simulado/:id',
    title: 'Confirmação Simulado',
    routeBack: '/simulado',
    external: true,
    authenticated: true,
    component: TestConfirmation,
  },
  {
    path: '/execucao-simulado',
    title: 'Execução Simulado',
    external: true,
    authenticated: true,
    component: TestQuestions,
  },
  {
    path: '/resultado-simulado',
    title: 'Resultado Simulado',
    routeBack: '/simulado',
    external: true,
    authenticated: true,
    component: TestResultado,
  },
  {
    path: '/gabarito-simulado',
    title: 'Gabarito Simulado',
    routeBack: '/simulado',
    external: true,
    authenticated: true,
    component: TestFeedback,
  },
]

export default routes
