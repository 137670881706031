import api from '~/services/api'

const services = {
  getPermission() {
    return api.get('/student/check-include-teaching-material')
  },

  getTeachingMaterials() {
    return api.get('/student/teaching-material')
  },
}

export default services
