import React from 'react'
import * as S from '../lessons.styles'

function LessonsExpandComponent(props) {
  const { isActive } = props

  return isActive ? <S.LessonsUpOutlinedIcon /> : <S.LessonsDownOutlinedIcon />
}

export default LessonsExpandComponent
