import api from '~/services/api'
import removeMask from '~/utils/remove-mask'

const services = {
  getUserDetails() {
    return api.get('/student/perfil')
  },
  dataUpdate(data) {
    const { firstName, lastName, cpf, mobile, email } = data.user

    const {
      address,
      cep,
      city,
      complement,
      district,
      number,
      state,
    } = data.payment

    return api.put('/student/perfil', {
      info: {
        firstName,
        lastName,
        cpf: cpf && removeMask(cpf),
        mobile: mobile && removeMask(mobile),
        email,
      },
      paymentInfo: {
        address,
        cep: cep && removeMask(cep),
        city,
        complement,
        district,
        number,
        state,
      },
    })
  },
}

export default services
