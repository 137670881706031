/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect } from 'react'
import { Button, Space, Radio, Tabs, Row, Card, Form, Col } from 'antd'
import {
  RightCircleOutlined,
  LeftCircleOutlined,
  CheckCircleOutlined,
  QuestionOutlined,
  ReloadOutlined,
} from '@ant-design/icons'

import MathJax from 'react-mathjax-preview'
import LayoutAntd from '~/shared/Layout'
import { Text } from '~/shared/Typography'
import notification from '~/shared/Notification'
import LessonsPlayerComponent from '~/pages/lessons/components/lessons-player.component'
import * as S from '../lessons-questions.styles'

const { TabPane } = Tabs

function LessonsQuestionsComponent(props) {
  const { questions, lesson } = props
  const [alternativeSelected, setAlternativeSelected] = useState(null)
  const [questionId, setQuestionId] = useState(null)
  const [correct, setCorrect] = useState(false)
  const [isFormSubmit, setIsFormSubmit] = useState(false)
  const [showCorrects, setShowCorrect] = useState(false)
  const [showResolution, setShowResolution] = useState(false)
  const [disableRedoExercise, setDisableRedoExercise] = useState(true)
  const [disableShowCorrect, setDisableShowCorrect] = useState(true)
  const [activeKey, setActiveKey] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    setActiveKey('1')
  }, [])

  // eslint-disable-next-line consistent-return
  function validateQuestion(values) {
    const optionSelected = Number(values.idAlternative)
    setAlternativeSelected(optionSelected)
    if (questionId) {
      const questionSelected = questions.filter(
        (question) => question.questionId === questionId,
      )

      const alternatives = questionSelected[0].answers
      const answer = alternatives.filter(
        (alternativesAnsers) => alternativesAnsers.answerId === optionSelected,
      )

      if (answer) {
        const { isCorrect } = answer[0]
        setCorrect(isCorrect)
      }

      setIsFormSubmit(true)
      setDisableRedoExercise(false)
      setDisableShowCorrect(false)
    } else {
      notification.error('Selecione uma alternativa ao responder.')
    }
  }

  function saveAlternative(valueSelected, questionId) {
    setIsFormSubmit(false)
    setQuestionId(questionId)
  }

  function onChangeTabs(val) {
    setActiveKey(val)
    setIsFormSubmit(false)
    setShowCorrect(false)
    setDisableShowCorrect(true)
    setDisableRedoExercise(true)
    setShowResolution(false)
    setAlternativeSelected(null)
    form.resetFields()
  }

  function getBorderAfterSubmit() {
    if (correct) {
      notification.success('Resposta correta.')
      return 'solid #90EE90'
    }

    notification.error('Resposta incorreta.')
    return 'solid #DB7093'
  }

  function getRealBorder(alternative) {
    if (showCorrects === true) {
      if (alternative.isCorrect) {
        return 'solid #90EE90'
      }

      return 'solid #DB7093'
    }

    return ''
  }

  function redoExercise() {
    setIsFormSubmit(false)
    setShowCorrect(false)
    setDisableShowCorrect(true)
    setDisableRedoExercise(true)

    form.setFieldsValue({ radioGroup: null })
  }

  function showCorrectAwnsers(questionSolutionTypeId) {
    setIsFormSubmit(true)
    setShowCorrect(true)
    setDisableShowCorrect(true)
    if (questionSolutionTypeId !== 1) setShowResolution(true)
    setDisableRedoExercise(false)
  }

  function previousExercise() {
    const numberQuestions = questions.length
    setIsFormSubmit(false)
    setShowCorrect(false)
    setDisableShowCorrect(true)
    setDisableRedoExercise(true)
    setAlternativeSelected(null)
    setShowResolution(false)
    form.resetFields()

    const actualKey = Number(activeKey)
    const keySelected = actualKey - 1
    if (keySelected <= numberQuestions && keySelected > 0) {
      setActiveKey(String(keySelected))
    } else {
      notification.warning('Não há mais exercícios')
    }
  }

  function nextExercise() {
    const numberQuestions = questions.length
    setIsFormSubmit(false)
    setShowCorrect(false)
    setDisableShowCorrect(true)
    setDisableRedoExercise(true)
    setAlternativeSelected(null)
    setShowResolution(false)
    form.resetFields()

    const actualKey = Number(activeKey)
    const keySelected = actualKey + 1
    if (keySelected <= numberQuestions) {
      setActiveKey(String(keySelected))
    } else {
      notification.warning('Não há mais exercícios')
    }
  }

  return (
    <S.StyledLayout>
      <Space direction="vertical">
        <Text
          fontFamily="Open Sans"
          minFontSize="2.5vw"
          fontSize="12px"
          color="#434343"
        >
          {lesson.studyPlanName} &gt; {lesson.disciplineName} &gt;{' '}
          {lesson.disciplineSubjectName} &gt; {lesson.title}
        </Text>
        <Text fontSize="32px" fontWeight="bold" color="#434343">
          {lesson.title}
        </Text>
      </Space>

      <Tabs
        onChange={(val) => onChangeTabs(val)}
        tabPosition="top"
        activeKey={activeKey}
        className="row-alternative"
      >
        {questions.map((question, index) => (
          <TabPane key={index + 1} tab={`Questão ${index + 1}`}>
            <Form form={form} onFinish={validateQuestion}>
              <Text
                key={question.questionId}
                fontSize="14px"
                color="#434343"
                value={question.questionId}
              >
                <MathJax math={question.statement} />
              </Text>
              <Form.Item name="radioGroup">
                <Radio.Group>
                  {question.answers.map((alternative) => (
                    <>
                      <Space size="large" direction="vertical">
                        <Card
                          size="small"
                          key={alternative.answerId}
                          style={
                            isFormSubmit === true &&
                            alternative.answerId === alternativeSelected &&
                            disableRedoExercise === true &&
                            disableShowCorrect === true
                              ? {
                                  border: getBorderAfterSubmit(),
                                  width: 960,
                                  marginBottom: 10,
                                }
                              : {
                                  border: getRealBorder(alternative),
                                  width: 960,
                                  marginBottom: 10,
                                }
                          }
                        >
                          <Row
                            gutter={16}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Form.Item
                              name="idAlternative"
                              style={{ height: '10px' }}
                              className="radio-alternative"
                            >
                              <Col xs={1} md={1}>
                                <Radio
                                  key={alternative.answerId}
                                  value={alternative.answerId}
                                  onChange={(event) =>
                                    saveAlternative(
                                      event.target.value,
                                      question.questionId,
                                    )
                                  }
                                  disabled={isFormSubmit}
                                />
                              </Col>
                            </Form.Item>
                            <Col xs={20} md={23}>
                              <Text fontSize="14px">
                                <MathJax math={alternative.answer} />
                              </Text>
                            </Col>
                          </Row>
                        </Card>
                      </Space>
                    </>
                  ))}
                </Radio.Group>
              </Form.Item>
              <br />
              <Row justify="end" className="actions-button">
                <Space direction="horizontal" size="middle">
                  <Button
                    type="primary"
                    size="large"
                    onClick={previousExercise}
                    icon={<LeftCircleOutlined />}
                  />
                  <Button
                    type="primary"
                    size="large"
                    onClick={nextExercise}
                    icon={<RightCircleOutlined />}
                  />
                  <Button
                    type="primary"
                    size="large"
                    onClick={() =>
                      showCorrectAwnsers(question.questionSolutionTypeId)
                    }
                    disabled={disableShowCorrect}
                    icon={<QuestionOutlined />}
                  >
                    Resolução
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={redoExercise}
                    disabled={disableRedoExercise}
                    icon={<ReloadOutlined />}
                  >
                    Refazer
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    size="large"
                    disabled={isFormSubmit}
                    icon={<CheckCircleOutlined />}
                    className="reply-button"
                  >
                    Responder
                  </Button>
                </Space>
              </Row>
              <br />
              {showResolution && (
                <Space direction="vertical">
                  <Text fontSize="22px" fontWeight="bold" color="#434343">
                    Resolução
                  </Text>

                  <Form>
                    <Space size="large" direction="horizontal">
                      <Card
                        style={{
                          width: 960,
                          marginBottom: 10,
                        }}
                      >
                        {question.questionSolutionTypeId === 2 && (
                          <Row gutter={16}>
                            <Col xs={24} md={24}>
                              <MathJax math={question.solutionContent} />
                            </Col>
                          </Row>
                        )}
                        {question.questionSolutionTypeId === 3 && (
                          <S.LessonsPlayer video={question.solutionVideoUrl} />
                        )}
                      </Card>
                    </Space>
                  </Form>
                </Space>
              )}
            </Form>
          </TabPane>
        ))}
      </Tabs>
    </S.StyledLayout>
  )
}

export default LessonsQuestionsComponent
