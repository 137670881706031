import api from '~/services/api'

const services = {
  getCoupon(data) {
    return api.get('/student/subscriptionplan/coupon', {
      params: {
        SubscriptionPlanId: data.subscriptionPlanId,
        DiscountCouponCode: data.couponValue || '',
      },
    })
  },
}

export default services
