import * as yup from 'yup'
import { cpf as cpfValidator } from 'cpf-cnpj-validator'

function installmentsTransform(value, originalValue) {
  if (originalValue === '') return 0
  return value
}

const schema = yup.object().shape({
  cep: yup.string().required('CEP obrigatório.'),
  address: yup.string().required('Endereço obrigatório.'),
  addressNumber: yup.string().required('Número obrigatório.'),
  addressComplement: yup.string(),
  addressDistrict: yup.string().required('Bairro obrigatório.'),
  addressCity: yup.string().required('Cidade obrigatória.'),
  addressState: yup.string().required('Estado obrigatório.'),

  name: yup.string().required('Nome do Cartão obrigatório.'),
  holderCpf: yup
    .string()
    .required('CPF obrigatório.')
    .test('cpfValidator', 'CPF inválido.', cpfValidator.isValid),
  number: yup.string().required('Número do Cartão obrigatório.'),
  expiry: yup.string().required('Validade do Cartão obrigatória.'),
  cvc: yup.string().required('Código do Cartão obrigatória.'),
  installments: yup
    .number()
    .transform(installmentsTransform)
    .required('Número de Parcelas obrigatório.')
    .min(1, 'Número de Parcelas obrigatório.'),
  termsConditions: yup
    .boolean()
    .oneOf([true], 'Termos e Condições obrigatório.'),
})

export default schema
