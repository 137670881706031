import api from '~/services/api'

const services = {
  login(data) {
    return api.post('/student/login', data)
  },

  getStudentDataInformation() {
    return api.get('/student/perfil')
  },
}

export default services
