import React from 'react'
import { Link } from 'react-router-dom'

import * as S from './styles'

import corujaSabiaWhite from '../../../../assets/img/coruja-sabia-white.svg'

function Header() {
  return (
    <S.HeaderWrapper>
      <Link to="/">
        <S.HeaderImage src={corujaSabiaWhite} alt="Coruja Sábia" />
      </Link>
    </S.HeaderWrapper>
  )
}

export default Header
