import React, { createContext, useState, useCallback } from 'react'

import useLocalStorage from '~/hooks/useLocalStorage'
import services from '~/pages/login/login.services'
import delightedSurvey from '~/utils/delighted-survey'

const AuthContext = createContext()

function AuthProvider(props) {
  const [storageUser, setStorageUser, removeStorageUser] = useLocalStorage(
    'user',
  )

  const [storageToken, setStorageToken, removeStorageToken] = useLocalStorage(
    'token',
  )

  const [, setStorageRefresh, removeStorageRefresh] = useLocalStorage('refresh')

  const [user, setUser] = useState(
    storageUser && storageToken ? storageUser : null,
  )

  const [loading, setLoading] = useState(false)

  const [
    storageVerifyUserDataInSubscription,
    setStorageVerifyUserDataInSubscription,
    removeStorageVerifyUserDataInSubscription,
  ] = useLocalStorage('verifyUserDataInSubscription')

  const [
    verifyUserDataInSubscription,
    setVerifyUserDataInSubscription,
  ] = useState(storageVerifyUserDataInSubscription || false)

  const { children } = props

  const getVerifyUserDataInSubscription = useCallback(async () => {
    setStorageVerifyUserDataInSubscription(false)
    setVerifyUserDataInSubscription(false)

    const { data } = await services.getStudentDataInformation()

    if (data?.info) {
      const { firstName, lastName, cpf, email, mobile } = data?.info

      if (!firstName || !lastName || !cpf || !email || !mobile) {
        setStorageVerifyUserDataInSubscription(true)
        setVerifyUserDataInSubscription(true)
      }

      let nameComplete = `${firstName} ${lastName}`
      delightedSurvey(nameComplete, email)
    }
  }, [setStorageVerifyUserDataInSubscription])

  const signIn = useCallback(
    async (values) => {
      setLoading(true)

      try {
        const response = await services.login(values)        
        const {
          name,
          studentId,
          token,
          studentFreemium,
          refresh,
          qtdPrivateClass,
        } = response.data

        setLoading(false)
        
        setUser({ name, studentId, email:values.email, studentFreemium, qtdPrivateClass })
        setStorageUser({ name, studentId, email:values.email, studentFreemium, qtdPrivateClass })

        setStorageToken(token)

        setStorageRefresh(refresh)

        await getVerifyUserDataInSubscription()
      } catch {
        setLoading(false)
      }
    },
    [
      setStorageUser,
      setStorageToken,
      setStorageRefresh,
      getVerifyUserDataInSubscription,
    ],
  )

  const signOut = useCallback(() => {
    setUser(null)
    removeStorageUser()

    removeStorageToken()

    removeStorageRefresh()

    removeStorageVerifyUserDataInSubscription()
  }, [
    removeStorageUser,
    removeStorageToken,
    removeStorageRefresh,
    removeStorageVerifyUserDataInSubscription,
  ])

  return (
    <AuthContext.Provider
      value={{
        signed: !!user?.name,
        user,
        loading,
        signIn,
        signOut,
        verifyUserDataInSubscription,
        getVerifyUserDataInSubscription,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext }

export default AuthProvider
