import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Skeleton, Button, Space, Row, Col } from 'antd'

import { Text } from '~/shared/Typography'
import services from '../test.services'

function TestQuestionsContainer() {
  const history = useHistory()
  const params = useParams()
  const { id } = params
  const [loadData, setLoadingData] = useState(true)
  const [data, setData] = useState({})
  const { name, hours, minutes, questions } = data

  const onClick = () => {
    localStorage.setItem('testId', id)
    history.push('/execucao-simulado')
  }

  const convertToHoursMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60

    return { hours, minutes }
  }

  async function getDetails(id) {
    try {
      const response = await services.getDetails(id)
      const { data } = response
      const { durationAsMinutes } = data

      const conversion = convertToHoursMinutes(durationAsMinutes)

      setData({
        ...data,
        ...conversion,
      })
      setLoadingData(false)
    } catch (err) {}
  }

  useEffect(() => {
    setLoadingData(true)
    getDetails(id)
  }, [id])

  return (
    <>
      {loadData && <Skeleton active />}
      {!loadData && (
        <>
          <Row>
            <Space>
              <Text fontSize="32px" fontWeight="bold" color="#434343">
                {name}
              </Text>
            </Space>
          </Row>
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '25px',
            }}
          >
            <Space>
              <Text fontSize="25px" fontWeight="bold" color="#434343">
                Orientações:
              </Text>
            </Space>
          </Row>

          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '35px',
            }}
          >
            <Space>
              <Text fontSize="25px" fontWeight="bold" color="#434343">
                - O simulado possui {questions} questões
                {hours || minutes
                  ? ` e o tempo de resposta de
                ${
                  hours
                    ? ` ${hours} horas e ${minutes} minutos`
                    : ` ${minutes} minutos`
                }`
                  : ''}
                .
              </Text>
            </Space>
          </Row>
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '35px',
            }}
          >
            <Space>
              <Text fontSize="25px" fontWeight="bold" color="#434343">
                - Cada simulado poderá ser respondido uma única vez.
              </Text>
            </Space>
          </Row>
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '35px',
            }}
          >
            <Space>
              <Text fontSize="25px" fontWeight="bold" color="#434343">
                - Os simulados abandonados serão considerados como feitos e
                também não poderão ser respondidos novamente.
              </Text>
            </Space>
          </Row>
          <Row>
            <Col
              xs={24}
              md={24}
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <Button
                type="primary"
                shape="round"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '50px',
                }}
                onClick={onClick}
              >
                <Text fontSize="32px" color="#fff">
                  Iniciar o simulado
                </Text>
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default TestQuestionsContainer
