import React, { useEffect, useState } from 'react'
import { Space, Row, Col, Tabs, Grid, Form, Radio, Button } from 'antd'
import Layout from '~/shared/Layout'
import { Text } from '~/shared/Typography'
import * as S from '../test.styles'
import MathJax from 'react-mathjax-preview'
import {
  RightCircleOutlined,
  LeftCircleOutlined,
  QuestionOutlined,
} from '@ant-design/icons'
import notification from '~/shared/Notification'

const { TabPane } = Tabs
function TestFeedbackComponent(props) {
  const { data } = props
  const { questions, testDescription, testGroupDescription } = data
  const [activeKey, setActiveKey] = useState('1')
  const [showCorrect, setShowCorrect] = useState(false)

  const { useBreakpoint } = Grid
  const screen = useBreakpoint()
  const { xl } = screen

  function previousExercise() {
    const numberQuestions = questions.length

    const actualKey = Number(activeKey)
    const keySelected = actualKey - 1
    if (keySelected <= numberQuestions && keySelected > 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setShowCorrect(false)
      setActiveKey(String(keySelected))
    } else {
      notification.warning('Não há mais questões')
    }
  }

  function nextExercise() {
    const numberQuestions = questions.length

    const actualKey = Number(activeKey)
    const keySelected = actualKey + 1
    if (keySelected <= numberQuestions) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setShowCorrect(false)
      setActiveKey(String(keySelected))
    } else {
      notification.warning('Não há mais questões')
    }
  }

  function showCorrectAwnsers() {
    setShowCorrect(!showCorrect)
  }

  function onChangeTabs(val) {
    setActiveKey(val)
    setShowCorrect(false)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <S.StyledLayout>
      <Space direction="vertical">
        <Text
          fontFamily="Open Sans"
          minFontSize="2.5vw"
          fontSize="12px"
          color="#434343"
        >
          {testGroupDescription} &gt; {testDescription}
        </Text>
        <Text fontSize="32px" fontWeight="bold" color="#434343">
          {testDescription}
        </Text>
      </Space>

      <Tabs
        tabPosition="top"
        onChange={(val) => onChangeTabs(val)}
        activeKey={activeKey}
      >
        {questions.map((question, index) => (
          <TabPane key={index + 1} tab={'Questão ' + (index + 1)}>
            <Form>
              <Text
                key={question.questionId}
                fontSize="14px"
                color="#434343"
                value={question.questionId}
              >
                <MathJax math={question.statement} />
              </Text>
              {question.answers.map((alternative) => (
                <>
                  <Space size={'large'} direction="horizontal">
                    <S.StudyPlansCardFeedback
                      key={alternative.answerId}
                      border={
                        alternative.correctAnswer
                          ? 'solid #90EE90'
                          : 'solid #DB7093'
                      }
                      width={'960px'}
                      marginBottom={'10px'}
                      size={'small'}
                    >
                      <Row
                        gutter={16}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Form.Item
                          name="idAlternative"
                          style={{ height: '10px' }}
                        >
                          <Col xs={1} md={1}>
                            <Radio
                              key={alternative.answerId}
                              checked={alternative.markedByStudent}
                              disabled={true}
                            />
                          </Col>
                        </Form.Item>
                        <Col xs={20} md={23}>
                          <Text fontSize="14px">
                            <MathJax math={alternative.answer} />
                          </Text>
                        </Col>
                      </Row>
                    </S.StudyPlansCardFeedback>
                  </Space>
                </>
              ))}
            </Form>
            <br />
            <Row justify={!xl ? 'start' : 'end'}>
              <Space direction="horizontal" size="middle">
                <Button
                  type="primary"
                  size="large"
                  onClick={previousExercise}
                  icon={<LeftCircleOutlined />}
                ></Button>
                <Button
                  type="primary"
                  size="large"
                  onClick={nextExercise}
                  icon={<RightCircleOutlined />}
                ></Button>
                {question.questionSolutionTypeId !== 1 && (
                  <Button
                    size="large"
                    onClick={showCorrectAwnsers}
                    icon={<QuestionOutlined />}
                  >
                    Resolução
                  </Button>
                )}
              </Space>
            </Row>
            <br />
            {showCorrect && <Resolution question={question} />}
          </TabPane>
        ))}
      </Tabs>
    </S.StyledLayout>
  )
}

function Resolution(props) {
  const { question } = props

  useEffect(() => {
    setTimeout(function () {
      const el = document.getElementById(`solution${question.questionId}`)
      el.scrollIntoView({
        behavior: 'smooth',
      })
    }, 300)
  })

  return (
    <Space direction="vertical">
      <Text fontSize="22px" fontWeight="bold" color="#434343">
        Resolução
      </Text>

      <Form
        id={`solution${question.questionId}`}
        tabIndex="0"
        style={{ outline: '0' }}
      >
        <Space size="large" direction="horizontal">
          <S.StudyPlansCardFeedback width={'960px'} marginBottom={'10px'}>
            {question.questionSolutionTypeId === 2 && (
              <Row gutter={16}>
                <Col xs={24} md={8}>
                  <MathJax math={question.solutionContent} />
                </Col>
              </Row>
            )}
            {question.questionSolutionTypeId === 3 && (
              <S.TestPlayer video={question.solutionVideoId} />
            )}
          </S.StudyPlansCardFeedback>
        </Space>
      </Form>
    </Space>
  )
}

export default TestFeedbackComponent
