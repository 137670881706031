import ExtractSignatureContainer from './containers/extractsignature.container'

const routes = [
  {
    path: '/extrato-assinatura',
    component: ExtractSignatureContainer,
    authenticated: true,
  },
]

export default routes
