import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AutoComplete, Input, Spin } from 'antd'
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons'
import api from '~/services/api'

import * as S from '~/pages/_layouts/default/default.styles'

function DefaultSearchComponent() {
  const [lessons, setLessons] = useState([])
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  async function getLessons(value) {
    setLoading(true)
    const response = await api.get('/student/lesson/search', {
      params: {
        filter: value,
      },
    })
    // console.log('DATA', response.data)
    setLoading(false)
    setLessons(response.data)
  }

  function onSelect(value) {
    history.push(value)
  }

  const options = lessons.map((lesson, index) => (
    <AutoComplete.Option
      id={lesson.disciplineLessonId}
      key={index.toString()}
      value={`${lesson.studyPlanId}/aula/${lesson.disciplineLessonId}`}
    >
      {lesson.step.replace('Etapa', 'Diciplina')} {'>'} {lesson.disciplineName}{' '}
      {'>'} {lesson.lessonTitle}
    </AutoComplete.Option>
  ))

  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

  return (
    <AutoComplete
      dataSource={options}
      dropdownMatchSelectWidth={500}
      onChange={getLessons}
      onSelect={onSelect}
      style={{ marginRight: '20px', flex: 1 }}
    >
      <Input
        placeholder="   Buscar uma aula"
        style={{ borderRadius: '40px', height: '40px' }}
        suffix={loading ? <Spin indicator={loadingIcon} /> : <SearchOutlined />}
        visible
        iconRender={<SearchOutlined />}
      />
    </AutoComplete>
  )
}

export default DefaultSearchComponent
