import StudentFormListContainer from './containers/student-form-list.container'
import StudentFormTakeQuestionsContainer from './containers/student-form-take-questions.container'
import StudentFormViewContainer from './containers/student-form-view.container'

const routes = [
  {
    path: '/duvidas',
    component: StudentFormListContainer,
    authenticated: true,
  },
  {
    path: '/duvidas/tirar',
    routeBack: '/duvidas',
    external: true,
    component: StudentFormTakeQuestionsContainer,
    authenticated: true,
  },
  {
    path: '/duvidas/visualizacao/:id',
    routeBack: '/duvidas',
    external: true,
    component: StudentFormViewContainer,
    authenticated: true,
  },
]

export default routes
