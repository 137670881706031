import React from 'react'
import { Empty } from 'antd'

import { Text } from '~/shared/Typography'

function DisciplinesEmptyComponent() {
  return (
    <Empty
      description={<Text fontSize="16px">Nenhuma disciplina encontrada.</Text>}
    />
  )
}

export default DisciplinesEmptyComponent
