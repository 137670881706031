import React from 'react'
import { Collapse } from 'antd'
import { ContainerCollapse } from '../liveClasses.styles'
import { CaretRightOutlined } from '@ant-design/icons'

function LiveClassesTimelineComponent({ setVideo, timelineData, video }) {

  const { Panel } = Collapse  

  return (

    <ContainerCollapse>
      <Collapse
        key={1}
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        defaultActiveKey={new Date().getFullYear()}
        className="collapse-main"
      >
        {timelineData?.slice(0).reverse().map((yearObj) => {

          return (
            <Panel
              header={yearObj.year}
              key={yearObj.year}
              style={{ textTransform: 'capitalize' }}
              >
                <Collapse
                    key={2}
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    defaultActiveKey={ video ? new Date(video.dateLiveClass).getMonth() + 1 : 0 }
                    className="collapse-main"
                >
                  
                  { yearObj.listMonth.map((monthObj) => {

                    let currentMonth = new Date();
                    currentMonth.setMonth(monthObj.month - 1);
                    const monthHeader = currentMonth.toLocaleString('pt-br', { month: 'long' })

                    const defaultActiveKeys = monthObj.lives?.map(live => {

                      if(live && video) {
                        const liveDate = new Date(live.dateLiveClass);
                        const selectedVideoDate = new Date(video.dateLiveClass);
                        if(liveDate.getDate() && liveDate.getDate() === selectedVideoDate.getDate()) return live.id;
                      }
                    }).filter( el => { if(el) return el; })        

                    return (

                      <Panel
                        header={monthHeader}
                        key={monthObj.month}
                        style={{ textTransform: 'capitalize' }}
                      >
                        <Collapse
                          key={2}
                          bordered={false}
                          expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                          )}
                          defaultActiveKey={ defaultActiveKeys }
                          className="collapse-main"
                        >
                          { monthObj.lives?.map((videoObj) => {

                            const dateObj = new Date(videoObj.dateLiveClass);
                            const date = dateObj.toLocaleDateString('pt-br')                            
                            const time = ("0" + dateObj.getHours()).slice(-2) + 'h' + ("0" + dateObj.getMinutes()).slice(-2)
                            const day = dateObj.toLocaleDateString('pt-br', { weekday: 'long' })
                            
                            return (
                              <Panel
                                header={date + " - " + day}
                                key={videoObj.id}
                                className="panel-text-half"
                                style={{ cursor: 'pointer'}}                        
                              >
                                <div onClick={() => setVideo(videoObj)} >
                                  {!!time && <p>Horário: {time}</p>}
                                  {!!videoObj.disciplineName && 
                                    <p>Disciplina: {videoObj.disciplineName}</p>
                                  }
                                </div>
                              </Panel>
                            )
                          })}
                        </Collapse>
                      </Panel>
                    )
                  })} 
              </Collapse>
            </Panel>  
          )         
        })}
      </Collapse>
    </ContainerCollapse>
  )
}

export default LiveClassesTimelineComponent
