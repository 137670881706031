import styled from 'styled-components'
import { Layout as AntdLayout } from 'antd'

export const Layout = styled(AntdLayout)`
  min-height: 100vh;

  &.min-height-empty {
    min-height: auto;
  }
`
