import styled, { css } from 'styled-components'
import media from 'styled-media-query'

const TableWrapper = styled.div`
  ${({ theme }) => css`
    .text-capitalize {
      text-transform: capitalize;
    }

    .table-content .ant-table-thead .ant-table-cell {
      font-weight: bold;
    }

    .table-content.cursor-pointer .ant-table-row.ant-table-row-level-0 {
      cursor: pointer;
    }

    ${media.lessThan('medium')`
      .table-title {
        display: none;
      }
    `}

    ${media.between('medium', '1024px')`
      .table-title {
        display: block;
      }
    `}

    .ant-table {
      margin-top: ${theme.spacings.xxsmall};
      display: block;

      ${media.lessThan('medium')`
        &-cell {
          text-align: left !important;
        }

        &-thead > tr,
        &-tbody > tr {
          th,
          td {
            &:first-of-type {
              padding-top: 1rem;
            }

            &:last-of-type {
              padding-bottom: 1rem;
            }
          }

          > th,
          > td {
            padding: 0 1rem;
            width: auto !important;
            font-size: 1.1rem;
            border: none;
            display: block;

            &:last-child {
              border-bottom: 1px solid ${theme.colors.inputBorder};
            }
          }
        }
      `}

      ${media.between('medium', '1024px')`
        &-thead {
          display: none;
        }
      `}
    }
  `}
`

const TableText = styled.p`
  ${({ theme }) => css`
    margin: 0;
    font-size: ${theme.font.sizes.xxxsmall};
  `}
`

const TableSpan = styled.span`
  font-weight: bold;
`

const TableButton = styled.div`
  ${({ theme }) => css`
    display: inline-block !important;

    ${media.lessThan('medium')`
      span {
        padding: 5px;
        font-size: ${theme.font.sizes.xxxsmall} !important;
        display: inline-block !important;
      }
    `}
  `}
`

export { TableWrapper, TableText, TableSpan, TableButton }
