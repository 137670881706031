import styled from 'styled-components'
import { Menu as AntdMenu, Layout } from 'antd'

const StyledLayout = styled(Layout)`
  background-color: #fff;
`
const Menu = styled(AntdMenu)`
  max-width: 100%;
`

const MenuItem = styled(AntdMenu.Item)``

export { Menu, MenuItem, StyledLayout }
