import styled from 'styled-components'
import { Input } from 'antd'

const InputSearch = styled(Input.Search)`
  && {
    padding-top: 12px;
    padding-left: 32px;
    padding-right: 8px;
    padding-bottom: 12px;

    width: ${(props) => (props.width ? props.width : '100%')};

    border: 1px solid #e8e8e8;
    border-radius: 40px;
    box-shadow: 0px 6px 6px #0000000a;

    font-family: 'Open Sans';

    .ant-input-search-icon::before {
      border: 0;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`

export default InputSearch
