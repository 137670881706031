/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Row, Col } from 'react-flexbox-grid'
import Payment from 'payment'

import CreditCard from '~/pages/subscription/components/Checkout/components/CreditCard'
import StepsButton from '../StepsButton'

import Heading from '~/pages/subscription/shared/Heading'
import Input from '~/pages/subscription/shared/Input'
import InputMask from '~/pages/subscription/shared/InputMask'
import removeMask from '~/pages/subscription/utils/remove-mask'
import Select from '~/pages/subscription/shared/Select'

import useData from '~/pages/subscription/hooks/useData'
import useAuth from '~/hooks/useAuth'

import api from '~/services/api'

import brazilStates from '~/pages/subscription/utils/brazil-states'

function PaymentForm(props) {
  const [focused, setFocused] = useState('')
  const [data, setData] = useState({})

  const { address, cep, city, complement, district, number, state } = data

  const {
    currentStep,
    previousStep,
    nextStep,
    paymentMethodId,
    setCurrentStep,
  } = props

  useEffect(() => {
    async function getData() {
      const response = await api.get('student/perfil')

      const { data } = response
      const { paymentInfo } = data
      setData(paymentInfo)
    }
    getData()
  }, [])

  const onChange = (obj) => {
    setData({ ...data, ...obj })
  }

  const {
    getInstallmentsAmount,
    installmentsAmount,
    getTastingValidate,
  } = useData()

  const auth = useAuth()
  const { studentId } = auth.user

  const { watch } = useFormContext()

  useEffect(() => {
    getInstallmentsAmount(38, '')
    getTastingValidate(38, '', studentId)
  }, [studentId])

  useEffect(() => {
    if (paymentMethodId === 1) getInstallmentsAmount(38, '')
  }, [paymentMethodId])

  const card = watch(['number', 'name', 'expiry', 'cvc'])

  function handleInputFocus(event) {
    setFocused(event.target.name)
  }

  function clearNumber(value = '') {
    return value.replace(/\D+/g, '')
  }

  function handleInputChange(event) {
    const { value } = event.target

    if (!value) return value

    const issuer = Payment.fns.cardType(value)
    const clearValue = clearNumber(value)

    let nextValue

    switch (issuer) {
      case 'amex':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10,
        )} ${clearValue.slice(10, 15)}`
        break

      case 'dinersclub':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10,
        )} ${clearValue.slice(10, 14)}`
        break

      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8,
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`
        break
    }

    return nextValue.trim()
  }

  return (
    <>
      <Heading lineBottom>Pagamento</Heading>

      {paymentMethodId === 1 && (
        <>
          <CreditCard
            number={card.number}
            name={card.name}
            expiry={card.expiry}
            cvc={card.cvc}
            focused={focused}
          />

          <Row>
            <Col md={6}>
              <Input
                name="name"
                placeholder="Titular do Cartão"
                onFocus={handleInputFocus}
              />
            </Col>

            <Col md={6}>
              <InputMask
                inputMode="numeric"
                name="holderCpf"
                placeholder="CPF"
                mask="999.999.999-99"
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Input
                inputMode="numeric"
                name="number"
                placeholder="Número do Cartão"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </Col>

            <Col md={6}>
              <InputMask
                inputMode="numeric"
                name="expiry"
                placeholder="Validade do Cartão"
                mask="99/99"
                onFocus={handleInputFocus}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <InputMask
                inputMode="numeric"
                name="cvc"
                placeholder="Código do Cartão"
                mask="9999"
                onFocus={handleInputFocus}
              />
            </Col>

            <Col md={6}>
              <Select
                name="installments"
                placeholder="Parcelas"
                items={installmentsAmount}
              />
            </Col>
          </Row>

          <Row>
            <Col xs={6} md={6}>
              <Input
                name="address"
                placeholder="Endereço"
                value={address}
                onChange={(e) => onChange({ address: e.target.value })}
              />
            </Col>

            <Col xs={12} md={6}>
              <Input
                inputMode="numeric"
                name="addressNumber"
                placeholder="Número"
                value={number}
                onChange={(e) => onChange({ number: e.target.value })}
              />
            </Col>

            <Col xs={12} md={6}>
              <Input
                name="addressComplement"
                placeholder="Complemento"
                value={complement}
                onChange={(e) => onChange({ complement: e.target.value })}
              />
            </Col>

            <Col xs={12} md={6}>
              <Input
                name="addressDistrict"
                placeholder="Bairro"
                value={district}
                onChange={(e) => onChange({ district: e.target.value })}
              />
            </Col>

            <Col xs={16} md={3}>
              <Input
                name="addressCity"
                placeholder="Cidade"
                value={city}
                onChange={(e) => onChange({ city: e.target.value })}
              />
            </Col>

            <Col xs={12} md={3}>
              <Select
                name="addressState"
                items={brazilStates}
                value={state}
                onChange={(e) => onChange({ state: e.target.value })}
              />
            </Col>

            <Col md={3}>
              <InputMask
                inputMode="numeric"
                name="cep"
                placeholder="CEP"
                mask="99999-999"
                value={cep ? removeMask(cep) : cep}
                onChange={(e) => setData(e.target.value)}
              />
            </Col>
          </Row>
        </>
      )}

      <Row end="xs" style={{ margin: 0 }}>
        <Col xs={12}>
          <Input type="checkbox" name="termsConditions">
            <span style={{ fontSize: 14 }}>
              Li e concordo com os{' '}
              <a
                href="https://corujasabia.com.br/termos-de-uso"
                target="_blank"
                rel="noreferrer"
              >
                termos e condições
              </a>
              .
            </span>
          </Input>
        </Col>
      </Row>

      <StepsButton
        // currentStep={currentStep}
        // validateInputs={validateInputs}
        currentStep={2}
        // previousStep={previousStep}
        // nextStep={nextStep}
        nextStep={2}
        style={{ margin: 0 }}
        setCurrentStep={setCurrentStep}
      />
    </>
  )
}

export default PaymentForm
