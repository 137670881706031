import styled, { css } from 'styled-components'
import { CheckCircleOutlined } from '@ant-design/icons'

const CouponRow = styled.div`
  ${({ theme }) => css`
    margin: 0;
    width: 100%;
    display: block;

    .coupon-row {
      margin: 0;
    }

    .coupon-active {
      padding: 10px 10px 0 10px;
      margin-bottom: ${theme.spacings.xxsmall};
      background: ${theme.colors.white};
      border: 1px solid ${theme.colors.input};
      border-radius: 5px;
    }
  `}
`

const CouponWrapper = styled.div`
  &.relative {
    position: relative;
  }

  &.discount-coupon .coupon-icon .anticon {
    position: absolute;
    top: 17px;
    right: 20px;
  }
`

const CouponInformation = styled.div`
  position: relative;
`

const CouponTextBase = styled.p`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xxsmall};
    font-size: ${theme.font.sizes.xxsmall};
  `}

  &.title-discount-applied {
    ${({ theme }) => css`
      padding-bottom: 5px;
      margin-bottom: 10px;
      border-bottom: 1px solid ${theme.colors.input};
      display: block;
    `}
  }
`

const CouponText = styled(CouponTextBase)`
  display: flex;
  justify-content: space-between;
`

const CouponTextSecondary = styled(CouponTextBase)`
  text-align: right;
`

const CouponIcon = styled.div`
  width: 100%;
  display: block;
  cursor: pointer;

  &.applied {
    ${({ theme }) => css`
      color: ${theme.colors.secondary};
    `}
  }

  &.removed {
    ${({ theme }) => css`
      color: ${theme.colors.close};
    `}
  }
`

const CouponCheckCircleOutlined = styled(CheckCircleOutlined)`
  ${({ theme }) => css`
    color: ${theme.colors.secondary};
  `}
`

export {
  CouponRow,
  CouponWrapper,
  CouponInformation,
  CouponText,
  CouponTextSecondary,
  CouponIcon,
  CouponCheckCircleOutlined,
}
