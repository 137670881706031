import React from 'react'
import { Skeleton } from 'antd'

function Loader(props) {
  const { loading, children } = props

  if (loading) return <Skeleton active />
  return children
}

export default Loader
