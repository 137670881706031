import { Col, Row, Form, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import useData from '~/pages/subscription/hooks/useData'
import Select from '~/shared/Select'
import Space from '~/shared/Space'
import StudyPlansButtonComponent from '../components/study-plans-button.component'
import StudyPlansFilterComponent from '../components/study-plans-filter.component'
import StudyPlansHandlerComponent from '../components/study-plans-handler.component'
import StudyPlansModeComponent from '../components/study-plans-mode.component'
import StudyPlansViewComponent from '../components/study-plans-view.component'
import { studyPlansModeEnum, studyPlansViewEnum } from '../study-plans.enums'
import services from '../study-plans.services'
import * as S from '../study-plans.styles'

function StudyPlansContainer() {
  const { Text } = Typography

  const [loadingStudyPlans, setLoadingStudyPlans] = useState(true)
  const [loadingStudyPlansContent, setLoadingStudyPlansContent] = useState(true)

  const [studyPlans, setStudyPlans] = useState([])

  const [selectedView, setSelectedView] = useState(studyPlansViewEnum.calendar)

  const [selectedStudyPlan, setSelectedStudyPlan] = useState(null)
  const [selectedStudentStudyPlan, setSelectedStudentStudyPlan] = useState(null)

  const [selectedStudyPlanContent, setSelectedStudyPlanContent] = useState(null)

  const [filters, setFilters] = useState()

  const { setAccessLiveClasses } = useData()

  async function getStudyPlans() {
    const response = await services.getStudyPlansWeek()
    setLoadingStudyPlans(false)

    const studyPlans = response.data
    if (response.data.length === 0) {
      setLoadingStudyPlansContent(false)
    }

    setStudyPlans(studyPlans)
    setSelectedStudyPlan(studyPlans.length && studyPlans[0].studyPlanId)
    setSelectedStudentStudyPlan(
      studyPlans.length && studyPlans[0].studentStudyPlanId,
    )

    if (studyPlans.length && studyPlans[0]?.accessLiveClasses)
      setAccessLiveClasses(true)
  }

  useEffect(() => {
    getStudyPlans()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getById() {
      const response = await services.getById(selectedStudyPlan, filters)

      setLoadingStudyPlansContent(false)
      setSelectedStudyPlanContent(response.data)
    }

    if (selectedStudyPlan) {
      setLoadingStudyPlansContent(true)
      getById()
    }
  }, [selectedStudyPlan, filters])

  function selectedStudyPlanContentAnchorChange(item) {
    const itemNew = item?.split(' ').join('-')

    if (itemNew)
      document
        .getElementById(itemNew)
        .scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  function onSubmit(values) {
    if (values.filters && values.filters !== '') setFilters(values)
    else setFilters({ filters: values })
  }

  return (
    <Space size="large" direction="vertical">
      <Row gutter={24} align="middle">
        <Col xs={24} md={24}>
          <StudyPlansModeComponent
            selectedMode={studyPlansModeEnum.studyPlans}
          />
        </Col>
      </Row>

      {!!studyPlans.length && (
        <S.Wrapper>
          <Row gutter={[24, 24]} align="middle">
            <Col
              style={{
                justifyContent: 'start',
                display: 'flex',
                padding: '12px 32px 12px 12px',
                flexWrap: 'wrap',
              }}
              xs={24}
              md={24}
            >
              <Form.Item
                label={<Text fontFamily="Open Sans">Selecione um Plano</Text>}
                style={{
                  margin: '0',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <S.Select
                  size="large"
                  defaultValue={studyPlans && studyPlans[0].name}
                  bordered
                  onChange={(studyPlan) => {
                    const value = JSON.parse(studyPlan)
                    setSelectedStudyPlan(value.studyPlanId)
                    setSelectedStudentStudyPlan(value.studentStudyPlanId)
                  }}
                  listItemHeight={10}
                  listHeight={250}
                >
                  {studyPlans.map((studyPlan, index) => {
                    return (
                      <Select.Option
                        key={studyPlan.studentStudyPlanId}
                        value={JSON.stringify(studyPlan)}
                      >
                        {studyPlan.name}
                      </Select.Option>
                    )
                  })}
                </S.Select>
              </Form.Item>
              {/*          <StudyPlansButtonComponent
                loadingStudyPlans={loadingStudyPlans}
                studyPlans={studyPlans}
                selectedStudentStudyPlan={selectedStudentStudyPlan}
                setSelectedStudentStudyPlan={setSelectedStudentStudyPlan}
                setSelectedStudyPlan={setSelectedStudyPlan}
              /> */}
            </Col>
          </Row>

          <Row
            gutter={[24, 24]}
            align="middle"
            justify="space-between"
            className="go-next-week"
          >
            <Col xs={24} md={12} className="select-go-next-week">
              <Select
                size="large"
                defaultValue={null}
                bordered={false}
                onChange={(item) => selectedStudyPlanContentAnchorChange(item)}
                listItemHeight={10}
                listHeight={250}
                className="box-select"
              >
                <Select.Option value={null}>Ir para a semana</Select.Option>

                {selectedStudyPlanContent?.steps.map((item) => (
                  <Select.Option key={item.id} value={item.label}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col xs={24} md={12} className="position-unset">
              <Row gutter={24} align="middle">
                <Col xs={24} md={24} className="position-unset">
                  <Row gutter={[24, { xs: 24 }]} align="middle" justify="end">
                    <Col xs={24} md={20} xxl={22}>
                      <StudyPlansFilterComponent onSubmit={onSubmit} />
                    </Col>

                    <Col
                      xs={24}
                      md={4}
                      xxl={2}
                      className="study-plans-view-action"
                    >
                      <StudyPlansViewComponent
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </S.Wrapper>
      )}

      <StudyPlansHandlerComponent
        loadingStudyPlansContent={loadingStudyPlansContent}
        studyPlans={studyPlans}
        selectedView={selectedView}
        selectedStudyPlanContent={selectedStudyPlanContent}
      />
    </Space>
  )
}

export default StudyPlansContainer
