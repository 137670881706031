import styled from 'styled-components'
import { Col, Row } from 'react-flexbox-grid'
import GoogleButton from 'react-google-button'
import TextField from '@material-ui/core/TextField'

export const Header = styled(Row)({
  alignItems: 'center',
  marginRight: 40,
  marginLeft: 40,
  marginTop: 20,
  marginBottom: 40,
  justifyContent: 'space-between',
})

export const ContainerImg = styled(Col)`
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  @media only screen and (max-width: 770px) {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`

export const ContainerText = styled(Col)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  @media only screen and (max-width: 770px) {
    align-items: center;
    justify-content: center;
    display: flex;
  }
`

export const ButtonGoogle = styled(GoogleButton)({
  width: '100%',
  borderRadius: '4px',
  boxShadow: '0 3px 4px 0 rgba(0,0,0,.25)',
  marginBottom: 40,
  marginTop: 20,
})

export const Input = styled(TextField)({
  height: '80px',
  width: '100%',
  fontSize: 40,
})

export const Button = styled('button')({
  borderWidth: 0,
  flex: 1,
  display: 'flex',
  width: '100%',
  height: 55,
  borderRadius: 6,
  alignItems: 'center',
  justifyContent: 'center',
})

export const TextCreatedSingIn = styled('p')({
  textAlign: 'center',
  margin: '20px',
  font: 'normal normal bold 2rem Futura PT',
  fontWeight: 'bold',
  color: '#34485C',
})

export const InnerButton = styled('div')({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

// export const TextSingIn = styled('p')({
//   flexDirection: 'row',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',

// })

export const TextSingIn = styled.p`
  font-family: 'Open Sans';
  text-align: end;
  font-size: 1.3rem;

  @media only screen and (max-width: 765px) {
    font-size: 16px;
    font-family: 'Open Sans';
  }

  /* @media only screen and (max-width: '300px') {
    font-size: '10px';
  }
  @media only screen and (min-width: '500px') {
    font-size: '10px';
  } */
`

export const TextInfo = styled.p`
  font-family: 'Open Sans';
  text-align: center;
  font-size: 24px;
  line-height: 1.4;
  color: #34485c;
  font-weight: bold;

  @media only screen and (max-width: 765px) {
    font-family: 'Open Sans';
    text-align: center;
    font-size: 16px;
    line-height: 1;
    color: #34485c;
    font-weight: bold;
  }
`
export const TextSubInfo = styled.p`
  font-family: 'Open Sans';
  text-align: center;
  font-size: 22px;
  color: #606f7f;

  @media only screen and (max-width: 765px) {
    font-size: 16px;
    font-family: 'Open Sans';
    color: #606f7f;
  }
`

export const TextTerms = styled.p`
  font-family: 'Open Sans';
  text-align: center;
  font-size: 16px;
  color: #34485c;

  @media only screen and (max-width: 765px) {
    font-size: 12px;
    font-family: 'Open Sans';
    color: #34485c;
  }
`
