import api from '~/services/api'

const services = {
  getList(data) {
    let url = `/student/forum/post/search?`

    const { filter, disciplineId, onlyMyPosts } = data

    if (disciplineId) url = `${url}&disciplineId=${disciplineId}`

    if (filter) url = `${url}&filter=${filter}`

    if (onlyMyPosts) url = `${url}&onlyMyPosts=${onlyMyPosts}`

    return api.get(url)
  },
  getDiscipline() {
    return api.get(`/student/forum/discipline`)
  },
  post(data) {
    return api.post(`/student/forum/post`, data)
  },
  get(forumPostId) {
    return api.get(`/student/forum/post/${forumPostId}`)
  },
  postImage(data) {
    return api.post('/resource/image/upload', data)
  },
}

export default services
