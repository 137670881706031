import React from 'react'
import * as S from './styles'

function Header(props) {
  const { children } = props

  return <S.Header>{children}</S.Header>
}

export default Header
