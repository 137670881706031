import TeachingMaterials from './containers/teachingMaterials-list.container'

const routes = [
  {
    path: '/material-didatico',
    title: 'Materiais Didáticos',
    authenticated: true,
    component: TeachingMaterials,
  },
]

export default routes
