/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Collapse } from 'antd'

import StudyPlanExpandComponent from './disciplines-expand.component'
import StudyPlanProgressComponent from './disciplines-progress.component'

import * as S from '../disciplines.styles'

import Space from '~/shared/Space'
import Box from '~/shared/Box'
import Card from '~/shared/Card'
import { Text } from '~/shared/Typography'

function DisciplinesListComponent(props) {
  const { disciplines } = props

  const history = useHistory()

  function getTagColor(typeId) {
    if (typeId === 1) return '#403edc'
    if (typeId === 2) return '#46d83f'
    return '#cc3366'
  }

  function redirectLesson(idStudyPlan, id) {
    history.push(`${idStudyPlan}/aula/${id}`)
  }

  useEffect(() => {
    localStorage.setItem('isStudyPlan', false)
  }, [])

  if (!disciplines.length) return null

  return (
    <Collapse
      defaultActiveKey={disciplines.map(
        (disciplineContentId) => disciplineContentId.disciplineId,
      )}
      expandIcon={(expandIcon) => (
        <StudyPlanExpandComponent isActive={expandIcon.isActive} />
      )}
      ghost
    >
      {disciplines.map((discipline) => (
        <S.DisciplinesCollapsePanel
          key={discipline.disciplineId}
          header={
            <>
              <Row align="middle" justify="space-between">
                <Box ml="32px">
                  <Text fontSize="18px" fontWeight="bold" color="#434343">
                    {discipline.name}
                  </Text>
                </Box>

                <Text fontFamily="Open Sans" fontSize="12px" color="#434343">
                  {discipline.lessonsDone} de {discipline.totalLessons} aulas
                  assistidas
                </Text>
              </Row>

              <StudyPlanProgressComponent
                percent={discipline.completation * 10}
              />
            </>
          }
        >
          <Space size="large" direction="vertical">
            {discipline.subjects.map((subject) => (
              <Card size="small" padding="8px 24px">
                <Collapse
                  expandIcon={(expandIcon) => (
                    <StudyPlanExpandComponent isActive={expandIcon.isActive} />
                  )}
                  ghost
                  expandIconPosition="right"
                  key={subject.disciplineSubjectId}
                  className="collapse-discipline-subjects"
                >
                  <S.DisciplinesCollapsePanel
                    key={subject.disciplineSubjectId}
                    header={
                      <Row justify="space-between">
                        <Box mb="16px">
                          <Text
                            fontSize="18px"
                            fontWeight="bold"
                            color="#403edc"
                          >
                            {subject.name}
                          </Text>
                        </Box>

                        <S.DisciplinesTag backgroundColor="#434343">
                          {subject.lessonsDone}/{subject.totalLessons} Aulas
                        </S.DisciplinesTag>
                      </Row>
                    }
                  >
                    {subject.lessons.map((lesson) => (
                      <Box key={lesson.disciplineLessonId} mb="8px">
                        <Row align="middle">
                          {' '}
                          {(!lesson.canAccess )   && (
                            <S.DisciplinesLockOutlinedIcon color="#939393" />
                          )}
                          {lesson.canAccess && (
                            <S.DisciplinesCheckOutlinedIcon
                              color={lesson.done ? '#52c41a' : '#939393'}
                            />
                          )}
                          {!lesson.canAccess && (
                            <Text
                              fontFamily="Open Sans"
                              fontWeight="bold"
                              cursor="pointer"
                              style={{ marginLeft: 8, marginRight: 8 }}
                              disabled
                            >
                              {lesson.name}
                            </Text>
                          )}
                          {lesson.canAccess && (
                            <Text
                              fontFamily="Open Sans"
                              fontWeight="bold"
                              cursor="pointer"
                              style={{ marginLeft: 8, marginRight: 8 }}
                              onClick={() =>
                                redirectLesson(
                                  lesson.studyPlanId,
                                  lesson.disciplineLessonId,
                                )
                              }
                            >
                              {lesson.name}
                            </Text>
                          )}
                          <S.DisciplinesTag
                            backgroundColor={getTagColor(lesson.typeId)}
                          >
                            {lesson.type}
                          </S.DisciplinesTag>
                        </Row>
                      </Box>
                    ))}
                  </S.DisciplinesCollapsePanel>
                </Collapse>
              </Card>
            ))}
          </Space>
        </S.DisciplinesCollapsePanel>
      ))}
    </Collapse>
  )
}

export default DisciplinesListComponent
