import api from '~/services/api'

const services = {
  getQuestions(idStudyPlan, idLesson) {
    return api.get(
      `/student/study-plan/${idStudyPlan}/lesson/${idLesson}/question`,
    )
  },
}

export default services
