import React from 'react'
import { Empty } from 'antd'

import { Text } from '~/shared/Typography'

function StudyTestEmptyComponent() {
  return (
    <Empty
      description={
        <Text fontSize="16px">Você ainda não possui simulados ativos.</Text>
      }
    />
  )
}

export default StudyTestEmptyComponent
