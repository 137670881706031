import React, { useState } from 'react'
import StepWizard from 'react-step-wizard'
import pagarme from 'pagarme'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import schema from './schema'

import Navigation from './components/Navigation'
import AddressForm from './components/AddressForm'
import PaymentForm from './components/PaymentForm'

import Container from '../../shared/Container'

import removeMask from '../../utils/remove-mask'

import useData from '../../hooks/useData'

import api from '../../../../services/api'
import GlobalStyles from '../../../../styles/global'
import notification from '~/shared/Notification'

function Checkout() {
  const formMethods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const {
    selectedSubscriptionPlan,
    handleCheckoutComplete,
    handleCheckoutFinishStep,
  } = useData()

  const { handleSubmit, watch, setValue } = formMethods

  function validateCardInfo(name, expiry, number, cvc) {
    const card = {
      card_holder_name: name,
      card_expiration_date: expiry,
      card_number: number,
      card_cvv: cvc,
    }

    return pagarme.validate({ card })
  }

  function alertErrors(name, expiry, number, cvc) {
    let hasError = false

    if (!name) {
      notification.error('Nome do Cartão inválido.')
      hasError = true
    }

    if (!expiry) {
      notification.error('Validade do Cartão inválida.')
      hasError = true
    }

    if (!number) {
      notification.error('Número do Cartão inválido.')
      hasError = true
    }

    if (!cvc) {
      notification.error('Código de Segurança inválido.')
      hasError = true
    }

    handleCheckoutFinishStep(false)

    return hasError
  }

  async function onSubmit(values) {
    const response = await api.get('/student/perfil')

    const {
      holderCpfTicket,
      cep,
      address,
      addressNumber,
      addressComplement,
      addressDistrict,
      addressCity,
      addressState,

      paymentMethodId,
      holderCpf,
      name,
      expiry,
      number,
      cvc,
      installments,
      discountCouponCode,
    } = values

    const data = {
      address: {
        cep: removeMask(cep),
        address,
        addressNumber,
        addressComplement,
        addressDistrict,
        addressCity,
        addressState,
      },
      payment: {
        subscriptionPlanId: selectedSubscriptionPlan.id,
        paymentMethodId,
        discountCouponCode,
      },
      info: {
        cpf:
          (holderCpf && removeMask(holderCpf)) ||
          (holderCpfTicket && removeMask(holderCpfTicket)),
      },
    }

    let validForm = true

    if(data.address.cep.length != 8) {
      notification.error('CEP Inválido.')
      validForm = false
    }

    if (paymentMethodId === 1) {
      const cardValidations = validateCardInfo(name, expiry, number, cvc)

      const {
        brand,
        card_holder_name,
        card_expiration_date,
        card_number,
        card_cvv,
      } = cardValidations.card

      if (
        alertErrors(
          card_holder_name,
          card_expiration_date,
          card_number,
          card_cvv,
        )
      ) validForm = false

      if(validForm) {

        const response = await api.post('/payment/card-hash', {
          cardNumber: number,
          cardHolderName: name,
          cardExpirationDate: removeMask(expiry),
          cardCvv: cvc,
        })
      

        const paymentCardInfo = {
          creditCardMask: `${brand}-${number.slice(number.length - 4)}`,
          creditCardHash: response.data.cardHash,
          installments,
        }

        data.payment = { ...data.payment, ...paymentCardInfo }
      }
    }


    if(validForm) {

      try {
        const response = await api.post('/student/subscription/renew', data)
        handleCheckoutComplete(response.data.subscriptionId)
        handleCheckoutFinishStep(false)
      } catch (e) {
        handleCheckoutFinishStep(false)
      }
    }
    else {
      handleCheckoutFinishStep(false)
    }
  }

  function onStepChange(stepChange) {
    const cvc = watch('cvc')
    if (stepChange.previousStep === 2 && cvc) setValue('cvc', '')
  }

  return (
    <>
      <GlobalStyles />
      <Container size="small">
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <StepWizard nav={<Navigation />} onStepChange={onStepChange}>
              {/*  <AddressForm /> */}
              <PaymentForm />
            </StepWizard>
          </form>
        </FormProvider>
      </Container>
    </>
  )
}

export default Checkout
