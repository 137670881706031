import api from '~/services/api'

const services = {
  getList() {
    return api.get('test/menu')
  },
  getDetails(testId) {
    return api.get(`test/details/${testId}`)
  },
  startTest(data) {
    return api.post(`test/start`, data)
  },
  startedTest(id) {
    return api.get(`test/started-test/${id}`)
  },
  setAnswer(data) {
    return api.post(`test/test-answer`, data)
  },
  finish(data) {
    return api.post(`test/finish`, data)
  },
  getScore(studentTestId) {
    return api.get(`test/test-score/${studentTestId}`)
  },
  feedback(studentTestId) {
    return api.get(`test/feedback/${studentTestId}`)
  },
}

export default services
