import styled from 'styled-components'
import {
  UpCircleFilled,
  DownCircleFilled,
  LockOutlined,
} from '@ant-design/icons'
import { Collapse, Layout } from 'antd'
import Vimeo from '@u-wave/react-vimeo'

const StyledLayout = styled(Layout)`
  background-color: #fff;

  .sidebar-button,
  .sidebar-hidden {
    display: none;
  }

  @media (max-width: 768px) {
    .sidebar-hidden {
      display: block;
    }

    .ant-btn.ant-btn-primary.ant-btn-circle.ant-btn-sm.ant-btn-icon-only {
      top: 103px !important;
      left: 15px !important;
      z-index: 999;
    }

    .button-menu-classes {
      display: none;
    }
  }
`

const LessonsLockOutlinedIcon = styled(LockOutlined)`
  font-size: 20px;
  color: ${(props) => props.color};
`

const LessonsCollapsePanel = styled(Collapse.Panel)`
  && {
    .ant-collapse-header {
      display: flex;
      align-items: center;

      padding-left: 16px;
    }
  }
`

const LessonsUpOutlinedIcon = styled(UpCircleFilled)`
  margin-right: 8px;

  font-size: 18px;
  color: #403edc;
`

const LessonsDownOutlinedIcon = styled(DownCircleFilled)`
  margin-right: 8px;

  font-size: 18px;
  color: #403edc;
`

const LessonsPlayer = styled(Vimeo)`
  position: relative;
  overflow: hidden;

  height: 0;

  padding-top: 0;
  padding-bottom: 56.25%;

  margin-bottom: 5px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
`

const WrapperMain = styled.div`
  position: relative;
  width: 100%;
  display: block;
  font-family: Futura PT;

  .button-menu-classes {
    position: absolute;
    top: 25px;
    right: 0;
    margin-left: 0;
    background-color: #403edc;
    border-color: #403edc;
  }

  header {
    margin-bottom: 48px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    .button-menu-classes {
      position: relative;
      top: 0;
      margin-bottom: 20px;
    }

    header {
      padding: 28px;
      margin-bottom: 30px;
      height: auto;
      line-height: unset;
      display: block;

      span.ant-input-search,
      span.ant-typography {
        margin: 20px 0 20px 0;
      }
    }
  }
`

const ButtonWrapper = styled.div`
  margin-top: 24px;

  margin-bottom: 30px;

  @media (max-width: 768px) {
    button:not(:first-child) {
      margin-left: -25px;
    }
  }
`

export {
  LessonsCollapsePanel,
  LessonsUpOutlinedIcon,
  LessonsDownOutlinedIcon,
  LessonsPlayer,
  LessonsLockOutlinedIcon,
  WrapperMain,
  ButtonWrapper,
  StyledLayout,
}
