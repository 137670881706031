import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { Tag } from 'antd'

import {
  QuestionCircleOutlined,
  PlusCircleFilled,
  EyeOutlined,
} from '@ant-design/icons'

const StudentFormTag = styled(Tag)`
  border-radius: 20px;
  width: ${(props) => props.width};
  margin-bottom: ${(props) => props.marginBottom};
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => props.padding};
  color: #fff;
  text-align: center;
  cursor: ${(props) => props.cursor};
`

const StudentFormQuestionOutlined = styled(QuestionCircleOutlined)`
  font-size: 20px;
  color: ${(props) => props.color};
`

const StudentFormPlusCircleFilled = styled(PlusCircleFilled)`
  font-size: 20px;
  color: ${(props) => props.color};
`

const StudentFormEyeOutlined = styled(EyeOutlined)`
  font-size: 20px;
  color: ${(props) => props.color};
`
const StyledContentWrapper = styled.div`
  padding: 24px;
  background-color: #fff;

  .message-wrapper .react-mathjax-preview-result img {
    max-width: 100%;
  }

  label {
    font-weight: bold;
  }

  &.view-doubts input {
    padding: 0;
    color: unset !important;
    border: none;
    background-color: transparent !important;
    cursor: text !important;
  }

  .tox .tox-statusbar {
    display: none !important;
  }

  ${media.lessThan('medium')`
    padding: 15px 0 0 0 !important;

    &.view-doubts,
    &.view-doubts .ant-row.ant-form-item .ant-col.ant-form-item-label {
      padding: 0;
    }

    &.view-doubts .ant-row.ant-form-item {
      margin-bottom: 15px;
    }

    &.view-doubts .ant-divider-horizontal {
      margin-top: 0;
    }

    &.take-question {
      padding-bottom: 70px !important;
    }

    &.take-question .ant-input {
      height: 40px;
    }

    .ant-select.ant-select-lg {
      ${({ theme }) => css`
        border: 1px solid ${theme.colors?.inputBorder};
        border-radius: 2px;
      `}
    }

    .col-answer {
      margin-top: 25px;
    }
  `}
`

const DisciplineWrapper = styled.div`
  ${({ theme }) => css`
    section.ant-layout {
      min-height: auto;
      background-color: ${theme.colors.white} !important;
    }

    .student-form-tag {
      display: flex;
      justify-content: right;
    }

    ${media.lessThan('medium')`
      .student-form-tag {
        position: absolute;
        top: 110px;
        right: 0;
        z-index: 99999;
        display: unset;
        justify-content: unset;
      }
    `}
  `}
`

export {
  StudentFormTag,
  StudentFormQuestionOutlined,
  StudentFormPlusCircleFilled,
  StudentFormEyeOutlined,
  StyledContentWrapper,
  DisciplineWrapper,
}
