import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '~/shared/Button'

function StudyPlansModeComponent(props) {
  const { onClick } = props
  const [id, setId] = useState(2)
  const modes = [
    {
      id: 2,
      name: 'Todas as Dúvidas',
      index: 2,
      onClick: (id) => {
        setIdMode(id)
        onClick(false)
      },
    },
    {
      id: 1,
      name: 'Minhas Dúvidas',
      index: 1,
      onClick: (id) => {
        setIdMode(id)
        onClick(true)
      },
    },
  ]

  const setIdMode = (id) => {
    setId(id)
  }

  return modes.map((mode) => {
    const active = id === mode.id
    return (
      <Button
        key={mode.id}
        height="44px"
        borderColor={active ? 'transparent' : '#eaeaea'}
        backgroundColor={active ? '#fff' : '#f0f2f5'}
        fontSize="18px"
        fontWeight="bold"
        marginBottom="10px"
        index={mode.index}
        onClick={() => mode.onClick(mode.id)}
      >
        {mode.name}
      </Button>
    )
  })
}

export default StudyPlansModeComponent
