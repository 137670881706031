/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Row, Col } from 'react-flexbox-grid'
import Payment from 'payment'

import CreditCard from '../CreditCard'
import StepsButton from '../StepsButton'

import Heading from '../../../../shared/Heading'
import Input from '../../../../shared/Input'
import InputMask from '../../../../shared/InputMask'
import Select from '../../../../shared/Select'

import useData from '../../../../hooks/useData'
import useAuth from '~/hooks/useAuth'

import api from '../../../../../../services/api'

import Coupon from './Coupon'
import SubscriptionTastingMessage from '../SubscriptionTastingMessage'

import brazilStates from '../../../../utils/brazil-states'

function PaymentForm(props) {
  const [focused, setFocused] = useState('')
  const [studentCpfTicket, setStudentCpfTicket] = useState('')
  const [data, setData] = useState({})

  const { address, cep, city, complement, district, number, state } = data

  useEffect(() => {
    async function getData() {

      const response = await api.get('student/perfil')
      setStudentCpfTicket(response?.data?.info?.cpf)
      const newData = { ...response.data.paymentInfo, ...response.data.info};
      newData.cep = parseInt(newData.cep)
      setData(newData)      
    }
    getData()
  }, [])

  const onChange = (obj) => {
    setData({ ...data, ...obj })
  }

  const { trigger } = useFormContext()

  async function validateInputs() {
    return trigger([
      'address',
      'cep',
      'address',
      'addressNumber',
      'addressComplement',
      'addressDistrict',
      'addressCity',
      'addressState',
    ])
  }

  const {
    selectedSubscriptionPlan,
    getInstallmentsAmount,
    installmentsAmount,
    setStudentSubscriptionCouponCheck,
    getTastingValidate,
    tastingPlan,
  } = useData()

  const auth = useAuth()
  const { studentId } = auth.user

  const { watch } = useFormContext()

  useEffect(() => {
    if (selectedSubscriptionPlan) {
      getInstallmentsAmount(selectedSubscriptionPlan.id, '')
      getTastingValidate(selectedSubscriptionPlan.id, '', studentId)
    }
  }, [selectedSubscriptionPlan, studentId])

  // eslint-disable-next-line radix
  const paymentMethod = parseInt(
    watch(
      'paymentMethodId',
      selectedSubscriptionPlan.allowCreditCardPayment ? 1 : 2,
    ),
  )

  useEffect(() => {
    if (paymentMethod === 2) {
      setStudentSubscriptionCouponCheck(true)
    }

    if (paymentMethod === 1)
      getInstallmentsAmount(selectedSubscriptionPlan.id, '')

    if ([undefined, null, ''].includes(tastingPlan.subscriptionPlanTrial))
      getTastingValidate(selectedSubscriptionPlan.id, '', studentId)
  }, [paymentMethod])

  const { currentStep, previousStep, nextStep } = props

  const card = watch(['number', 'name', 'expiry', 'cvc'])

  function handleInputFocus(event) {
    setFocused(event.target.name)
  }

  function clearNumber(value = '') {
    return value.replace(/\D+/g, '')
  }

  function handleInputChange(event) {
    const { value } = event.target

    if (!value) return value

    const issuer = Payment.fns.cardType(value)
    const clearValue = clearNumber(value)

    let nextValue

    switch (issuer) {
      case 'amex':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10,
        )} ${clearValue.slice(10, 15)}`
        break

      case 'dinersclub':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10,
        )} ${clearValue.slice(10, 14)}`
        break

      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8,
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`
        break
    }

    return nextValue.trim()
  }

  return (
    <>
      <Heading lineBottom>Pagamento</Heading>

      <Row start="md" center="xs">
        {selectedSubscriptionPlan.allowCreditCardPayment && (
          <Col
            xs={6}
            md={3}
            onClick={() => setStudentSubscriptionCouponCheck(true)}
          >
            <Input
              name="paymentMethodId"
              type="radio"
              defaultChecked={paymentMethod === 1}
              value={1}
            >
              Cartão de Crédito
            </Input>
          </Col>
        )}

        {selectedSubscriptionPlan.allowBanckSlipPayment && (
          <Col xs={6} md={3}>
            <Input
              name="paymentMethodId"
              type="radio"
              defaultChecked={paymentMethod === 2}
              value={2}
            >
              Boleto
            </Input>
          </Col>
        )}
      </Row>

      {paymentMethod === 1 && (
        <>
          <CreditCard
            number={card.number}
            name={card.name}
            expiry={card.expiry}
            cvc={card.cvc}
            focused={focused}
          />

          <Row>
            <Col md={6}>
              <Input
                name="name"
                placeholder="Titular do Cartão"
                onFocus={handleInputFocus}
              />
            </Col>

            {studentCpfTicket &&
              <Col md={6}>
                <InputMask
                  inputMode="numeric"
                  name="holderCpf"
                  placeholder="CPF"
                  mask="999.999.999-99"
                  defaultValue={studentCpfTicket}
                />
              </Col>
            }
          </Row>

          <Row>
            <Col md={6}>
              <Input
                inputMode="numeric"
                name="number"
                placeholder="Número do Cartão"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </Col>

            <Col md={6}>
              <InputMask
                inputMode="numeric"
                name="expiry"
                placeholder="Validade do Cartão"
                mask="99/99"
                onFocus={handleInputFocus}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <InputMask
                inputMode="numeric"
                name="cvc"
                placeholder="Código do Cartão"
                mask="9999"
                onFocus={handleInputFocus}
              />
            </Col>

            <Col md={6}>
              <Select
                name="installments"
                placeholder="Parcelas"
                items={installmentsAmount}
              />
            </Col>

            <Coupon subscriptionPlanId={selectedSubscriptionPlan.id} />

            {!!tastingPlan?.subscriptionPlanTrial && (
              <SubscriptionTastingMessage
                initialDate={tastingPlan.trialPeriodStart}
                endDate={tastingPlan.trialPeriodEnd}
                billingDate={tastingPlan.trialPeriodSubscriptionChargeDate}
                paymentMethod={paymentMethod}
                numberDays={tastingPlan.numberDays}
                canUseSubscriptionPlanTrial={
                  tastingPlan.canUseSubscriptionPlanTrial
                }
              />
            )}
          </Row>

          <Row>
            <Col xs={6} md={6}>
              <Input
                name="address"
                placeholder="Endereço"
                value={address}
                onChange={(e) => onChange({ address: e.target.value })}
              />
            </Col>

            <Col xs={12} md={6}>
              <Input
                inputMode="numeric"
                name="addressNumber"
                placeholder="Número"
                value={number}
                onChange={(e) => onChange({ number: e.target.value })}
              />
            </Col>

            <Col xs={12} md={6}>
              <Input
                name="addressComplement"
                placeholder="Complemento"
                value={complement}
                onChange={(e) => onChange({ complement: e.target.value })}
              />
            </Col>

            <Col xs={12} md={6}>
              <Input
                name="addressDistrict"
                placeholder="Bairro"
                value={district}
                onChange={(e) => onChange({ district: e.target.value })}
              />
            </Col>

            <Col xs={16} md={3}>
              <Input
                name="addressCity"
                placeholder="Cidade"
                value={city}
                onChange={(e) => onChange({ city: e.target.value })}
              />
            </Col>

            <Col xs={12} md={3}>
              <Select
                name="addressState"
                items={brazilStates}
                value={state}
                onChange={(e) => onChange({ state: e.target.value })}
              />
            </Col>

            { cep &&
              <Col md={3}>
                <InputMask
                  inputMode="numeric"
                  name="cep"
                  placeholder="CEP"
                  mask="99999-999"
                  defaultValue={cep}
                  onChange={(e) => setData(e.target.value)}
                />
              </Col>
            }
          </Row>
        </>
      )}

      {paymentMethod === 2 && (
        <>
          <Row>
            <Col md={6}>
              {studentCpfTicket && studentCpfTicket.length > '0' &&(
                <InputMask
                  inputMode="numeric"
                  name="holderCpfTicket"
                  placeholder="CPF"
                  mask="999.999.999-99"
                  defaultValue={studentCpfTicket}
                />
              )}

              {!studentCpfTicket &&(
                <InputMask
                  inputMode="numeric"
                  name="holderCpfTicket"
                  placeholder="CPF"
                  mask="999.999.999-99"
                  defaultValue={studentCpfTicket}
                />
              )}
            </Col>

            <Col xs={12} md={6}>
              <Input
                name="address"
                placeholder="Endereço"
                value={address}
                onChange={(e) => onChange({ address: e.target.value })}
              />
            </Col>

            <Col xs={12} md={6}>
              <Input
                inputMode="numeric"
                name="addressNumber"
                placeholder="Número"
                value={number}
                onChange={(e) => onChange({ number: e.target.value })}
              />
            </Col>

            <Col xs={12} md={6}>
              <Input
                name="addressComplement"
                placeholder="Complemento"
                value={complement}
                onChange={(e) => onChange({ complement: e.target.value })}
              />
            </Col>

            <Col xs={12} md={6}>
              <Input
                name="addressDistrict"
                placeholder="Bairro"
                value={district}
                onChange={(e) => onChange({ district: e.target.value })}
              />
            </Col>

            <Row style={{ margin: 0 }}>
              <Col xs={12} md={6}>
                <Input
                  name="addressCity"
                  placeholder="Cidade"
                  value={city}
                  onChange={(e) => onChange({ city: e.target.value })}
                />
              </Col>

              <Col xs={12} md={6}>
                <Select
                  name="addressState"
                  items={brazilStates}
                  value={state}
                  onChange={(e) => onChange({ state: e.target.value })}
                />
              </Col>
            </Row>

            { cep &&
              <Col md={6}>
                <InputMask
                  inputMode="numeric"
                  name="cep"
                  placeholder="CEP"
                  mask="99999-999"
                  defaultValue={cep}
                  onChange={(e) => setData(e.target.value)}
                />
              </Col>
            }

            <Coupon subscriptionPlanId={selectedSubscriptionPlan.id} />

            {!!selectedSubscriptionPlan.bankSlipPaymentInstructions && (
              <div style={{ margin: '1em' }}>
                {selectedSubscriptionPlan.bankSlipPaymentInstructions}
              </div>
            )}
            {!!tastingPlan?.subscriptionPlanTrial && (
              <SubscriptionTastingMessage
                paymentMethod={paymentMethod}
                canUseSubscriptionPlanTrial={
                  tastingPlan.canUseSubscriptionPlanTrial
                }
              />
            )}
          </Row>
        </>
      )}

      <Row end="xs" style={{ margin: 0 }}>
        <Col xs={12}>
          <Input type="checkbox" name="termsConditions">
            <span style={{ fontSize: 14 }}>
              Li e concordo com os{' '}
              <a
                href="https://corujasabia.com.br/termos-de-uso"
                target="_blank"
                rel="noreferrer"
              >
                termos e condições
              </a>
              .
            </span>
          </Input>
        </Col>
      </Row>

      <StepsButton
        // currentStep={currentStep}
        // validateInputs={validateInputs}
        currentStep={2}
        // previousStep={previousStep}
        // nextStep={nextStep}
        nextStep={2}
        style={{ margin: 0 }}
      />
    </>
  )
}

export default PaymentForm
