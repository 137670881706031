import UserPasswordChange from './containers/userPasswordChange-list.container'

const routes = [
  {
    path: '/alterar-senha',
    title: 'Alterar senha',
    authenticated: true,
    component: UserPasswordChange,
  },
]

export default routes
