import styled from 'styled-components'
import { Layout as AntdLayout } from 'antd'

export const Header = styled(AntdLayout.Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 48px;
`
