import React, { useState } from 'react'
import pagarme from 'pagarme'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import schema from './schema'

import PaymentForm from './components/PaymentForm'
import Container from '~/pages/subscription/shared/Container'

import removeMask from '~/pages/subscription/utils/remove-mask'
import useData from '~/pages/subscription/hooks/useData'

import api from '~/services/api'
import GlobalStyles from '~/styles/global'
import notification from '~/shared/Notification'

function Checkout({ setCurrentStep, setResSubscriptionId }) {
  const [paymentMethodId] = useState(1)

  const formMethods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      holderCpf: '',
      expiry: '',
      cvc: '',
      cep: '',
    },
  })

  const { handleCheckoutFinishStep } = useData()

  const { handleSubmit, watch, setValue } = formMethods

  function validateCardInfo(name, expiry, number, cvc) {
    const card = {
      card_holder_name: name,
      card_expiration_date: expiry,
      card_number: number,
      card_cvv: cvc,
    }

    return pagarme.validate({ card })
  }

  function alertErrors(name, expiry, number, cvc) {
    let hasError = false

    if (!name) {
      notification.error('Nome do Cartão inválido.')
      hasError = true
    }

    if (!expiry) {
      notification.error('Validade do Cartão inválida.')
      hasError = true
    }

    if (!number) {
      notification.error('Número do Cartão inválido.')
      hasError = true
    }

    if (!cvc) {
      notification.error('Código de Segurança inválido.')
      hasError = true
    }

    handleCheckoutFinishStep(false)

    return hasError
  }

  async function onSubmit(values) {
    handleCheckoutFinishStep(true)

    const {
      cep,
      address,
      addressNumber,
      addressComplement,
      addressDistrict,
      addressCity,
      addressState,

      holderCpf,
      name,
      expiry,
      number,
      cvc,
      installments,
      discountCouponCode,
    } = values

    const data = {
      aulaUnitaria: true,
      privateClass: true,
      mentorShip: false,
      creditPrivateClass: 1,
      creditMentorShip: 0,
      packageAmount: 0,
      address: {
        cep: removeMask(cep),
        address,
        addressNumber,
        addressComplement,
        addressDistrict,
        addressCity,
        addressState,
      },
      payment: {
        subscriptionPlanId: 38,
        paymentMethodId,
        discountCouponCode,
      },
      info: {
        cpf: holderCpf && removeMask(holderCpf),
      },
    }

    if (paymentMethodId === 1) {
      const cardValidations = validateCardInfo(name, expiry, number, cvc)

      const {
        brand,
        card_holder_name,
        card_expiration_date,
        card_number,
        card_cvv,
      } = cardValidations.card

      if (
        alertErrors(
          card_holder_name,
          card_expiration_date,
          card_number,
          card_cvv,
        )
      )
        return

      const response = await api.post('/payment/card-hash', {
        cardNumber: number,
        cardHolderName: name,
        cardExpirationDate: removeMask(expiry),
        cardCvv: cvc,
      })

      const paymentCardInfo = {
        creditCardMask: `${brand}-${number.slice(number.length - 4)}`,
        creditCardHash: response.data.cardHash,
        installments,
      }

      data.payment = { ...data.payment, ...paymentCardInfo }
    }

    try {
      const response = await api.post('/student/subscription/renew', data)
      setResSubscriptionId(response.data.subscriptionId)
      setCurrentStep(2)
      handleCheckoutFinishStep(false)
    } catch (e) {
      handleCheckoutFinishStep(false)
    }
  }

  return (
    <>
      <GlobalStyles />
      <Container size="small">
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <PaymentForm
              paymentMethodId={paymentMethodId}
              setCurrentStep={setCurrentStep}
            />
          </form>
        </FormProvider>
      </Container>
    </>
  )
}

export default Checkout
