import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Row, Col } from 'react-flexbox-grid'

import StepsButton from '../StepsButton'

import Heading from '../../../../shared/Heading'
import Input from '../../../../shared/Input'
import Select from '../../../../shared/Select'
import InputMask from '../../../../shared/InputMask'

import brazilStates from '../../../../utils/brazil-states'

import api from '~/services/api'

function AddressForm(props) {
  const { trigger } = useFormContext()

  const [data, setData] = useState({})
  const { address, cep, city, complement, district, number, state } = data

  const { currentStep, previousStep, nextStep } = props

  // async function validateInputs() {
  //   return trigger([
  //     'cep',
  //     'address',
  //     'addressNumber',
  //     'addressComplement',
  //     'addressDistrict',
  //     'addressCity',
  //     'addressState',
  //   ])
  // }

  async function validateInputs() {
    return trigger([
      'address',
    ])
  }

  // STEP 1 QUE CONTEM O ENDERECO.

  useEffect(() => {
    async function getData() {
      const response = await api.get('student/perfil')
      const { data } = response
      const { paymentInfo } = data
      setData(paymentInfo)
    }
    getData()
  }, [])

  const onChange = (obj) => {
    setData({ ...data, ...obj })
  }

  return (
    <>
      <Heading lineBottom>Endereço de Cobrança</Heading>

      <Row>
        <Col xs={12} md={6}>
          {cep && (
            <InputMask
              inputMode="numeric"
              name="cep"
              placeholder="CEP"
              mask="99999-999"
              defaultValue={cep}
            />
          )}
          {!cep && (
            <InputMask
              inputMode="numeric"
              name="cep"
              placeholder="CEP"
              mask="99999-999"
              defaultValue=""
            />
          )}
        </Col>

        <Col xs={12} md={6}>
          <Input
            name="address"
            placeholder="Endereço"
            value={address}
            onChange={(e) => onChange({ address: e.target.value })}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Input
            inputMode="numeric"
            name="addressNumber"
            placeholder="Número"
            value={number}
            onChange={(e) => onChange({ number: e.target.value })}
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            name="addressComplement"
            placeholder="Complemento"
            value={complement}
            onChange={(e) => onChange({ complement: e.target.value })}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Input
            name="addressDistrict"
            placeholder="Bairro"
            value={district}
            onChange={(e) => onChange({ district: e.target.value })}
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            name="addressCity"
            placeholder="Cidade"
            value={city}
            onChange={(e) => onChange({ city: e.target.value })}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
        <Select
            name="addressState"
            items={brazilStates}
            value={state}
            onChange={(e) => onChange({ state: e.target.value })}
          />
        </Col>
      </Row>

      <StepsButton
       // validateInputs={validateInputs}
        currentStep={currentStep}
        previousStep={previousStep}
        nextStep={nextStep}
      />
    </>
  )
}

export default AddressForm
