import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Typography,
  Row,
  Col,
  Form,
  Tooltip,
  Grid,
  Skeleton,
  Empty,
  Table,
  Modal,
} from 'antd'

import api from '../student-form.services'

import Select from '~/shared/Select'
import { Text as TextEmpty } from '~/shared/Typography'

import * as S from '../student-form.styles'
import * as T from '~/shared/styles'
import useAuth from '~/hooks/useAuth'

import StudentFormModeComponent from './student-form-mode.component'
import StudentFormFilterComponent from './student-form-filter.component'
import StudentFormTakeQuestionsViewModal from './student-form-view-modal.component'

function StudentFormListComponent() {
  const { useBreakpoint } = Grid

  const screen = useBreakpoint()
  const { lg } = screen
  const auth = useAuth()
  const { Title, Text } = Typography
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [loadingDoubts, setLoadingDoubts] = useState(true)
  const [filter, setFilters] = useState({ filter: null, disciplineId: null })
  const [data, setData] = useState([])
  const [discipline, setDiscipline] = useState([])
  const [idDoubts, setIdDoubts] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)

  const getList = async (filter) => {
    setLoading(true)

    try {
      const response = await api.getList(filter)
      const { data } = response

      setData(data)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const getDiscipline = async () => {
    try {
      const response = await api.getDiscipline()
      const { data } = response

      setDiscipline(data)
      setLoadingDoubts(false)
    } catch {
      setLoadingDoubts(false)
    }
  }

  useEffect(() => {
    async function getData() {
      await getList()
    }

    getData()
  }, [])

  useEffect(() => {
    async function getDataDiscipline() {
      await getDiscipline()
    }

    if (data) getDataDiscipline()
  }, [data])

  useEffect(() => {
    async function getListByFilter() {
      await getList(filter)
    }

    getListByFilter()
  }, [filter])

  const Item = () => {
    function renderText(item) {
      return <Text className="text-capitalize">{item.toLowerCase()}</Text>
    }

    function renderTag(item) {
      return item.tags.map((tag) => {
        return (
          <S.StudentFormTag key={item.id} backgroundColor="#939393">
            <Text style={{ color: '#fff' }}>{tag}</Text>
          </S.StudentFormTag>
        )
      })
    }

    function renderAaction(item) {    
      return (
        <Tooltip title="Visualizar Dúvida">
          <S.StudentFormTag
            backgroundColor="#939393"
            cursor="pointer"
            onClick={() => {
              history.push(`/duvidas/visualizacao/${item.id}`)
            }}
          >
            <S.StudentFormEyeOutlined />
          </S.StudentFormTag>
        </Tooltip>
      )
    }

    function renderMobile(item) {
      const { subject, disciplineName, createdBy, tags } = item

      return (
        <>
          {!!subject && (
            <T.TableText className="text-capitalize">
              Título: <T.TableSpan>{subject.toLowerCase()}</T.TableSpan>
            </T.TableText>
          )}

          {!!disciplineName && (
            <T.TableText>
              Disciplina: <T.TableSpan>{disciplineName}</T.TableSpan>
            </T.TableText>
          )}

          {!!createdBy && (
            <T.TableText className="text-capitalize">
              Criado: <T.TableSpan>{createdBy.toLowerCase()}</T.TableSpan>
            </T.TableText>
          )}

          {!!tags.length > 0 && (
            <T.TableText>
              Termos: <T.TableSpan>{renderTag(item)}</T.TableSpan>
            </T.TableText>
          )}

          <T.TableText>
            Visualizar: <T.TableSpan>{renderAaction(item)}</T.TableSpan>
          </T.TableText>
        </>
      )
    }

    const columns = [
      {
        title: 'Título',
        dataIndex: 'subject',
        responsive: ['md'],
        render: (subject) => renderText(subject),
      },
      {
        title: 'Disciplina',
        dataIndex: 'disciplineName',
        responsive: ['md'],
      },
      {
        title: 'Criado',
        dataIndex: 'createdBy',
        responsive: ['md'],
        render: (createdBy) => renderText(createdBy),
      },
      {
        title: 'Termos',
        responsive: ['md'],
        render: (tags) => renderTag(tags),
      },
      {
        title: 'Visualizar',
        align: 'center',
        responsive: ['md'],
        render: (item) => renderAaction(item),
      },
      {
        title: 'Dúvidas',
        responsive: ['xs'],
        render: (item) => renderMobile(item),
      },
    ]

    const handleModalClose = () => {
      setIsModalVisible(false)
    }

    const modalStudentFormTakeQuestions = (id) => {
      setIdDoubts(id)

      setIsModalVisible(true)
    }

    return (
      discipline.length > 0 && (
        <>
          <Table
            columns={columns}
            dataSource={data}
            onRow={(item) => ({
              onClick: () => {
                modalStudentFormTakeQuestions(item.id)
              },
            })}
            className="table-content cursor-pointer"
          />

          {!!idDoubts && !!isModalVisible && (
            <Modal
              visible={isModalVisible}
              onOk={handleModalClose}
              okText="Fechar"
              onCancel={handleModalClose}
              width={1000}
              cancelButtonProps={{ style: { display: 'none' } }}
            >
              <StudentFormTakeQuestionsViewModal id={idDoubts} />
            </Modal>
          )}
        </>
      )
    )
  }

  return discipline.length > 0 ? (
    <S.DisciplineWrapper>
      <T.TableWrapper>
        <Row>
          <Col xs={24} md={12}>
            <StudentFormModeComponent
              onClick={(value) => {
                setFilters((data) => ({ ...data, onlyMyPosts: value }))
              }}
            />
          </Col>

          {!auth.user.studentFreemium && (
            <Col xs={24} md={12} className="student-form-tag">
              <S.StudentFormTag
                backgroundColor="#403edc"
                cursor="pointer"
                padding="10px"
                width={lg ? '170px' : '40px'}
                marginBottom="15px"
                onClick={() => {
                  history.push('/duvidas/tirar')
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <S.StudentFormPlusCircleFilled />

                {lg && (
                  <Text style={{ color: '#fff', fontSize: '15px' }}>
                    Tirar uma dúvida
                  </Text>
                )}
              </S.StudentFormTag>
            </Col>
          )}
        </Row>

        <Row gutter={[24, { md: 24 }]} style={{ marginTop: '15px' }}>
          <Col xs={24} md={12}>
            <Form.Item
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Select
                size="large"
                defaultValue={null}
                bordered={false}
                onChange={(disciplineId) =>
                  setFilters((data) => ({ ...data, disciplineId }))
                }
                listItemHeight={10}
                listHeight={250}
                className="box-select"
              >
                <Select.Option value={null}>Todas as disciplinas</Select.Option>

                {discipline.map((item) => {
                  return (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <StudentFormFilterComponent
              onSubmit={(value) => {
                setFilters((data) => ({ ...data, filter: value }))
              }}
            />
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: '15px' }} className="table-title">
          <Col xs={24} md={24}>
            <Title level={4} style={{ color: '#403edc' }}>
              Dúvidas
            </Title>
          </Col>
        </Row>

        {loadingDoubts && <Skeleton active />}

        {!loadingDoubts && <Item />}
      </T.TableWrapper>
    </S.DisciplineWrapper>
  ) : (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <Empty
          description={
            <TextEmpty fontSize="16px">
              A sua assinatura não possui acesso ao fórum de dúvidas.
            </TextEmpty>
          }
        />
      )}
    </>
  )
}

export default StudentFormListComponent
