import React from 'react'
import * as S from '../disciplines.styles'

function DisciplinesExpandComponent(props) {
  const { isActive } = props

  return isActive ? (
    <S.DisciplinesUpOutlinedIcon />
  ) : (
    <S.DisciplinesDownOutlinedIcon />
  )
}

export default DisciplinesExpandComponent
