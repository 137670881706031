import React from 'react'

import DisciplinesEmptyComponent from './disciplines-empty.component'
import DisciplinesListComponent from './disciplines-list.component'

import Loader from '~/shared/Loader'

function DisciplinesHandlerComponent(props) {
  const { loadingDisciplines, disciplines } = props

  if (!loadingDisciplines && !disciplines.length)
    return <DisciplinesEmptyComponent />

  return (
    <Loader loading={loadingDisciplines}>
      <DisciplinesListComponent disciplines={disciplines} />
    </Loader>
  )
}

export default DisciplinesHandlerComponent
