import api from '~/services/api'

const services = {
  getList() {
    return api.get('student-essay')
  },
  upload(data) {
    var formData = new FormData()

    formData.append('StudyPlanId', data.StudyPlanId)
    formData.append('DisciplineId', data.DisciplineId)
    if (data.file) formData.append('Essay', data.file)
    formData.append('DisciplineSubjectId', data.DisciplineSubjectId)
    formData.append('DisciplineLessonId', data.DisciplineLessonId)

    return api.post('/student-essay/student-upload', formData, {
      headers: {
        'content-type': `multipart/form-data; ${
          data.file ? `boundary=${formData._boundary}` : ''
        } `,
      },
    })
  },
}

export default services
