import React, { useEffect, useState } from 'react'
import * as S from '../lessons.styles'
import Loader from '~/shared/Loader'

function LessonsPlayerComponent(props) {
  const [video, setVideo] = useState('')
  const [loading, setLoading] = useState(true)
  const { lesson, onPlay, onPause, onEnd } = props
  
  useEffect(() => {
    setVideo(lesson)
  }, [lesson])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [video])

  return (
    <Loader loading={loading}>
      {!!video && (
          <S.LessonsPlayer
            start={video.videoStartsAt}
            video={video.videoUrl}
            onPlay={onPlay}
            onPause={onEnd !== onPause}
            onEnd={onEnd}
          />
      )}
    </Loader>
  )
}

export default LessonsPlayerComponent
