import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Row, Col, Skeleton } from 'antd'
import Dialog from '@material-ui/core/Dialog'
import useAuth from '~/hooks/useAuth'
import Space from '~/shared/Space'
import { Text } from '~/shared/Typography'
import Button from '~/shared/Button'
import LessonsNavigationComponent from './lessons-navigation.component'
import LessonsPlayerComponent from './lessons-player.component'
import LessonsRatingComponent from './lessons-rating.component'
import ModalPlansSelection from '../../modalPlans/components/modalPlans.component'
import services from '../lessons.services'

function LessonsHandlerComponent(props) {
  const {
    loadingLesson,
    lesson,
    onPlay,
    onPause,
    onEnd,
    previousLesson,
    nextLesson,
    idLesson,
    idStudyPlan,
  } = props

  const auth = useAuth()
  const history = useHistory()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  if (loadingLesson) return <Skeleton active />
  if (!lesson) return null

  return (
    <>
      <Space size="small" direction="vertical" marginTop="1.3rem">
        <Text
          fontFamily="Open Sans"
          minFontSize="2.5vw"
          fontSize="12px"
          color="#434343"
        >
          {lesson.studyPlanName} &gt; {lesson.disciplineName} &gt;{' '}
          {lesson.disciplineSubjectName} &gt; {lesson.title}
        </Text>

        <Text fontSize="32px" fontWeight="bold" color="#434343">
          {lesson.title}
        </Text>

        <Row gutter={[24, 24]} justify="space-between">
          <Col>
            <Button height="44px" fontSize="18px" fontWeight="bold" index={2}>
              Aula
            </Button>
          </Col>

          <Col>
            <Link to={{ pathname: lesson.pdfUrl }} target="_blank">
              <Button
                height="44px"
                borderColor="#403edc"
                backgroundColor="#403edc"
                fontSize="18px"
                fontWeight="bold"
                color="#fff"
                index={2}
                onClick={()=> services.logMaterialAccess(props.lesson, auth.user)}
              >
                Material
              </Button>
            </Link>
          </Col>

          {lesson.hasQuestions && (
            <Col>
              <Button
                height="44px"
                borderColor="#403edc"
                backgroundColor="#403edc"
                fontSize="18px"
                fontWeight="bold"
                color="#fff"
                index={2}
                onClick={() => {
                  services.logExerciseAccess(props.lesson, auth.user)
                  if (!auth.user.studentFreemium) {
                    history.push(`/${idStudyPlan}/aula/${idLesson}/exercicio`)
                  } else {
                    showModal()
                  }
                }}
              >
                Exercícios
              </Button>
            </Col>
          )}
        </Row>

        {!!lesson && (
          <LessonsPlayerComponent
            lesson={lesson}
            onPlay={onPlay}
            onPause={onPause}
            onEnd={onEnd}
            />            
        )}

        <LessonsRatingComponent
          lessonId={lesson.disciplineLessonId}
          services={services}
          isLive={false}
        />

        <Row justify="end">
          <LessonsNavigationComponent
            previousLesson={previousLesson}
            nextLesson={nextLesson}
            enablePreviousButton={lesson.previousLessonId}
            enableNextLessonButton={lesson.nextLessonId}
          />
        </Row>
      </Space>
      <Dialog
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
        maxWidth="sm"
        onClose={handleCancel}
        open={isModalVisible}
      >
        <ModalPlansSelection />
      </Dialog>
    </>
  )
}

export default LessonsHandlerComponent
