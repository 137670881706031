import React from 'react'
import { Progress } from 'antd'

function DisciplinesProgressComponent(props) {
  const { percent } = props

  return (
    <Progress
      status="success"
      size="small"
      percent={percent * 10}
      trailColor="#e2e2e2"
      showInfo={false}
    />
  )
}

export default DisciplinesProgressComponent
