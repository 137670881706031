import axios from 'axios'
import notification from '~/shared/Notification'

const baseURL = 'https://api.corujasabia.com.br/api/v1'

const urlParams = new URLSearchParams(window.location.search)

const api = axios.create({
  baseURL,
})

api.defaults.headers.common['X-Application-Key'] = 'webkey123'

function signOut() {
  localStorage.clear()

  window.location.href = '/'
  notification.warning('Sesssão expirada.')
}

export async function refreshToken() {
  const oldRefreshToken =
    JSON.parse(localStorage.getItem('refresh')) || urlParams.get('refresh')

  const response = await api.post('/student/token/refresh', {
    refresh: oldRefreshToken,
  })

  const {
    name,
    studentId,
    token,
    studentFreemium,
    refresh,
    qtdPrivateClass,
  } = response.data

  localStorage.setItem('token', JSON.stringify(token))
  localStorage.setItem('refresh', JSON.stringify(refresh))
  localStorage.setItem(
    'user',
    JSON.stringify({ name, studentId, studentFreemium, qtdPrivateClass }),
  )

  return null
}

api.interceptors.request.use((config) => {
  const token =
    JSON.parse(localStorage.getItem('token')) || urlParams.get('token')

  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const { status, data, config } = error.response

    switch (status) {
      case 400:
        notification.error(data.message)
        break
      case 401:
        if (config.url === '/student/token/refresh') {
          signOut()
          return
        }
        await refreshToken()
        // eslint-disable-next-line consistent-return
        return api.request(config)
      case 403:
        notification.error('Aluno não possui acesso a esse recurso.')
        break
      case 404:
        notification.error('Recurso não encontrado.')
        break
      case 444:
        notification.error(data.message)
        break
      case 500:
        notification.error(data.message)
        break
      default:
        notification.error('')
        break
    }

    // eslint-disable-next-line consistent-return
    return Promise.reject(error)
  },
)

export default api
