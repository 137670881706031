const studyPlansModeEnum = {
  studyPlans: 1,
  subjects: 2,
}

const studyPlansViewEnum = {
  calendar: 1,
  list: 2,
}

export { studyPlansModeEnum, studyPlansViewEnum }
