import React from 'react'
import { Form } from 'antd'

import InputSearch from '~/shared/InputSearch'

function StudentFormFilterComponent(props) {
  const { onSubmit: onFinish } = props

  return (
    <Form name="study-form">
      <Form.Item name="filters" style={{ marginBottom: 10 }}>
        <InputSearch placeholder="Buscar dúvidas" onSearch={onFinish} />
      </Form.Item>
    </Form>
  )
}

export default StudentFormFilterComponent
