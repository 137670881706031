import React from 'react'

import services from '../student-form.services'
// eslint-disable-next-line import/order
import { Editor } from '@tinymce/tinymce-react'

function Tinymce(props) {
  const { valueResposta, disabled, onEditorChange, view } = props

  return (
    <Editor
      key={1}
      apiKey="ieb59hbzg8i2husrzf2w667b49ykjg955zehe0a36yffty17"
      initialValue={valueResposta}
      content="1"
      disabled={disabled}
      init={{
        branding: false,
        menubar: false,
        resize: false,
        height: view === undefined ? 500 : '',

        plugins:
          view === undefined
            ? [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
                'lists, link, code image',
              ]
            : [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
                'lists, link, code image',
                'autoresize',
              ],
        external_plugins: {
          mathjax:
            'https://cdn.jsdelivr.net/npm/@dimakorotkov/tinymce-mathjax@1.0.4/plugin.min.js',
        },
        mathjax: {
          lib:
            'https://cdn.jsdelivr.net/npm/mathjax@3.0.5/es5/tex-mml-chtml.js',
        },

        language: 'pt_BR',

        toolbar:
          view === undefined
            ? 'undo redo | styleselect | ' +
              'bold italic backcolor  | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'mathjax | removeformat | image code'
            : false,

        content_style: 'img { max-width: 100%; }',

        images_upload_handler(blobInfo, success, failure) {
          async function saveImage() {
            try {
              const formData = new FormData()
              formData.append('file', blobInfo.blob(), blobInfo.filename())
              const saveImage = await services.postImage(formData)
              const imgUrl = saveImage.data.imageUrl
              success(imgUrl)
            } catch {
              failure('Ocorreu um erro.')
            }
          }

          saveImage()
        },
      }}
      onEditorChange={onEditorChange}
    />
  )
}

export default Tinymce
