import React, { useEffect, useState } from 'react'
import { Button, Input, Tooltip } from 'antd';
import notification from '~/shared/Notification'

function LessonsRatingComponent(props) {

  const { TextArea } = Input;
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [score, setScore] = useState(false)

  useEffect(() => {
    getCurrentFeedback()
  }, []);

  const getCurrentFeedback = async () => {
    const currentFeedback = await props.services.getFeedback(props.lessonId, props.isLive);
    setScore(currentFeedback.data.score);
    setFeedbackMessage(currentFeedback.data.feedbackMessage)
  }

  //Click do Emoji
  const clickRating = newScore => {
    setScore(newScore);
    props.services.setFeedback(props.lessonId, newScore, feedbackMessage, props.isLive);
    notification.success('Você avaliou essa aula!')
  }

  //Click do Botão Avaliar
  const submitComment = () => {
    props.services.setFeedback( props.lessonId, score, feedbackMessage, props.isLive );
    notification.success('Você avaliou essa aula!')
  };
  
  const emojis = [
    { title: 'Péssimo', emoji: '😤', score: 2 },
    { title: 'Ruim', emoji: '😠', score: 4 },
    { title: 'Razoável', emoji: '😐', score: 6 },
    { title: 'Bom', emoji: '😀', score: 8 },
    { title: 'Amei', emoji: '🥰', score: 10 },
  ];

  return (
    
    <div style={{ fontSize: '24px', fontFamily: 'Open Sans' }}>

      {/* Score */}
      { props.lessonId && (

          <>
            <hr />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span style={{ marginRight: '10px' }}>Avalie essa Aula: </span>

                { Object.entries(emojis).map( el => {

                  const emoji = el[1];

                  const backgroundColor = (score == emoji.score) ? '#D3D3D3' : '#FFF';
                  
                  return ( 
                    <Tooltip title={ emoji.title }>
                      <span 
                        onClick={ () => clickRating(emoji.score) } 
                        style={{ 
                          cursor: 'pointer', 
                          backgroundColor,
                          borderRadius: '50%',
                          padding: '0 5px 0 5px'
                        }}>
                          { emoji.emoji }
                      </span>
                    </Tooltip>
                  )
                })}    
            </div>
          </>
      )}
            
    {/* FeedbackMessage */}
    { props.lessonId && !!score &&

      <TextArea 
        style={{ margin: "15px 0 15px 0"}} 
        value={ feedbackMessage }
        placeholder="Justifique sua Avaliação"
        onChange={ ev => setFeedbackMessage( ev.target.value )}            
      />
    }
    
    {/* Submit Button */}
    { props.lessonId && feedbackMessage &&
    
      <span style={{ 
        display: 'flex',
        justifyContent: 'end',
      }}>
        <Button onClick={() => submitComment() }>Avaliar</Button>
      </span>
    }

  </div>
  )
}

export default LessonsRatingComponent
