import styled, { css } from 'styled-components'

import { Tag } from 'antd'

const wrapperModifiers = {
  lineLeft: (theme) => css`
    border-left: 7px solid ${theme.colors.primary};
  `,

  lineBottom: (theme) => css`
    padding-left: 0;
    position: relative;
    margin-bottom: ${theme.spacings.medium};

    &::after {
      content: ' ';
      border-bottom: 7px solid ${theme.colors.primary};
      width: 5rem;
      position: absolute;
      left: 0;
      bottom: -1rem;
    }
  `,
}

const DetailsTag = styled(Tag)`
  border-radius: 20px;
  width: ${(props) => props.width};
  margin-bottom: ${(props) => props.marginBottom};
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => props.padding};
  color: #fff;
  text-align: center;
  cursor: ${(props) => props.cursor};
`

const DetailsWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.small} ${theme.spacings.small};
  `}
`

const DetailsCard = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xsmall};
    padding: ${theme.spacings.xsmall};
    border-radius: ${theme.border.radius};
    background-color: #fff;
  `}
`

const DetailsInfo = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.familySecondary};
    font-size: ${theme.font.sizes.xxxxsmall};
    cursor: ${(props) => props.cursor};

    ${(props) => (props.hover ? '&:hover { text-decoration: underline;}' : '')}

    :not(:last-child) {
      margin-bottom: ${theme.spacings.xxsmall};
    }
  `}
`

const DetailsInfoStrong = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.xxxsmall};
  `}
`
const Wrapper = styled.h2`
  ${({ theme, lineBottom }) => css`
    padding-left: 1rem;
    font-size: 1.4rem;

    ${lineBottom && wrapperModifiers.lineBottom(theme)};
    ${!lineBottom && wrapperModifiers.lineLeft(theme)};
  `}
`

export {
  DetailsTag,
  DetailsWrapper,
  DetailsCard,
  DetailsInfo,
  DetailsInfoStrong,
  Wrapper,
}
