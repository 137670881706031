import React from 'react'

import * as S from '../lessons.styles'

import Button from '~/shared/Button'

function LessonsNavigationComponent(props) {
  const {
    previousLesson,
    nextLesson,
    enablePreviousButton,
    enableNextLessonButton,
  } = props

  return (
    <S.ButtonWrapper>
      <Button
        height="44px"
        fontSize="18px"
        fontWeight="bold"
        index={2}
        disabled={!enablePreviousButton}
        onClick={previousLesson}
      >
        Aula anterior
      </Button>

      <Button
        height="44px"
        borderColor="#403edc"
        backgroundColor="#403edc"
        fontSize="18px"
        fontWeight="bold"
        color="#fff"
        index={1}
        disabled={!enableNextLessonButton}
        onClick={nextLesson}
      >
        Próxima aula
      </Button>
    </S.ButtonWrapper>
  )
}

export default LessonsNavigationComponent
