import styled, { css } from 'styled-components'

const SubscriptionPlansWrapper = styled.div`
  ${({ theme }) => css`
    justify-content: center;
    display: flex;
    grid-template-columns: repeat(1fr);
    gap: ${theme.spacings.small};
    margin-top: ${theme.spacings.xxsmall};
    width: '432px';
    height: '631px';
    overflow: hidden;
  `}
`

const SubscriptionPlansItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: ${theme.border.radius};
    box-shadow: 0 0 15px rgb(0 0 0 / 5%);
    transition: all 0.5s ease;
    height: 500px;
  `}
`

const SubscriptionPlansDivider = styled.div`
  ${({ theme }) => css`
    border-bottom: 0px solid rgba(0, 0, 0, 0.16);
    border-color: '#BABABA';
    padding: 5px 0;
  `}
`
const SubscriptionPlansHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    padding: ${theme.spacings.xxxsmall};
    border-top-left-radius: ${theme.border.radius};
    border-top-right-radius: ${theme.border.radius};
  `}
`

const SubscriptionPlansTitle = styled.h3`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily};
    font-weight: 500;
    font-size: ${theme.font.sizes.xxsmall};
    color: ${theme.colors.white};
    margin: 0;
    padding: 10px 10px;
  `}
`

const SubscriptionPlansSubtitle = styled.p`
  ${({ theme, color }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${color === 'secondary'
      ? `${theme.colors.white}`
      : `${theme.colors.secondary}`};
    margin: 0;
    padding: 0;
    padding-right: 15px;
  `}
`

const SubscriptionPlansContent = styled.div`
  ${({ theme }) => css`
    text-align: center;
    padding-top: 10px;
  `}
`
const SubscriptionPlansPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const SubscriptionPlansMonetary = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacings.xxsmall};
    font-size: ${theme.font.sizes.xxsmall};
    font: 'Futura PT';
    font-weight: ${theme.font.bold};
    vertical-align: super;
  `}
`

const SubscriptionPlansPrice = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxsmall};
    font: 'Futura PT';
    font-weight: ${theme.font.bold};
  `}
`

const SubscriptionPlansMonth = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxxsmall};
  `}
`

const SubscriptionPlansMonthBold = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
  `}
`

const SubscriptionPlansDescont = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.white};
    opacity: 0.8;
  `}
`

const SubscriptionPlansDescontLine = styled.span`
  text-decoration: line-through;
`

const SubscriptionPlansBenefitsList = styled.ul`
  ${({ theme }) => css`
    padding: 10px 10px 0 0;
    margin-left: ${theme.font.sizes.small};
    text-align: left;
    list-style: none;
    overflow-y: auto;
    margin-top: ${theme.spacings.xxsmall};
    max-width: 325px;
  `}
`

const SubscriptionPlansBenefitsItem = styled.li`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0px;
    font-size: ${theme.font.sizes.xxxsmall};
    list-style: none;
    &::before {
      content: '';
      width: 5px;
      height: 5px;
      margin-right: 0.8rem;
      padding-right: 5px;
      border-radius: 50%;
      background-color: #5fcc58;
    }
  `}
`

const SubscriptionPlansFooter = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.xxxsmall};
    color: ${theme.colors.text};
    text-align: center;
    margin-top: ${theme.spacings.xxsmall};
  `}
`

const SubscriptionPlansDuration = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xxsmall};
    font-size: ${theme.font.sizes.xxsmall};
    opacity: 0.8;
  `}
`

const SubscriptionPlansButton = styled.button`
  ${({ theme }) => css`
    width: 344px;
    height: 60px;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding: 0px;
    font-size: ${theme.font.sizes.xxxsmall};
    color: white;
    border-radius: 0.8rem;
    border: 1px solid rgba(0, 0, 0, 0.16);
    border-color: 'white';
    cursor: pointer;
  `}
`

export {
  SubscriptionPlansWrapper,
  SubscriptionPlansItem,
  SubscriptionPlansDivider,
  SubscriptionPlansHeader,
  SubscriptionPlansTitle,
  SubscriptionPlansSubtitle,
  SubscriptionPlansContent,
  SubscriptionPlansPriceContainer,
  SubscriptionPlansMonetary,
  SubscriptionPlansPrice,
  SubscriptionPlansMonth,
  SubscriptionPlansMonthBold,
  SubscriptionPlansDescont,
  SubscriptionPlansDescontLine,
  SubscriptionPlansBenefitsList,
  SubscriptionPlansBenefitsItem,
  SubscriptionPlansFooter,
  SubscriptionPlansDuration,
  SubscriptionPlansButton,
}
