import React from 'react'
import { Grid, Button } from 'antd'
import { RightOutlined, LeftOutlined } from '@ant-design/icons'

import * as S from './styles'

function Sidebar(props) {
  const breakpoint = Grid.useBreakpoint()

  const { visible, setVisible, collapsed, setCollapsed, children } = props

  const width = 300
  const collapsedWidth = 100

  return breakpoint.xs ? (
    <>
      <S.Drawer
        placement="left"
        width={width}
        height="100%"
        visible={visible}
        style={{ backgroundColor: '#F2F2F2', opacity: '0.73' }}
        onClose={() => setVisible(false)}
      >
        <S.Sider
          width={width}
          height="100%"
          style={{ backgroundColor: '#F2F2F2', opacity: '0.73' }}
        >
          {children}
        </S.Sider>
      </S.Drawer>

      <Button
        type="primary"
        size="small"
        shape="circle"
        icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
        onClick={() => setVisible((visible) => !visible)}
        style={{
          position: 'absolute',
          left: 0,
          top: 108,
          backgroundColor: '#403edc',
          borderColor: '#403edc',
        }}
      />
    </>
  ) : (
    <>
      <S.Sider
        width={width}
        /* height="100%" */
        collapsedWidth={collapsedWidth}
        collapsed={collapsed}
        className={collapsed && 'sidebar-hidden'}
        asideHeight="100vh"
      >
        <Button
          type="primary"
          size="small"
          shape="circle"
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => setCollapsed((collapsed) => !collapsed)}
          style={{
            position: 'absolute',
            right: 0,
            marginRight: -10,
            top: 108,
            backgroundColor: '#403edc',
            borderColor: '#403edc',
          }}
          className="sidebar-button"
        />

        {children}
      </S.Sider>
    </>
  )
}

export default Sidebar
