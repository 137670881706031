import styled from 'styled-components'
import { Typography } from 'antd'

const Text = styled(Typography.Text).withConfig({
  shouldForwardProp: (prop) => {
    return !['minFontSize'].includes(prop)
  },
})`
  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) =>
    `min(${props.fontSize || '14px'}, ${props.minFontSize || '5vw'})`};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  cursor: ${(props) => props.cursor};
`

export { Text }
