import React from 'react'
import { TableOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { Space } from 'antd'

function StudyPlansViewComponent(props) {
  const { selectedView, setSelectedView } = props

  const views = [
    { id: 1, icon: TableOutlined, onClick: () => setSelectedView(1) },
    { id: 2, icon: UnorderedListOutlined, onClick: () => setSelectedView(2) },
  ]

  return (
    <Space size="middle">
      {views.map((view) => {
        const Icon = view.icon
        const active = view.id === selectedView

        return (
          <Icon
            key={view.id}
            active={active}
            onClick={view.onClick}
            style={{ fontSize: 22, color: active ? '#403edc' : '#939393' }}
          />
        )
      })}
    </Space>
  )
}

export default StudyPlansViewComponent
