import React from 'react'
import { Image } from 'antd'

import corujaSabiaWhite from '~/assets/img/coruja-sabia-white.svg'
import corujaSabiaTitleWhite from '~/assets/img/coruja-sabia-title-white.png'

function Logo(props) {
  const { width, height, showTitle, preview = false } = props
  let src = null

  if (showTitle) src = corujaSabiaTitleWhite
  else src = corujaSabiaWhite

  return (
    <Image
      src={src}
      width={width}
      height={height}
      preview={preview}
      alt="Coruja Sábia"
    />
  )
}

export default Logo
