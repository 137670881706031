import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Tooltip, Skeleton, Table } from 'antd'

import * as T from '~/shared/styles'
import api from '../essay.services'

import Modal from './essay-modal.component'

import * as S from '../essay.style'

function EssayListComponent() {
  const { Title, Text } = Typography
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState(null)
  const [data, setData] = useState([])

  const getList = async () => {
    setLoading(true)
    try {
      const response = await api.getList()
      const { data } = response

      setData(data)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }
  useEffect(() => {
    async function getData() {
      await getList()
    }

    getData()
  }, [])

  const download = (studentEssayUrl) => {
    window.open(studentEssayUrl)
  }

  const downloadEssayWithScore = (markedUrl) => {
    window.open(markedUrl)
  }

  const redirectClass = (studyPlanId, disciplineLessonId) => {
    history.push(`${studyPlanId}/aula/${disciplineLessonId}`)
  }

  const upload = (item) => {
    setItem(item)
    setVisible(true)
  }

  const handleOk = async () => {
    setVisible(false)
    await getList()
  }
  const handleCancel = () => {
    setVisible(false)
  }

  const renderAction = (item) => {
    return (
      <>
        {!item?.studentEssayStatusDescription && (
          <T.TableButton>
            <Tooltip title="Enviar Redação">
              <S.StudyPlansTag
                backgroundColor="#939393"
                cursor="pointer"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
                onClick={() => upload(item)}
              >
                <S.StudyPlansUploadOutlinedIcon />
              </S.StudyPlansTag>
            </Tooltip>
          </T.TableButton>
        )}

        {(item?.studentEssayStatusDescription === 'Em Correção' ||
          item?.studentEssayStatusDescription === 'Aguardando') && (
          <T.TableButton>
            <S.StudyPlansTag
              backgroundColor="#46d83f"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <S.StudyPlansVideoCheckCircleOutlined />
            </S.StudyPlansTag>
          </T.TableButton>
        )}

        {(item?.studentEssayStatusDescription === 'Em Correção' ||
          item?.studentEssayStatusDescription === 'Aguardando') && (
          <T.TableButton>
            <Tooltip title="Download Redação">
              <S.StudyPlansTag
                backgroundColor="#939393"
                cursor="pointer"
                style={{ display: 'flex', justifyContent: 'center' }}
                onClick={() => download(item?.studentEssayUrl)}
              >
                <S.StudyPlansDownloadOutlinedIcon />
              </S.StudyPlansTag>
            </Tooltip>
          </T.TableButton>
        )}

        {item?.studentEssayStatusDescription === 'Corrigida' && (
          <T.TableButton>
            <Tooltip title="Download Correção">
              <S.StudyPlansTag
                backgroundColor="#939393"
                cursor="pointer"
                style={{ display: 'flex', justifyContent: 'center' }}
                onClick={() => downloadEssayWithScore(item?.markedUrl)}
              >
                <S.StudyPlansDownloadOutlinedIcon />
              </S.StudyPlansTag>
            </Tooltip>
          </T.TableButton>
        )}

        <T.TableButton>
          <Tooltip title="Assistir aula">
            <S.StudyPlansTag
              backgroundColor="#939393"
              cursor="pointer"
              style={{ display: 'flex', justifyContent: 'center' }}
              onClick={() =>
                redirectClass(item.studyPlanId, item.disciplineLessonId)
              }
            >
              <S.StudyPlansVideoCameraOutlinedIcon />
            </S.StudyPlansTag>
          </Tooltip>
        </T.TableButton>
      </>
    )
  }

  const renderMarkStartDate = (item) => {
    const newDate = new Date(
      item?.studentEssayStatusDescription === 'Aguardando'
        ? item?.studentUploadDate
        : item?.markStartDate,
    )

    const date = newDate.toLocaleDateString('pt-br', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })

    const time = newDate.toLocaleTimeString('pt-br', {
      hour: 'numeric',
      minute: 'numeric',
    })

    return (
      <Text>
        {!item?.studentEssayStatusDescription
          ? 'Não enviado'
          : `${date} às ${time}`}
      </Text>
    )
  }

  const renderScore = (item) => {
    return (
      <Text>
        {item?.score ||
          // eslint-disable-next-line no-nested-ternary
          (!item?.studentEssayStatusDescription
            ? 'Não enviado'
            : item?.studentEssayStatusDescription === 'Aguardando'
            ? ' Aguardando correção'
            : item?.studentEssayStatusDescription)}
      </Text>
    )
  }

  function renderMobile(item) {
    const { disciplineLessonName, markStartDate } = item

    return (
      <>
        {!!disciplineLessonName && (
          <T.TableText className="text-capitalize">
            Título: {` `}
            <T.TableSpan>{disciplineLessonName.toLowerCase()}</T.TableSpan>
          </T.TableText>
        )}

        {!!markStartDate && (
          <T.TableText className="text-capitalize">
            Enviado: {` `}
            <T.TableSpan style={{ textTransform: 'initial' }}>
              {renderMarkStartDate(item)}
            </T.TableSpan>
          </T.TableText>
        )}

        <T.TableText>
          Nota: <T.TableSpan>{renderScore(item)}</T.TableSpan>
        </T.TableText>

        {renderAction(item)}
      </>
    )
  }

  const columns = [
    {
      title: 'Título',
      dataIndex: 'disciplineLessonName',
      responsive: ['md'],
    },
    {
      title: 'Enviado',
      responsive: ['md'],
      render: (item) => renderMarkStartDate(item),
    },
    {
      title: 'Nota',
      responsive: ['md'],
      render: (item) => renderScore(item),
    },
    {
      title: 'Ação',
      responsive: ['md'],
      render: (item) => renderAction(item),
    },
    {
      title: 'Redações',
      responsive: ['xs'],
      render: (item) => renderMobile(item),
    },
  ]

  if (loading) return <Skeleton active />

  return (
    <S.EssayWrapper>
      <Modal
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        item={item}
      />

      <T.TableWrapper>
        <Title level={4} style={{ color: '#403edc' }} className="table-title">
          Redações
        </Title>

        <Table columns={columns} dataSource={data} className="table-content" />
      </T.TableWrapper>
    </S.EssayWrapper>
  )
}

export default EssayListComponent
