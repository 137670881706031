import { LeftOutlined } from '@ant-design/icons'
import { Layout as AntdLayout } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useAuth from '~/hooks/useAuth'
import Box from '~/shared/Box'
import Content from '~/shared/Content'
import { Text } from '~/shared/Typography'
import * as S from '../external.styles'

function ExternalContainer(props) {
  const [isMobile, setIsMobile] = useState(false)
  const [localStorageCheck, setLocalStorageCheck] = useState()
  const { children, routeBack, subscription, withoutHeader } = props
  const auth = useAuth()

  useEffect(() => {
    const isMbGet = localStorage.getItem('isMb')
    setLocalStorageCheck(isMbGet)

    if (localStorageCheck) setIsMobile(localStorageCheck)
  }, [localStorageCheck])

  return (
    <S.StyledLayout>
      {!withoutHeader && isMobile === false && (
        <AntdLayout.Header
          style={{
            marginBottom: 48,
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: routeBack ? 'space-between' : 'flex-end',
          }}
        >
          {routeBack && (
            <Link to={routeBack}>
              <Text
                style={{
                  fontSize: 16,
                  color: '#fff',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
              >
                <LeftOutlined style={{ marginRight: 8 }} />
                Voltar
              </Text>
            </Link>
          )}

          <Text style={{ fontSize: 16, color: '#fff', fontWeight: 'bold' }}>
            {auth.user.name.split(' ')[0]}
          </Text>
        </AntdLayout.Header>
      )}
      {!subscription && (
        <Content>
          <Box ml="auto" mr="auto" mw="960px">
            {children}
          </Box>
        </Content>
      )}{' '}
      {subscription && <>{children}</>}
    </S.StyledLayout>
  )
}

export default ExternalContainer
