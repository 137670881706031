import React from 'react'

import * as S from './styles'

import Container from '../../shared/Container'
import Heading from '../../shared/Heading'

import useData from '../../hooks/useData'

import formatCurrency from '../../utils/format-currency'
import GlobalStyles from '../../../../styles/global'
import notification from '~/shared/Notification'
import useAuth from '~/hooks/useAuth'
import messageNotificationVerifyUserDataInSubscription from '~/utils/messageNotificationVerifyUserDataInSubscription'

function SubscriptionPlans() {
  const { subscriptionPlans, handleSubscriptionPlanClick } = useData()

  const auth = useAuth()

  const { verifyUserDataInSubscription } = auth

  function handleSelectPlan(subscriptionPlanId) {
    if (verifyUserDataInSubscription)
      notification.info(
        messageNotificationVerifyUserDataInSubscription(),
        '/minha-conta',
      )

    if (!verifyUserDataInSubscription)
      handleSubscriptionPlanClick(subscriptionPlanId)
  }

  return (
    <>
      <GlobalStyles />

      <Container size="large">
        <Heading>Planos de Assinatura</Heading>

        <S.SubscriptionPlansWrapper>
          {subscriptionPlans.map((subscriptionPlan) => (
            <S.SubscriptionPlansItem key={subscriptionPlan.id}>
              <S.SubscriptionPlansDivider>
                <S.SubscriptionPlansHeader
                  backgroundColor={subscriptionPlan.displayTheme}
                >
                  <S.SubscriptionPlansDivider>
                    <S.SubscriptionPlansTitle>
                      {subscriptionPlan.name}
                    </S.SubscriptionPlansTitle>
                    <S.SubscriptionPlansSubtitle
                      color={subscriptionPlan.displayTheme}
                    >
                      {subscriptionPlan.shortDescription}
                    </S.SubscriptionPlansSubtitle>
                  </S.SubscriptionPlansDivider>

                  {subscriptionPlan.amount !==
                    subscriptionPlan.amountWithDescount && (
                    <S.SubscriptionPlansDescont>
                      de{' '}
                      <S.SubscriptionPlansDescontLine>
                        R${formatCurrency(subscriptionPlan.amount)}
                      </S.SubscriptionPlansDescontLine>{' '}
                      <S.SubscriptionPlansDivider>
                        por{' '}
                        <S.SubscriptionPlansMonthBold>
                          R$
                          {formatCurrency(subscriptionPlan.amountWithDescount)}
                        </S.SubscriptionPlansMonthBold>
                      </S.SubscriptionPlansDivider>
                    </S.SubscriptionPlansDescont>
                  )}
                </S.SubscriptionPlansHeader>

                <S.SubscriptionPlansContent>
                  <S.SubscriptionPlansMonetary>R$</S.SubscriptionPlansMonetary>
                  <S.SubscriptionPlansPrice>
                    {subscriptionPlan.amount ===
                      subscriptionPlan.amountWithDescount &&
                      (!subscriptionPlan.displayInstallmentAmount ||
                        subscriptionPlan.maximumInstallments === 1) &&
                      formatCurrency(subscriptionPlan.amount)}

                    {subscriptionPlan.amount ===
                      subscriptionPlan.amountWithDescount &&
                      subscriptionPlan.displayInstallmentAmount &&
                      subscriptionPlan.maximumInstallments > 1 &&
                      formatCurrency(subscriptionPlan.installmentValue)}

                    {subscriptionPlan.amount !==
                      subscriptionPlan.amountWithDescount &&
                      (!subscriptionPlan.displayInstallmentAmount ||
                        subscriptionPlan.maximumInstallments === 1) &&
                      formatCurrency(subscriptionPlan.amountWithDescount)}

                    {subscriptionPlan.amount !==
                      subscriptionPlan.amountWithDescount &&
                      subscriptionPlan.displayInstallmentAmount &&
                      subscriptionPlan.maximumInstallments > 1 &&
                      formatCurrency(
                        subscriptionPlan.installmentValueWithDescount,
                      )}
                  </S.SubscriptionPlansPrice>

                  {subscriptionPlan.displayInstallmentAmount &&
                    subscriptionPlan.maximumInstallments > 1 && (
                      <S.SubscriptionPlansMonth>
                        /mês por{' '}
                        <S.SubscriptionPlansMonthBold>
                          {subscriptionPlan.maximumInstallments} meses
                        </S.SubscriptionPlansMonthBold>
                      </S.SubscriptionPlansMonth>
                    )}

                  <S.SubscriptionPlansBenefitsList>
                    {subscriptionPlan.subscriptionPlanBenefits.map(
                      (subscriptionPlanBenefit) => (
                        <S.SubscriptionPlansBenefitsItem
                          key={subscriptionPlanBenefit.id}
                        >
                          {subscriptionPlanBenefit.description}
                        </S.SubscriptionPlansBenefitsItem>
                      ),
                    )}
                  </S.SubscriptionPlansBenefitsList>
                </S.SubscriptionPlansContent>
              </S.SubscriptionPlansDivider>

              <S.SubscriptionPlansFooter>
                <S.SubscriptionPlansButton
                  backgroundColor={subscriptionPlan.displayTheme}
                  onClick={() => handleSelectPlan(subscriptionPlan)}
                >
                  {subscriptionPlan.choosePlanButtonLabel}
                </S.SubscriptionPlansButton>
              </S.SubscriptionPlansFooter>
            </S.SubscriptionPlansItem>
          ))}
        </S.SubscriptionPlansWrapper>
      </Container>
    </>
  )
}

export default SubscriptionPlans
