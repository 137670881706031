import styled from "styled-components";

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const LoaderImage = styled.img`
  width: 120px;
`;

export { LoaderWrapper, LoaderImage };
