import React, { useCallback, useMemo, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Collapse } from 'antd'

import StudyPlansExpandComponent from './study-plans-expand.component'
import StudyPlansProgressComponent from './study-plans-progress.component'

import * as S from '../study-plans.styles'

import Space from '~/shared/Space'
import Box from '~/shared/Box'
import Card from '~/shared/Card'
import { Text } from '~/shared/Typography'

function StudyPlansListComponent(props) {
  const history = useHistory()

  const { selectedStudyPlanContent } = props

  const redirectLesson = useCallback(
    (idStudyPlan, id) => {
      history.push(`${idStudyPlan}/aula/${id}`)
    },
    [history],
  )

  useEffect(() => {
    localStorage.setItem('isStudyPlan', true)
  })

  const MemoizedList = useMemo(
    () => (
      <Collapse
        defaultActiveKey={selectedStudyPlanContent.currentStep}
        expandIcon={(expandIcon) => (
          <StudyPlansExpandComponent isActive={expandIcon.isActive} />
        )}
        ghost
      >
        {selectedStudyPlanContent.steps.map((studyPlanContentStep) => (
          <S.StudyPlansCollapsePanel
            key={studyPlanContentStep.id}
            header={
              <>
                <Row align="middle" justify="space-between">
                  <Box ml="32px">
                    <Text fontSize="18px" fontWeight="bold" color="#434343">
                      {studyPlanContentStep.label}
                    </Text>
                  </Box>

                  <Text fontFamily="Open Sans" fontSize="12px" color="#434343">
                    {studyPlanContentStep.lessonsDone} de{' '}
                    {studyPlanContentStep.totalLessons} aulas assistidas
                    {/* |{' '}
                    {studyPlanContentStep.pointsErned} de{' '}
                    {studyPlanContentStep.totalPoints} pontos ganhos */}
                  </Text>
                </Row>

                <StudyPlansProgressComponent
                  percent={studyPlanContentStep.completation * 10}
                />
              </>
            }
          >
            <Space size="large" direction="vertical">
              {studyPlanContentStep.daysOfWeek.map((studyPlanDay) => (
                <Card
                  key={studyPlanDay.dayOfWeek}
                  size="small"
                  padding="8px 24px"
                >
                  <Row justify="space-between">
                    <Box mb="16px">
                      <Text fontSize="18px" fontWeight="bold" color="#403edc">
                        {studyPlanDay.dayOfWeek}
                      </Text>
                    </Box>

                    {/* <Text
                      fontWeight="bold"
                      color={studyPlanDay.done ? '#434343' : '#939393'}
                    >
                      {studyPlanDay.totalPoints} pontos
                    </Text> */}
                  </Row>

                  {studyPlanDay.lessons.map((studyPlanLesson) => (
                    <Box key={studyPlanLesson.id} mb="8px">
                      <Row align="middle">
                        {((studyPlanContentStep.studyPlanStepTypeId === 3 ||
                          (studyPlanContentStep.studyPlanStepTypeId === 2 &&
                            !studyPlanContentStep.isLastStepDone)) || !studyPlanLesson.canAccessLesson ) && (
                          <S.StudyPlansLockOutlinedIcon
                            fontSize="20px"
                            color={'#939393'}
                          />
                        )}
                        {((studyPlanContentStep.studyPlanStepTypeId === 1 ||
                          (studyPlanContentStep.studyPlanStepTypeId === 2 &&
                            studyPlanContentStep.isLastStepDone)) && studyPlanLesson.canAccessLesson ) && (
                          <S.StudyPlansCheckOutlinedIcon
                            color={studyPlanLesson.done ? '#52c41a' : '#939393'}
                          />
                        )}
                        <S.StudyPlansTag
                          backgroundColor={
                            studyPlanLesson.done ? '#403edc' : '#939393'
                          }
                        >
                          {studyPlanLesson.shortDisciplineName}
                        </S.StudyPlansTag>
                        {((studyPlanContentStep.studyPlanStepTypeId === 3 ||
                          (studyPlanContentStep.studyPlanStepTypeId === 2 &&
                            !studyPlanContentStep.isLastStepDone)) && studyPlanLesson.canAccessLesson ) && (
                          <Text
                            fontFamily="Open Sans"
                            fontWeight="bold"
                            cursor="pointer"
                            disabled={true}
                          >
                            {studyPlanLesson.title}
                          </Text>
                        )}
                        {(studyPlanContentStep.studyPlanStepTypeId === 1 ||
                          (studyPlanContentStep.studyPlanStepTypeId === 2 &&
                            studyPlanContentStep.isLastStepDone)) && studyPlanLesson.canAccessLesson && (
                          <Text
                            fontFamily="Open Sans"
                            fontWeight="bold"
                            cursor="pointer"
                            onClick={() =>
                              redirectLesson(
                                selectedStudyPlanContent.studyPlanId,
                                studyPlanLesson.id,
                              )
                            }
                          >
                            {studyPlanLesson.title}
                          </Text>
                        )}
                        {(studyPlanContentStep.studyPlanStepTypeId === 1 ||
                          (studyPlanContentStep.studyPlanStepTypeId === 2 &&
                            studyPlanContentStep.isLastStepDone)) && !studyPlanLesson.canAccessLesson && (
                          <Text
                            fontFamily="Open Sans"
                            fontWeight="bold"
                            
                          >
                            {studyPlanLesson.title}
                          </Text>
                        )}
                      </Row>
                    </Box>
                  ))}
                </Card>
              ))}
            </Space>
          </S.StudyPlansCollapsePanel>
        ))}
      </Collapse>
    ),
    [selectedStudyPlanContent, redirectLesson],
  )

  if (!selectedStudyPlanContent) return null

  return MemoizedList
}

export default StudyPlansListComponent
