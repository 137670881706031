import styled from 'styled-components'
import { Collapse, Tag } from 'antd'
import {
  UpOutlined,
  DownOutlined,
  CheckCircleFilled,
  LockOutlined,
} from '@ant-design/icons'

const DisciplinesCollapsePanel = styled(Collapse.Panel)`
  && {
    .ant-collapse-header {
      padding-left: 0;
    }

    .ant-collapse-content-box {
      padding: 0;
    }

    .collapse-discipline-subjects .anticon.anticon-up,
    .collapse-discipline-subjects .anticon.anticon-down {
      right: 0;
    }

    .collapse-discipline-subjects
      .ant-collapse-item:not(.ant-collapse-item-active)
      .ant-collapse-header {
      padding-bottom: 0;
    }
  }
`

const DisciplinesTag = styled(Tag)`
  padding-left: 16px;
  padding-right: 16px;

  border-radius: 20px;
  background-color: ${(props) => props.backgroundColor};
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  height: fit-content;
`

const DisciplinesUpOutlinedIcon = styled(UpOutlined)`
  position: absolute;
  top: 16px;

  font-size: 20px;
`

const DisciplinesDownOutlinedIcon = styled(DownOutlined)`
  position: absolute;
  top: 16px;

  font-size: 20px;
`

const DisciplinesCheckOutlinedIcon = styled(CheckCircleFilled)`
  font-size: 20px;
  color: ${(props) => props.color};
`

const DisciplinesLockOutlinedIcon = styled(LockOutlined)`
  font-size: 20px;
  color: ${(props) => props.color};
`

const DisciplinesFormCol = styled.div`
  width: 100%;
  display: flex;

  @media (min-width: 1024px) and (max-width: 1224px) {
    display: block;

    .discipline-col-form {
      max-width: 100%;
    }
  }

  @media (min-width: 1560px) {
    max-width: 72.333333%;
    flex: 0 0 72.333333%;
  }

  @media (min-width: 1900px) {
    max-width: 60.333333%;
    flex: 0 0 60.333333%;
  }
`

export {
  DisciplinesCollapsePanel,
  DisciplinesTag,
  DisciplinesUpOutlinedIcon,
  DisciplinesDownOutlinedIcon,
  DisciplinesCheckOutlinedIcon,
  DisciplinesLockOutlinedIcon,
  DisciplinesFormCol,
}
