import React, { createContext, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import useSessionStorage from '../hooks/useSessionStorage'

import api from '../../../services/api'
import serviceStudyPlan from '~/pages/studyplans/study-plans.services'

import formatCurrency from '~/pages/subscription/utils/format-currency'

const DataContext = createContext()

function DataProvider(props) {
  const [loading, setLoading] = useState(false)
  const [installmentsAmount, setInstallmentsAmount] = useState([])
  const [installmentInUntil, setInstallmentInUntil] = useState('')
  const [installmentInUntilCheck, setInstallmentInUntilCheck] = useState(false)

  const [
    storageSubscriptionPlan,
    setStorageSubscriptionPlan,
  ] = useSessionStorage('@CorujaSabia:SelectedSubscriptionPlan')

  const [storageStudyPlan, setStorageStudyPlan] = useSessionStorage(
    '@CorujaSabia:SelectedStudyPlan',
  )

  const [storageIdSubscription, setStorageIdSubscription] = useSessionStorage(
    '@CorujaSabia:IdSubscription',
  )

  const [
    storageStudentSubscriptionCouponCode,
    setStorageStudentSubscriptionCouponCode,
  ] = useSessionStorage('@CorujaSabia:StudentSubscriptionCouponCode')

  const [subscriptionPlans, setSubscriptionPlans] = useState([])
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(
    storageSubscriptionPlan || {},
  )

  const [studyPlans, setStudyPlans] = useState([])
  const [selectedStudyPlan, setSelectedStudyPlan] = useState(
    storageStudyPlan || null,
  )

  const [idSubscription, setIdSubscrition] = useState(
    storageIdSubscription || null,
  )

  const [
    studentSubscriptionCouponCheck,
    setStudentSubscriptionCouponCheck,
  ] = useState(false)

  const [checkoutFinishStep, setCheckoutFinishStep] = useState(false)

  const [shiftsIdsPlans, setShiftsIdsPlans] = useSessionStorage(
    '@CorujaSabia:shiftsIdsPlans',
  )

  const [allowLiveFaq, setAllowLiveFaq] = useState(false)

  const [tastingPlan, setTastingPlan] = useState([])

  const [accessLiveClasses, setAccessLiveClasses] = useState(false)

  const history = useHistory()

  const { children } = props

  const getSubscriptionPlans = useCallback(async () => {
    setLoading(true)

    const response = await api.get('/student/subscriptionplan/active')

    const formattedData = response.data.map((dataItem) => {
      return {
        ...dataItem,
        displayTheme:
          dataItem.displayTheme.charAt(0).toLowerCase() +
          dataItem.displayTheme.slice(1),
      }
    })

    setLoading(false)

    setSubscriptionPlans(formattedData)
    setStudyPlans([])
  }, [])

  const redirectCheckout = useCallback(() => {
    history.push('/renovacao/finalizar-assinatura')
  }, [history])

  const handleSubscriptionPlanClick = useCallback(
    (subscriptionPlan) => {
      setSelectedSubscriptionPlan(subscriptionPlan)
      setStorageSubscriptionPlan(subscriptionPlan)
      redirectCheckout()
    },
    [setSelectedSubscriptionPlan, setStorageSubscriptionPlan, redirectCheckout],
  )

  const handleStudyPlanClick = useCallback(
    (studyPlan) => {
      setSelectedStudyPlan(studyPlan)
      setStorageStudyPlan(studyPlan)

      redirectCheckout()
    },
    [setSelectedStudyPlan, setStorageStudyPlan, redirectCheckout],
  )

  const handleCheckoutComplete = useCallback(
    (idSubscription) => {
      setIdSubscrition(idSubscription)
      setStorageIdSubscription(idSubscription)

      history.push('/renovacao/detalhes-assinatura')
    },
    [setStorageIdSubscription, history],
  )

  const handleCheckoutFinishStep = useCallback((finishStep) => {
    setCheckoutFinishStep(finishStep)
  }, [])

  const getInstallmentsAmount = useCallback(async (planId, couponCode) => {
    try {
      const response = await api.get('/payment/calculate-installments-amount', {
        params: {
          subscriptionPlanId: planId,
          discountCouponCode: couponCode || '',
        },
      })

      const formattedInstallmentsAmount = response.data.map(
        (installmentAmount) => {
          return {
            value: installmentAmount.installment,
            description: `${installmentAmount.installment}x de ${formatCurrency(
              installmentAmount.installmentAmount,
            )}`,
          }
        },
      )

      const installment = await formattedInstallmentsAmount[
        formattedInstallmentsAmount.length - 1
      ]?.description.split(' ')

      setInstallmentInUntil(installment)

      setInstallmentsAmount(formattedInstallmentsAmount)
    } catch (err) {
      history.push('/renovacao')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getShiftsPlans = useCallback(async () => {
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index
    }

    try {
      const { data } = await serviceStudyPlan.getStudyPlans()

      const ids = data?.map((item) => {
        return item.studyPlanId
      })

      const { allowLiveFAQClasses } = data?.find(
        (item) => item.allowLiveFAQClasses,
      )

      setAllowLiveFaq(allowLiveFAQClasses)

      const unique = ids?.filter(onlyUnique)

      setShiftsIdsPlans(unique)

      if (data[0]?.accessLiveClasses) setAccessLiveClasses(true)

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTastingValidate = useCallback(
    async (planId, couponCode, studentId) => {
      setLoading(true)

      try {
        const response = await api.get(
          `/subscriptionplan/${planId}/trial-period`,
          {
            params: {
              discountCouponCode: couponCode || '',
              studentId: studentId || '',
            },
          },
        )

        const { data } = response

        setTastingPlan(data)
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    },
    [],
  )

  return (
    <DataContext.Provider
      value={{
        loading,

        getSubscriptionPlans,
        subscriptionPlans,
        handleSubscriptionPlanClick,
        selectedSubscriptionPlan,

        studyPlans,
        handleStudyPlanClick,
        selectedStudyPlan,

        redirectCheckout,

        handleCheckoutComplete,
        idSubscription,
        handleCheckoutFinishStep,
        checkoutFinishStep,

        getInstallmentsAmount,
        installmentsAmount,

        setInstallmentInUntil,
        installmentInUntil,
        setInstallmentInUntilCheck,
        installmentInUntilCheck,

        studentSubscriptionCouponCheck,
        setStudentSubscriptionCouponCheck,

        storageStudentSubscriptionCouponCode,
        setStorageStudentSubscriptionCouponCode,

        getShiftsPlans,
        shiftsIdsPlans,

        getTastingValidate,
        setTastingPlan,
        tastingPlan,

        allowLiveFaq,
        setAllowLiveFaq,

        accessLiveClasses,
        setAccessLiveClasses,
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export { DataContext }

export default DataProvider
