import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { Space, Row, Col, Button, Progress } from 'antd'
import { Text } from '~/shared/Typography'
import Layout from '~/shared/Layout'

function TestScoreComponent(props) {
  const history = useHistory()
  const [duration, setDuration] = useState({ hours: null, minutes: null })
  const [timeSpent, setTimeSpent] = useState(null)

  function secondsToHms(d) {
    d = Number(d)
    var h = Math.floor(d / 3600)
    var m = Math.floor((d % 3600) / 60)
    var s = Math.floor((d % 3600) % 60)

    var hDisplay = h > 0 ? h + (h == 1 ? ' hora ' : ' horas ') : ''
    var mDisplay = m > 0 ? m + (m == 1 ? ' minuto ' : ' minutos ') : ''
    var sDisplay = s > 0 ? s + (s == 1 ? ' segundo' : ' segundos') : ''
    return hDisplay + mDisplay + sDisplay
  }

  const convertToHoursMinutes = (totalMinutes) => {
    var hours = Math.floor(totalMinutes / 60)
    var minutes = totalMinutes % 60

    return { hours, minutes }
  }

  const { data } = props

  const {
    testName,
    numberOfQuestion,
    correctAnswer,
    testAverageScore,
    studentScore,
  } = data
  const { hours, minutes } = duration

  useEffect(() => {
    const { testDuration, timeSpentAsSeconds } = data

    const duration = convertToHoursMinutes(testDuration)
    const tSpent = secondsToHms(timeSpentAsSeconds)
    setDuration(duration)
    setTimeSpent(tSpent)
  }, [])

  const onClick = () => {
    history.push('/gabarito-simulado')
  }

  return (
    <>
      <Row>
        <Col xs={24} md={24}>
          <Space>
            <Text fontSize="32px" fontWeight="bold" color="#434343">
              {testName}
            </Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24}>
          <Space>
            <Text
              fontSize="20px"
              fontWeight="bold"
              color="#434343"
              style={{ marginLeft: '10px' }}
            >
              Simulado composto por {numberOfQuestion} questões com o tempo de
              resposta de
              {hours
                ? ` ${hours} horas e ${minutes} minutos`
                : ` ${minutes} minutos`}
              .
            </Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24}>
          <Space>
            <Text fontSize="25px" fontWeight="bold" color="#434343">
              Resultado:
            </Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24}>
          <Space>
            <Text
              fontSize="20px"
              fontWeight="bold"
              color="#434343"
              style={{ marginLeft: '10px' }}
            >
              Você conseguiu {correctAnswer} de {numberOfQuestion} pontos.
            </Text>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24}>
          <Space>
            <Text fontSize="20px" fontWeight="bold" color="#434343">
              Sua pontuação:
            </Text>
          </Space>
        </Col>
        <Col xs={17} md={24}>
          <Progress
            status="active"
            trailColor="#e2e2e2"
            percent={studentScore}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24}>
          <Space>
            <Text fontSize="20px" fontWeight="bold" color="#434343">
              Pontuação média:
            </Text>
          </Space>
        </Col>
        <Col xs={17} md={24}>
          <Progress
            status="active"
            trailColor="#e2e2e2"
            percent={testAverageScore}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={9} md={3}>
          <Text fontSize="20px" fontWeight="bold" color="#434343">
            Seu tempo:
          </Text>
        </Col>

        <Col xs={15} md={6}>
          <Text fontSize="20px" color="#434343">
            {timeSpent}
          </Text>
        </Col>
      </Row>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col xs={6} md={6}>
          <Button
            type="primary"
            shape="round"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50px',
              marginTop: '30px',
            }}
            onClick={onClick}
          >
            <Text fontSize="32px" color="#fff">
              Ver gabarito
            </Text>
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default TestScoreComponent
