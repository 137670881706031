import React, { useEffect, useState } from 'react'
import { Avatar, Typography, Skeleton } from 'antd'
import { UserOutlined, LeftOutlined } from '@ant-design/icons'

import * as S from '../mentorship.style'

function MentorShipViewComponent(props) {
  const { data, setStep } = props
  const { Title, Text } = Typography
  const [loading, setLoading] = useState(false)

  const renderCalendly = () => {
    setLoading(true)
    const head = document.querySelector('head')
    const script = document.createElement('script')
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js',
    )
    head.appendChild(script)
    setLoading(false)
  }

  useEffect(() => {
    renderCalendly()
  }, [])

  if (loading) return <Skeleton active />

  return (
    <S.EssayWrapper>
      <S.ButtonGoBack
        type="primary"
        size="large"
        shape="round"
        icon={<LeftOutlined />}
        onClick={() => setStep(1)}
      >
        Voltar para Professores
      </S.ButtonGoBack>
      <S.ViewTeacherContainer>
        <S.Content>
          <S.TeacherInfo style={{ display: 'flex' }}>
            <Avatar size={135} icon={<UserOutlined />} />
            <S.InfoContent>
              <S.TitleCard>{data.name}</S.TitleCard>
              <S.TextCard>{data.discipline}</S.TextCard>
              <span role="img" aria-label="estrelas">
                ⭐️ ⭐️ ⭐️ ⭐️ ⭐️
              </span>
            </S.InfoContent>
          </S.TeacherInfo>
          <S.BioContent>
            <S.BioText>{data.biography}</S.BioText>
          </S.BioContent>
        </S.Content>
        <S.ContentCalendar>
          <Text style={{ color: '#404040' }}>
            Selecione um dos horários disponíveis:
          </Text>
        </S.ContentCalendar>
        {data.calendlySlug ? (
          <div
            className="calendly-inline-widget"
            data-url={`https://calendly.com/${data.calendlySlug}/mentoria?hide_gdpr_banner=1&hide_event_type_details=1&primary_color=4f00e4`}
          />
        ) : (
          <Title level={4} style={{ color: '#404040' }}>
            Ainda não foi cadastrado nenhum horário
          </Title>
        )}
      </S.ViewTeacherContainer>
    </S.EssayWrapper>
  )
}

export default MentorShipViewComponent
