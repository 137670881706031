import styled from 'styled-components'
import media from 'styled-media-query'
import { Button } from 'antd'

const StyledButton = styled(Button)
  .attrs({
    shape: 'round',
  })
  .withConfig({
    shouldForwardProp: (prop) => {
      return !['borderColor', 'backgroundColor'].includes(prop)
    },
  })`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  padding-left: min(40px, 5vw);
  padding-right: min(40px, 5vw);

  margin-bottom: ${(props) => props.marginBottom};

  border-color: ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color || '#434343'};
  box-shadow: 0 6px 6px #0000000a;

  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => `min(${props.fontSize}, 3vw)`};
  font-weight: ${(props) => props.fontWeight};
  line-height: 0;

  z-index: ${(props) => props.index};

  :not(:first-child) {
    margin-left: ${(props) => props.marginLeft || '-32px'} ;
    padding-left: min(62px, 10vw);
  }

  :active,
  :hover,
  :focus {
    border-color: ${(props) => props.borderColor || '#eaeaea'};
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color || '#434343'};
  }

  &.button-panel {
    padding: 0 min(50px, 7vw);

    :not(:first-child) {
      padding: 0 min(50px, 7vw);
    }
  }

  ${media.lessThan('medium')`
    :not(:first-child) {
      margin-left: 0;
      padding-left: min(62px, 20px);
    }
  `}
`

export default StyledButton
