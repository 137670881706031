import api from '~/services/api'

const services = {
  getStudyPlans() {
    return api.get('/student/study-plan/valid?studyPlanType=0')
  },
  getStudyPlansWeek() {
    return api.get('/student/study-plan/valid?studyPlanType=1')
  },
  getStudyPlansDisciplines() {
    return api.get('/student/study-plan/valid?studyPlanType=2')
  },
  getById(id, filters) {
    return api.get(`/student/study-plan/${id}`, { params: filters })
  },
}

export default services
