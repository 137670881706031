import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col } from 'react-flexbox-grid'

import * as S from './styles'

import StatusInfo from './components/StatusInfo'
import StudentInfo from './components/StudentInfo'
import PlanInfo from './components/PlanInfo'
import PaymentInfo from './components/PaymentInfo'
import PaymentAction from './components/PaymentAction'

import Container from '../../shared/Container'

import api from '../../../../services/api'

import useData from '../../hooks/useData'
import GlobalStyles from '../../../../styles/global'

function Details() {
  const [paymentDetails, setPaymentDetails] = useState(null)

  const { idSubscription } = useData()

  const getPaymentDetails = useCallback(async () => {
    const response = await api.get('/student/subscription/status', {
      params: {
        subscriptionId: idSubscription,
      },
    })

    setPaymentDetails(response.data)
  }, [idSubscription])

  useEffect(() => {
    getPaymentDetails()
  }, [getPaymentDetails])

  useEffect(() => {
    if (paymentDetails) {
      if (
        paymentDetails.payment.status === 1 ||
        paymentDetails.payment.status === 2 ||
        paymentDetails.payment.status === 3
      ) {
        const getPaymentInterval = setInterval(getPaymentDetails(), 5000)

        clearInterval(getPaymentInterval)
      }
    }
  }, [getPaymentDetails, paymentDetails])

  return (
    <>
      <Container size="small" noPadding>
        {paymentDetails && (
          <Row>
            <Col xs={12}>
              <S.DetailsWrapper>
                <StatusInfo
                  id={idSubscription}
                  payment={paymentDetails.payment}
                />
              </S.DetailsWrapper>
            </Col>

            <Col xs={12}>
              <S.DetailsWrapper>
                <StudentInfo info={paymentDetails.info} />
              </S.DetailsWrapper>
            </Col>

            <Col xs={12}>
              <S.DetailsWrapper>
                <PlanInfo
                  subscription={paymentDetails.subscription}
                  studyPlans={paymentDetails.studyPlans}
                />
              </S.DetailsWrapper>
            </Col>

            <Col xs={12}>
              <S.DetailsWrapper>
                <PaymentInfo payment={paymentDetails.payment} />
              </S.DetailsWrapper>
            </Col>

            <Col xs={12}>
              <S.DetailsWrapper>
                <PaymentAction payment={paymentDetails.payment} />
              </S.DetailsWrapper>
            </Col>
          </Row>
        )}{' '}
        <GlobalStyles />
      </Container>
    </>
  )
}

export default Details
