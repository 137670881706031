import styled from 'styled-components'
import { UpCircleFilled, DownCircleFilled } from '@ant-design/icons'
import { Collapse, Layout } from 'antd'
import Vimeo from '@u-wave/react-vimeo'

const StyledLayout = styled(Layout)`
  background-color: #fff;

  @media (max-width: 1000px) {
    .row-alternative .ant-form-item .ant-card.ant-card-bordered.ant-card-small,
    .row-alternative .ant-space.ant-space-vertical,
    .row-alternative .ant-card.ant-card-bordered {
      width: 100% !important;
    }

    .row-alternative .ant-radio-group.ant-radio-group-outline,
    .row-alternative
      .ant-space.ant-space-vertical
      .ant-space-item
      .ant-space.ant-space-horizontal.ant-space-align-center,
    .row-alternative .ant-radio-group .ant-space {
      display: block;
    }

    .row-alternative .radio-alternative {
      margin: 0;
      height: auto !important;
    }

    .row-alternative .ant-space-item .ant-typography p {
      margin-bottom: 0;
    }

    .row-alternative .ant-row.ant-row-end.actions-button,
    .row-alternative .ant-space.ant-space-horizontal.ant-space-align-center {
      text-align: right;
      display: block;
    }

    .row-alternative
      .ant-row.ant-row-end.actions-button
      .ant-space
      .ant-space-item {
      margin: 0 !important;
      display: inline-block;
      vertical-align: middle;
    }

    .row-alternative
      .ant-row.ant-row-end.actions-button
      .ant-space
      .ant-space-item
      button {
      margin-right: 5px;
    }

    .row-alternative
      .ant-row.ant-row-end.actions-button
      .ant-space
      .ant-space-item
      span {
      display: none;
    }

    .row-alternative
      .ant-row.ant-row-end.actions-button
      .ant-space
      .ant-space-item
      .reply-button
      span {
      display: inline-block;
    }

    .row-alternative
      .ant-row.ant-row-end.actions-button
      .ant-space
      .ant-space-item
      .anticon {
      display: block;
    }
  }

  @media (max-width: 400px) {
    .row-alternative
      .ant-row.ant-row-end.actions-button
      .ant-space
      .ant-space-item
      button {
      padding: 10px;
      padding-bottom: 8px;
      width: auto;
      height: auto;
    }

    .row-alternative
      .ant-row.ant-row-end.actions-button
      .ant-space
      .ant-space-item
      .reply-button {
      margin: 0;
      padding: 6.4px 15px;
      font-size: 15px;
    }
  }

  @media (max-width: 325px) {
    .row-alternative
      .ant-row.ant-row-end.actions-button
      .ant-space
      .ant-space-item
      button {
      padding: 7px;
    }

    .row-alternative
      .ant-row.ant-row-end.actions-button
      .ant-space
      .ant-space-item
      .reply-button {
      font-size: 13px;
    }
  }
`

const LessonsCollapsePanel = styled(Collapse.Panel)`
  && {
    .ant-collapse-header {
      display: flex;
      align-items: center;

      padding-left: 16px;
    }
  }
`

const LessonsUpOutlinedIcon = styled(UpCircleFilled)`
  margin-right: 8px;

  font-size: 18px;
  color: #403edc;
`

const LessonsDownOutlinedIcon = styled(DownCircleFilled)`
  margin-right: 8px;

  font-size: 18px;
  color: #403edc;
`

const LessonsPlayer = styled(Vimeo)`
  position: relative;
  overflow: hidden;

  height: 0;

  padding-top: 0;
  padding-bottom: 56.25%;

  margin-bottom: 24px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
`

export {
  LessonsCollapsePanel,
  LessonsUpOutlinedIcon,
  LessonsDownOutlinedIcon,
  LessonsPlayer,
  StyledLayout,
}
