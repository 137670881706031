import api from '~/services/api'

const services = {
  getDisciplinesCombobox() {
    return api.get('/student/study-plan/discipline/combobox')
  },
  getDisciplines(discipline, pageNumber) {
    return api.get('/user/teacher/student', {
      params: {
        Role: 2,
        PrivateClasses: false,
        MentorShip: true,
        discipline,
        pageNumber,
        pageSize: 9,
      },
    })
  },
}

export default services
