import React, { useState, useEffect } from 'react'
import { Row, Col, Space, Form, Input, Button, Checkbox } from 'antd'

import InputMask from 'react-input-mask'
import services from '../userAccount.services'

import Box from '~/shared/Box'
import Card from '~/shared/Card'
import { Text } from '~/shared/Typography'
import Select from '~/shared/Select'
import Loader from '~/shared/Loader'

import { Info } from '../userAccount-styles'

import notification from '~/shared/Notification'

import brazilStates from '~/utils/brazil-states'
import removeMask from '~/utils/remove-mask'

import schema from '../schema'

import ErrorIconComponent from '~/shared/ErrorIconComponent'
import useAuth from '~/hooks/useAuth'

function UserAccountComponent() {
  const [dataInfo, setDataInfo] = useState([])
  const [dataPaymentInfo, setDataPaymentInfo] = useState([])
  const [cpfCheck, setCpfCheck] = useState(false)
  const [cpfError, setCpfError] = useState(false)
  const [mobileError, setMobileError] = useState(false)
  const [cepError, setCepError] = useState(false)
  const [loadingData, setLoadingData] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const requiredRule = [{ required: true }]
  const [form] = Form.useForm()

  const auth = useAuth()

  const { getVerifyUserDataInSubscription } = auth

  async function getUserPerfilDetails() {
    
    const response = await services.getUserDetails()
    const { data } = response

    setDataInfo(data.info)
    setDataPaymentInfo(data.paymentInfo)
    await getVerifyUserDataInSubscription()
  }

  useEffect(() => { getUserPerfilDetails() }, [])
  useEffect(() => {
    form.setFieldsValue({
      firstName: dataInfo.firstName,
      lastName: dataInfo.lastName,
      cpf: dataInfo.cpf,
      email: dataInfo.email,
      mobile: dataInfo.mobile,
      cep: dataPaymentInfo.cep,
      address: dataPaymentInfo.address,
      number: dataPaymentInfo.number,
      complement: dataPaymentInfo.complement,
      district: dataPaymentInfo.district,
      city: dataPaymentInfo.city,
      state: dataPaymentInfo.state
    })

    setLoadingData(false)
  }, [dataInfo, dataPaymentInfo, form])

  async function dataFinish(data) {

  
    try {

      const data = await form.getFieldValue();

      if(cpfError) {
        notification.error(cpfError);
        return;
      }

      if(cepError) {
        notification.error(cepError);
        return;
      }

      if(mobileError) {
        notification.error(mobileError);
        return;
      }

      setLoadingSubmit(true);

      await services.dataUpdate({
        user: {
          firstName: data.firstName,
          lastName: data.lastName,
          cpf: data.cpf,
          email: data.email,
          mobile: data.mobile
        },
        payment: {
          cep: data.cep,
          address: data.address,
          number: data.number,
          complement: data.complement,
          district: data.district,
          city: data.city,
          state: data.state
        }
      })

      notification.success('Dados alterado com sucesso.')

      setLoadingSubmit(false)
      setLoadingData(true)

      await getUserPerfilDetails()
      await getVerifyUserDataInSubscription()

      setLoadingData(false)
      
    } catch (e) {
      setLoadingSubmit(false)
      notification.error(e.message)
    }
  }

  async function handleCpfChange(data) {
    setCpfError(false)
    const cpfValidate = await schema.isValid({ cpfCheck: removeMask(data) })
    if (!cpfValidate && data.length > 0) setCpfError('CPF inválido.')
  }

  async function handleCepChange(data) {
    setCepError(false)
    if (removeMask(data).length === 0) setCepError(false)     
    else if (removeMask(data).length !== 8) setCepError('CEP inválido.')     
  }

  async function handleMobileChange(data) {
    setMobileError(false) 
    if (removeMask(data).length === 0) setMobileError(false)        
    else if (removeMask(data).length !== 11) setMobileError('Telefone inválido.') 
  }

  return (
    <Box>
      <Card size="default">
        <Loader loading={loadingData}>
          <Info>
            <Text className="title">Dados cadastrais</Text>

            <Form
              form={form}
              size="large"
              initialValues={{ active: true }}
              onFinish={dataFinish}
            >
              <Row gutter={16}>
                <Col xs={12} md={12}>
                  <Form.Item name="firstName" rules={requiredRule} hasFeedback>
                    <Input placeholder="Nome" />
                  </Form.Item>
                </Col>

                <Col xs={12} md={12}>
                  <Form.Item name="lastName" rules={requiredRule} hasFeedback>
                    <Input placeholder="Sobrenome" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={12} md={12} className={cpfError && 'alert-error-col'}>
                  <Form.Item
                    name="cpf"
                    rules={!cpfCheck && requiredRule}
                    hasFeedback
                    className={
                      cpfError &&
                      'ant-alert-error alert-error ant-form-item-with-help ant-form-item-has-error'
                    }
                  >
                    <InputMask
                      inputMode="numeric"
                      name="cpfCheck"
                      placeholder="CPF"
                      mask="999.999.999-99"
                      className={`${
                        cpfCheck && 'cursor-not-allowed ant-input-disabled'
                      } input`}
                      disabled={cpfCheck}
                      onBlur={(e) => handleCpfChange(e.target.value)}
                    />
                  </Form.Item>

                  {!!cpfError && (
                    <>
                      <ErrorIconComponent />

                      <div className="ant-form-item-explain ant-form-item-explain-error">
                        <div className="item-message-error" role="alert">
                          {cpfError}
                        </div>
                      </div>
                    </>
                  )}
                </Col>

                <Col xs={12} md={12}>
                  <Form.Item
                    name="email"
                    rules={[{ type: 'email' }, ...requiredRule]}
                    hasFeedback
                  >
                    <Input placeholder="E-mail" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={12} md={12} className={mobileError && 'alert-error-col'}>
                  <Form.Item 
                    name="mobile" 
                    rules={requiredRule} 
                    hasFeedback
                    className={ 
                      mobileError &&
                      'ant-alert-error alert-error ant-form-item-with-help ant-form-item-has-error'
                    }
                  >
                    <InputMask
                      placeholder="Telefone/Celular"
                      mask="(99) 9999-99999"
                      className="input"
                      onBlur={(e) => handleMobileChange(e.target.value)}
                    />
                  </Form.Item>
                  {!!mobileError && (
                  <>
                    <ErrorIconComponent />

                    <div className="ant-form-item-explain ant-form-item-explain-error">
                      <div className="item-message-error" role="alert">
                        {mobileError}
                      </div>
                    </div>
                  </>
                )}
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={12} md={12} style={{ marginBottom: '3px'}}>
                  <Text className="title">Endereço de cobrança</Text>
                </Col>
              </Row>

              <Row gutter={16}>
              <Col xs={12} md={12} className={cepError && 'alert-error-col'}>
                <Form.Item
                  name="cep"
                  rules={requiredRule}
                  hasFeedback={true}
                  className={ 
                    cepError &&
                    'ant-alert-error alert-error ant-form-item-with-help ant-form-item-has-error'
                  }
                >
                  <InputMask
                    placeholder="CEP"
                    mask="99999-999"
                    className="input"
                    onBlur={(e) => handleCepChange(e.target.value)}
                  />
                </Form.Item>
                {!!cepError && (
                  <>
                    <ErrorIconComponent />

                    <div className="ant-form-item-explain ant-form-item-explain-error">
                      <div className="item-message-error" role="alert">
                        {cepError}
                      </div>
                    </div>
                  </>
                )}
              </Col>

                <Col xs={12} md={12}>
                  <Form.Item
                    name="address"
                    rules={requiredRule}
                    hasFeedback={true}
                  >
                    <Input
                      placeholder="Endereço"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={12} md={12}>
                  <Form.Item
                    name="number"
                    rules={requiredRule}
                    hasFeedback={true}
                  >
                    <Input
                      type="number"
                      placeholder="Número"
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} md={12}>
                  <Form.Item name="complement">
                    <Input
                      placeholder="Complemento"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={12} md={12}>
                  <Form.Item
                    name="district"
                    rules={requiredRule}
                    hasFeedback={true}
                  >
                    <Input
                      placeholder="Bairro"
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} md={12}>
                  <Form.Item
                    name="city"
                    rules={requiredRule}
                    hasFeedback={true}
                  >
                    <Input
                      placeholder="Cidade"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={12} md={12}>
                  <Form.Item
                    name="state"
                    rules={requiredRule}
                    hasFeedback={true}
                  >
                    <Select
                      options={brazilStates}
                      placeholder="Estado"
                      allowClear
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={12} md={12}>
                  <Space>
                    <Button
                      htmlType="submit"
                      type="primary"
                      size="large"
                      shape="round"
                      loading={loadingSubmit}
                    >
                      Alterar Dados
                    </Button>
                  </Space>
                </Col>
              </Row>

            </Form>
          </Info>
        </Loader>
      </Card>
    </Box>
  )
}

export default UserAccountComponent
