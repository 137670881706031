import React from 'react'
import { Col, Grid } from 'antd'
import { Tada, FadeInRightBig } from 'animate-css-styled-components'

import {
  StyledLayout,
  StyledContent,
  StyledRow,
  StyledCard,
  StyledTitle,
  StyledSubtitle,
} from '../auth.styles'

import Logo from '~/shared/Logo'
import Footer from '~/shared/Footer'

function AuthContainer(props) {
  const { title, children } = props
  const { useBreakpoint } = Grid

  const screen = useBreakpoint()

  const logoWidth = screen.xs ? 130 : 160

  return (
    <StyledLayout>
      <StyledContent>
        <StyledRow align="middle">
          <Col span={24} md={8} align="center">
            <Tada>
              <Logo width={logoWidth} showTitle />
            </Tada>
          </Col>

          <Col span={24} md={16}>
            <FadeInRightBig>
              <StyledCard>
                <StyledTitle level={1}>Portal do Aluno</StyledTitle>
                {children}
              </StyledCard>
            </FadeInRightBig>
          </Col>
        </StyledRow>
      </StyledContent>

      <Footer color="#fff" />
    </StyledLayout>
  )
}

export default AuthContainer
