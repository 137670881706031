import React from 'react'
import ReactDOM from 'react-dom'
// import axe from 'react-axe'
import TagManager from 'react-gtm-module'

import App from './App'

import tagManagerConfig from '~/config/tag-manager'

// if (process.env.NODE_ENV !== 'production') axe(React, ReactDOM, 1000)

TagManager.initialize(tagManagerConfig)

ReactDOM.render(<App />, document.getElementById('root'))
