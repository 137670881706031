import React from 'react'

import ConfigProvider from './config'
import ThemeProvider from './theme'
import AuthProvider from './auth'
import DataProvider from '../pages/subscription/providers/data'

function Providers(props) {
  const { children } = props

  return (
    <ConfigProvider>
      <ThemeProvider>
        <AuthProvider>
          <DataProvider>{children}</DataProvider>
        </AuthProvider>
      </ThemeProvider>
    </ConfigProvider>
  )
}

export default Providers
