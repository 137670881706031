import LessonsContainer from './containers/lessons.container'

const routes = [
  {
    path: '/:idStudyPlan/aula/:id',
    component: LessonsContainer,
    authenticated: true,
  },
]

export default routes
