import styled from 'styled-components'
import { Layout as LayoutAntd, Drawer as DrawerAntd } from 'antd'

const Drawer = styled(DrawerAntd).attrs({
  bodyStyle: { padding: 0 },

})`
  padding: 0;
`

const Sider = styled(LayoutAntd.Sider)`
  background-color: #fff;
  height: 100%

  aside {
    height: ${(props) => props.asideHeight};
  }
`

export { Drawer, Sider }
