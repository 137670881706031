/* eslint-disable func-names */
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import {
  DownOutlined,
  DownSquareOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons'
import Dialog from '@material-ui/core/Dialog'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined'
import {
  Col,
  Collapse,
  Dropdown,
  Grid,
  Layout as AntdLayout,
  Menu,
  Skeleton,
  Tooltip,
} from 'antd'

import { v4 as uuid } from 'uuid'

import imgCorujaTop from '~/assets/img/Logo3bc.svg'
import useAuth from '~/hooks/useAuth'
import Box from '~/shared/Box'
import Button from '~/shared/Button'
import Content from '~/shared/Content'
import notification from '~/shared/Notification'
import Sidebar from '~/shared/Sidebar'
import { Text } from '~/shared/Typography'

import messageNotificationVerifyUserDataInSubscription from '~/utils/messageNotificationVerifyUserDataInSubscription'
import ModalPlansSelection from '../../modalPlans/components/modalPlans.component'
import DefaultSearchcomponent from '../../_layouts/default/containers/components/default-search.component'
import DefaultUserComponent from '../../_layouts/default/containers/components/default-user.component'
import LessonsExpandComponent from '../components/lessons-expand.component'
import LessonsHandlerComponent from '../components/lessons-handler.component'
import services from '../lessons.services'
import * as S from '../lessons.styles'

function LessonsContainer() {
  const auth = useAuth()
  const [loadingLesson, setLoadingLesson] = useState(true)

  const [visible, setVisible] = useState(false)
  const [collapsed, setCollapsed] = useState(false)

  const [treeView, setTreeview] = useState(null)
  const [lesson, setLesson] = useState(null)
  const [isStudyPlan, setIsStudyPlan] = useState(false)

  const params = useParams()
  const history = useHistory()
  const breakpoint = Grid.useBreakpoint()

  const [stepLabels, setStepLabels] = useState([])
  const [stepSelectedLabel, setStepSelectedLabel] = useState([])
  const [stepSelected, setStepSelected] = useState([])
  const [studyPlanType, setStudyPlanType] = useState(null)

  const { idStudyPlan, id } = params

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [redirectPlayer, setRedirectPlayer] = useState(false)

  const [paramUrl, setParamUrl] = useState(id)
  const { verifyUserDataInSubscription } = auth

  const showModal = (buttonClick) => {
    if (verifyUserDataInSubscription && buttonClick) {
      notification.info(
        messageNotificationVerifyUserDataInSubscription(),
        '/minha-conta',
      )
    } else {
      setIsModalVisible(true)
    }
  }

  const handleCancel = () => {
    if (redirectPlayer === true) {
      history.push(isStudyPlan ? '/painel' : '/disciplina')
    } else {
      setIsModalVisible(false)
    }
  }

  const myPromise = (data) =>
    new Promise(function (resolve, reject) {
      if (data.steps) {
        const i = 0

        data.steps.forEach((item, i) => {
          setStepLabels((stepLabels) => [
            ...stepLabels,
            { label: item.label, id: item.id },
          ])

          item.daysOfWeek.forEach((valor, j) => {
            valor.lessons.forEach((lesson, l) => {
              if (lesson.id === parseInt(paramUrl)) {
                setStepSelectedLabel(item.label)
                setStepSelected(item)
              }
            })
          })
          i = 1

          item.daysOfWeek.forEach((day) => {
            day.lessons.forEach((obj) => {
              if (obj.id.toString() === id && !obj.canAccessLesson) {
                setRedirectPlayer(true)

                showModal()
              } else if (obj.id.toString() === id) {
                resolve()
              }
            })
          })
        })
      } else if (data.subjects) {
        let i = 0

        data.subjects.forEach((subject) => {
          setStepLabels((stepLabels) => [
            ...stepLabels,
            { label: subject.name, id: subject.disciplineSubjectId },
          ])

          subject.lessons.forEach((valorSub, s) => {
            if (valorSub.disciplineLessonId == parseInt(paramUrl)) {
              setStepSelectedLabel(subject.name)
              setStepSelected(subject)
            }
          })

          i = 1
          subject.lessons.forEach((obj) => {
            if (obj.disciplineLessonId.toString() === id && !obj.canAccess) {
              reject()
            } else if (obj.disciplineLessonId.toString() === id) {
              resolve()
            }
          })
        })
      }
    })

  const SelectStep = (stepID) => {
    if (studyPlanType === 1) {
      setStepSelected(treeView.steps.find((x) => x.id.toString() === stepID))
    } else {
      setStepSelected(
        treeView.subjects.find(
          (x) => x.disciplineSubjectId.toString() === stepID,
        ),
      )
    }
  }

  useEffect(() => {
    const isStudyPlan = localStorage.getItem('isStudyPlan')
    setIsStudyPlan(isStudyPlan === 'true')

    return () => {
      localStorage.removeItem('isStudyPlan')
    }
  }, [])

  useEffect(() => {
    async function getById() {
      setLesson('')

      const response = await services.getById(idStudyPlan, id)
      setLoadingLesson(false)
      setLesson(response.data)
    }

    async function getTreeView() {
      const plantype = await services.getStudPlanType(idStudyPlan)

      setStudyPlanType(plantype.data)

      const response =
        plantype.data === 1
          ? await services.getPlanTreeViewById(idStudyPlan, '')
          : await services.getDiscipline(id)

      const { data } = response

      try {
        await myPromise(data)

        setTreeview(data)
      } catch (err) {
        notification.error('Aula indisponível')
        history.push(isStudyPlan ? '/painel' : '/disciplina')
      }

      try {
        getById()
      } catch (err) {
        history.push(isStudyPlan ? '/painel' : '/disciplina')
      }
    }

    getTreeView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idStudyPlan, id])

  useEffect(() => {
    setCollapsed(false)

    if (breakpoint.xs) setCollapsed(true)
  }, [breakpoint.xs])

  function onPlay() {
    services.start(idStudyPlan, id)
  }

  function onPause(time) {
    services.pause(idStudyPlan, id, time.seconds)
  }

  function onEnd() {
    services.finish(idStudyPlan, id)
  }

  function previousLesson() {
    setLoadingLesson(true)
    history.push(`/${idStudyPlan}/aula/${lesson.previousLessonId}`)
  }

  function nextLesson() {
    setLoadingLesson(true)
    history.push(`/${idStudyPlan}/aula/${lesson.nextLessonId}`)
  }

  function handleStepLessonsMenu(lessonId) {
    const url = `/${idStudyPlan}/aula/${lessonId}`
    const { pathname } = history.location

    if (url !== pathname) setLoadingLesson(true)

    history.push(url)
  }

  function onClick1(props) {
    setStepSelectedLabel(props.item.props.label)
    SelectStep(props.key)
  }

  function menu() {
    return (
      <Menu onClick={onClick1}>
        {stepLabels?.map((item) => (
          <Menu.Item key={item.id} label={item.label}>
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  return (
    <S.StyledLayout>
      <AntdLayout.Header
        style={{ width: '100%', display: 'flex', alignItems: 'center' }}
      >
        <Link style={{ flex: 1 }} to={isStudyPlan ? '/painel' : '/disciplina'}>
          <Box>
            <Box mb="8px">
              <img
                src={imgCorujaTop}
                width={collapsed ? 28 : 45}
                alt="Imagem Logo Coruja"
              />
            </Box>
          </Box>
        </Link>
        <DefaultSearchcomponent style={{ flex: 1 }} />
        <DefaultUserComponent />
      </AntdLayout.Header>

      <S.StyledLayout style={{ width: '100%' }}>
        <S.WrapperMain style={{ width: collapsed ? '100%' : '73%' }}>
          <S.StyledLayout>
            <Content>
              <Box ml="auto" mr="auto" mw="960px">
                <Tooltip title="Menu de Aulas" placement="right">
                  <Button
                    type="primary"
                    shape="circle"
                    icon={collapsed ? <LeftOutlined /> : <RightOutlined />}
                    onClick={() => setCollapsed((collapsed) => !collapsed)}
                    className="button-menu-classes"
                    style={{ padding: 0, color: 'white', width: '32px' }}
                  />
                </Tooltip>
                <LessonsHandlerComponent
                  loadingLesson={loadingLesson}
                  lesson={lesson}
                  onPlay={onPlay}
                  onPause={onPause}
                  onEnd={onEnd}
                  previousLesson={previousLesson}
                  nextLesson={nextLesson}
                  idLesson={id}
                  idStudyPlan={idStudyPlan}
                />
              </Box>
            </Content>
          </S.StyledLayout>
        </S.WrapperMain>
        <div style={{ maxWidth: collapsed ? '0' : '25%' }}>
          <Sidebar
            width={400}
            visible={visible}
            setVisible={setVisible}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            style={{
              display: 'block',
              fontFamily: 'Futura PT',
              height: '100%',
            }}
          >
            <AntdLayout.Sider
              width={400}
              height="100%"
              collapsedWidth={0}
              style={{
                background: 'rgb(242 242 242 / 0.73)',
                fontFamily: 'Futura PT',
                height: '100%',
              }}
            >
              {!!breakpoint.xs && <Box h="80px" />}

              <Box
                mh="80px"
                style={{
                  borderBottom: '2px solid #b4b4b4',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '2rem',
                  paddingRight: '2rem',
                  justifyContent: 'space-between',
                }}
              >
                {studyPlanType === 1 && (
                  <Text fontSize="20px" fontWeight="bold">
                    {lesson?.studyPlanName}
                  </Text>
                )}

                {studyPlanType === 2 && (
                  <Text fontSize="20px" fontWeight="bold">
                    {lesson?.disciplineName}
                  </Text>
                )}

                <Link to={isStudyPlan ? '/painel' : '/disciplina'}>
                  {' '}
                  <Button
                    type="primary"
                    shape="circle"
                    style={{
                      backgroundColor: '#403edc',
                      borderColor: '#403edc',
                      color: '#fff',
                    }}
                  >
                    Seus Planos
                  </Button>
                </Link>
              </Box>

              <Box
                mh="80px"
                style={{
                  borderBottom: '2px solid #b4b4b4',
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '2rem',
                }}
              >
                <Dropdown overlay={menu}>
                  <div
                    className="ant-dropdown-link"
                    style={{
                      color: 'rgba(0, 0, 0, 0.85)',
                      fontSize: '1.3rem',
                      fontFamily: 'Futura PT',
                    }}
                  >
                    <DownOutlined /> {stepSelectedLabel} <DownSquareOutlined />
                  </div>
                </Dropdown>
              </Box>

              {!treeView && studyPlanType !== 1 && (
                <Box pl="8px" pr="8px">
                  <Skeleton active />
                </Box>
              )}

              {treeView && studyPlanType === 1 ? (
                <Box key={stepSelected?.label} mh="80px">
                  <Box mt="16px" ml="14px">
                    {stepSelected?.daysOfWeek?.map((days, index) => (
                      <Collapse
                        defaultActiveKey={days?.lessons?.map((valor, i) => {
                          if (valor.id === parseInt(paramUrl)) {
                            return 0
                          }
                        })}
                        key={index}
                        ghost
                        expandIcon={(expandIcon) => (
                          <LessonsExpandComponent
                            isActive={expandIcon.isActive}
                          />
                        )}
                      >
                        <S.LessonsCollapsePanel
                          header={
                            <Box
                              style={{
                                justifyContent: 'space-between',
                                width: '100%',
                                display: 'flex',
                                borderBottom: '2px solid #DEDEDE',
                                paddingRight: '2rem',
                              }}
                            >
                              <Text
                                fontSize="1.1rem"
                                fontWeight="bold"
                                color="#434343"
                              >
                                {days.dayOfWeekFull}
                              </Text>
                              <Text fontSize="0.8rem" color="#434343">
                                {days.totalLessonsDone}/{days.totalLessons}{' '}
                                aulas assistidas
                              </Text>
                            </Box>
                          }
                        >
                          {days.lessons?.map((lesson) => (
                            <>
                              <Box
                                key={lesson.id}
                                pt="14px"
                                pb="14px"
                                style={{
                                  borderBottom: '2px solid #DEDEDE',
                                  alignItems: 'center',
                                  display: 'flex',
                                  fontFamily: 'Open Sans',
                                }}
                              >
                                {(stepSelected.studyPlanStepTypeId === 3 ||
                                  (stepSelected.studyPlanStepTypeId === 2 &&
                                    !stepSelected.isLastStepDone)) &&
                                  lesson.canAccessLesson && (
                                    <>
                                      <S.LessonsLockOutlinedIcon
                                        fontSize="0.8rem"
                                        color="#939393"
                                      />
                                      <Text
                                        fontSize="0.8rem"
                                        color={
                                          lesson.done ? '#939393' : '#434343'
                                        }
                                        disabled
                                        style={{ marginLeft: '14px' }}
                                        fontWeight={
                                          lesson.id === parseInt(id, 10) &&
                                          'bold'
                                        }
                                      >
                                        {lesson.title}
                                      </Text>
                                    </>
                                  )}

                                {(stepSelected.studyPlanStepTypeId === 1 ||
                                  (stepSelected.studyPlanStepTypeId === 2 &&
                                    stepSelected.isLastStepDone)) &&
                                  lesson.canAccessLesson &&
                                  !lesson.done && (
                                    <>
                                      <FiberManualRecordOutlinedIcon
                                        style={{ marginRight: '14px' }}
                                      />
                                      <Text
                                        onClick={() =>
                                          handleStepLessonsMenu(lesson.id)
                                        }
                                        style={{ cursor: 'pointer' }}
                                        fontSize="0.8rem"
                                        color="#434343"
                                        fontWeight={
                                          lesson.id === parseInt(id, 10) &&
                                          'bold'
                                        }
                                      >
                                        {lesson.title}
                                      </Text>
                                    </>
                                  )}

                                {(stepSelected.studyPlanStepTypeId === 1 ||
                                  (stepSelected.studyPlanStepTypeId === 2 &&
                                    stepSelected.isLastStepDone)) &&
                                  lesson.canAccessLesson &&
                                  lesson.done && (
                                    <>
                                      <CheckCircleIcon
                                        style={{
                                          color: '#5fcc58',
                                          marginRight: '14px',
                                        }}
                                      />
                                      <Text
                                        onClick={() =>
                                          handleStepLessonsMenu(lesson.id)
                                        }
                                        style={{ cursor: 'pointer' }}
                                        fontSize="0.8rem"
                                        color="#434343"
                                        fontWeight={
                                          lesson.id === parseInt(id, 10) &&
                                          'bold'
                                        }
                                      >
                                        {lesson.title}
                                      </Text>
                                    </>
                                  )}

                                {!lesson.canAccessLesson && (
                                  <>
                                    <S.LessonsLockOutlinedIcon
                                      fontSize="0.8rem"
                                      color="#939393"
                                    />
                                    <Text
                                      fontSize="0.8rem"
                                      color={
                                        lesson.done ? '#939393' : '#434343'
                                      }
                                      disabled
                                      style={{ marginLeft: '14px' }}
                                    >
                                      {lesson.title} {lesson.done}
                                    </Text>
                                  </>
                                )}
                              </Box>
                            </>
                          ))}
                        </S.LessonsCollapsePanel>
                      </Collapse>
                    ))}
                  </Box>
                </Box>
              ) : (
                <>
                  {stepSelected?.lessons?.map((lesson, index) => (
                    <>
                      <Box
                        key={index}
                        pb="14px"
                        pt="14px"
                        style={{
                          borderBottom: '2px solid #DEDEDE',
                          display: 'flex',
                          alignItems: 'center',
                          fontFamily: 'Open Sans',
                          paddingLeft: '14px',
                        }}
                      >
                        {lesson.canAccess && !lesson.done && (
                          <>
                            <FiberManualRecordOutlinedIcon
                              style={{ marginRight: '14px' }}
                            />

                            <Text
                              onClick={() =>
                                handleStepLessonsMenu(lesson.disciplineLessonId)
                              }
                              style={{ cursor: 'pointer' }}
                              fontSize="0.8rem"
                              color="#434343"
                              fontWeight={
                                lesson.disciplineLessonId ===
                                  parseInt(id, 10) && 'bold'
                              }
                            >
                              {lesson.name}
                            </Text>
                          </>
                        )}

                        {lesson.canAccess && lesson.done && (
                          <>
                            <CheckCircleIcon
                              style={{ color: '#5fcc58', marginRight: '14px' }}
                            />
                            <Text
                              onClick={() => handleStepLessonsMenu(lesson.id)}
                              style={{ cursor: 'pointer' }}
                              fontSize="0.8rem"
                              color="#434343"
                              fontWeight={
                                lesson.disciplineLessonId ===
                                  parseInt(id, 10) && 'bold'
                              }
                            >
                              {lesson.name}
                            </Text>
                          </>
                        )}

                        {!lesson.canAccess && (
                          <>
                            <S.LessonsLockOutlinedIcon
                              fontSize="0.8rem"
                              color="#939393"
                            />
                            <Text
                              fontSize="0.8rem"
                              color={lesson.done ? '#939393' : '#434343'}
                              disabled
                              style={{ marginLeft: '14px' }}
                            >
                              {lesson.name} {lesson.done}
                            </Text>
                          </>
                        )}
                      </Box>
                    </>
                  ))}
                </>
              )}

              {auth.user.studentFreemium && (
                <Col style={{ textAlign: 'center', marginTop: '2rem' }}>
                  <Button
                    height="44px"
                    borderColor="#403edc"
                    fontSize="18px"
                    fontWeight="bold"
                    color="#403edc"
                    onClick={() => showModal('buttonClick')}
                    index={2}
                  >
                    Assine Agora
                  </Button>
                </Col>
              )}
            </AntdLayout.Sider>
          </Sidebar>

          <Dialog
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
            maxWidth="sm"
            onClose={handleCancel}
            open={isModalVisible}
          >
            <ModalPlansSelection />
          </Dialog>
        </div>
      </S.StyledLayout>
    </S.StyledLayout>
  )
}

export default LessonsContainer
