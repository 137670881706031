import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Skeleton, Layout as AntdLayout } from 'antd'
import { LeftOutlined, DownOutlined } from '@ant-design/icons'
import Layout from '~/shared/Layout'
import Content from '~/shared/Content'
import Box from '~/shared/Box'
import { Text } from '~/shared/Typography'
import services from '../lessons-questions.services'
import servicesLesson from '~/pages/lessons/lessons.services'
import useAuth from '~/hooks/useAuth'
import ComponentQuestionsLessons from '../components/lessons-questions.component'
import * as S from '../lessons-questions.styles'

function LessonsQuestionsContainer() {
  const auth = useAuth()
  const params = useParams()
  const [loadData, setLoadingData] = useState(true)
  const { idStudyPlan, id } = params
  const [isMobile, setIsMobile] = useState(false)
  const [questions, setQuestions] = useState([])
  const [lesson, setLesson] = useState({})

  async function getQuestions(idStudyPlan, id) {
    try {
      const response = await services.getQuestions(idStudyPlan, id)
      const exercises = response.data
      setQuestions(exercises)
    } catch {
      // Console.Log
    }
  }

  async function getByIdLesson(idStudyPlan, id) {
    const response = await servicesLesson.getById(idStudyPlan, id)
    setLesson(response.data)
  }

  useEffect(() => {
    setLoadingData(true)
    getQuestions(idStudyPlan, id)
    getByIdLesson(idStudyPlan, id)
    setLoadingData(false)
  }, [idStudyPlan, id])

  useEffect(() => {
    if (window.location.search !== '') {
      const parameters = new URLSearchParams(window.location.search)
      if (parameters) {
        setIsMobile(Boolean(parameters.get('isMb')))
      }
    }
  }, [])

  if (loadData) return <Skeleton active />

  return (
    <S.StyledLayout>
      {isMobile === false ? (
        <AntdLayout.Header
          style={{
            marginBottom: 48,
            height: 80,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Link to={`/${idStudyPlan}/aula/${id}`}>
            <Text style={{ fontSize: 16, color: '#fff', fontWeight: 'bold' }}>
              <LeftOutlined style={{ marginRight: 8 }} />
              Voltar
            </Text>
          </Link>

          <Text style={{ fontSize: 16, color: '#fff', fontWeight: 'bold ' }}>
            {auth.user.name.split(' ')[0]}
          </Text>
        </AntdLayout.Header>
      ) : (
        ''
      )}

      <Content>
        <Box ml="auto" mr="auto" mw="960px">
          <ComponentQuestionsLessons
            questions={questions}
            lesson={lesson}
            isMobile={isMobile}
          />
        </Box>
      </Content>
    </S.StyledLayout>
  )
}

export default LessonsQuestionsContainer
