import styled, { css } from 'styled-components'
import { lighten } from 'polished'

const GoogleButton = styled.div`
  ${({ theme }) => css`
    width: ${theme.width.large};
    height: 50px;
    background-color: ${theme.colors.googleButton};
    color: #FFF;
    border-radius: 40px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
    margin-top: 10px;
    font-weight: normal;
    font-size: 18px;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    gap: 5rem;
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
      background: ${lighten(0.1, theme.colors.googleButton)};
    }
    svg {
      height: 28px;
      width: 28px;
    }
  `}
`

export { GoogleButton }
