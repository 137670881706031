import {
  CalendarOutlined,
  ContainerOutlined,
  CreditCardOutlined,
  FileTextOutlined,
  LaptopOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from '@ant-design/icons'
import Dialog from '@material-ui/core/Dialog'
import { Skeleton, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import imgPerfil from '~/assets/img/perfil.jpeg'
import imgCorujaTop from '~/assets/img/topo.png'
import useAuth from '~/hooks/useAuth'
import useData from '~/pages/subscription/hooks/useData'
import * as S from '~/pages/_layouts/default/default.styles'
import Box from '~/shared/Box'
import Button from '~/shared/Button'
import Content from '~/shared/Content'
import Footer from '~/shared/Footer'
import Header from '~/shared/Header'
import Layout from '~/shared/Layout'
import Sidebar from '~/shared/Sidebar'
import { Text } from '~/shared/Typography'
import ModalPlansSelection from '../../../modalPlans/components/modalPlans.component'
import services from '../default.services'
import DefaultMenuComponent from './components/default-menu.component'
import DefaultSearchcomponent from './components/default-search.component'
import DefaultUserComponent from './components/default-user.component'

function DefaultContainer(props) {
  const [visible, setVisible] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const [teachingMaterialHidden, setTeachingMaterialHidden] = useState(true)
  const [loading, setLoading] = useState(false)

  const [isMobile, setIsMobile] = useState(false)
  const [localStorageCheck, setLocalStorageCheck] = useState()

  const auth = useAuth()
  const history = useHistory()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const { children } = props

  const { getShiftsPlans, allowLiveFaq } = useData()

  useEffect(() => {
    const isMbGet = localStorage.getItem('isMb')
    setLocalStorageCheck(isMbGet)

    if (localStorageCheck) setIsMobile(localStorageCheck)
  }, [localStorageCheck])

  useEffect(() => {
    async function getTeachingMaterialPermission() {
      setLoading(true)
      const response = await services.getPermission()

      if (response.data.includeTeachingMaterial)
        setTeachingMaterialHidden(false)

      setLoading(false)
    }

    getTeachingMaterialPermission()
    getShiftsPlans()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const siderItems = [
    {
      icon: CalendarOutlined,
      label: 'Painel de Estudante',
      link: '/painel',
      hidden: false,
    },
    {
      icon: CreditCardOutlined,
      label: 'Assinatura',
      link: '/extrato-assinatura',
      hidden: false,
    },
    {
      icon: UserOutlined,
      label: 'Dados Pessoais',
      link: '/minha-conta',
      hidden: false,
    },
    {
      icon: ContainerOutlined,
      label: 'Simulados',
      link: '/simulado',
      hidden: false,
    },
    {
      icon: QuestionCircleOutlined,
      label: 'Dúvidas Postadas',
      link: '/duvidas',
      hidden: false,
    },
    {
      icon: FileTextOutlined,
      label: 'Minhas Redações',
      link: '/redacoes',
      hidden: false,
      blockedFreemium: auth.user.studentFreemium,
    },
    {
      icon: LaptopOutlined,
      label: 'Plantões',
      link: '/plantoes',
      hidden: !allowLiveFaq,
      blockedFreemium: auth.user.studentFreemium,
    },
    {
      icon: ContainerOutlined,
      label: 'Materiais Didáticos',
      link: '/material-didatico',
      hidden: teachingMaterialHidden,
    },
    {
      icon: CalendarOutlined,
      label: 'Aulas Particulares',
      link: '/aulas-particulares',
      hidden: false,
    },
    // {
    //   icon: CalendarOutlined,
    //   label: 'Mentoria',
    //   link: '/mentoria',
    //   hidden: false,
    // },
  ]

  return (
    <Layout>
      {isMobile === false && (
        <Sidebar
          visible={visible}
          setVisible={setVisible}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        >
          <Box
            display="flex"
            align="center"
            justify="center"
            h="100px"
            padding="16px"
            borderBottom="1px solid #ededee"
          >
            <Box mb="8px">
              <img
                src={imgCorujaTop}
                width={collapsed ? 28 : 45}
                alt="Imagem Logo Coruja"
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            align="center"
            justify="center"
            p="8px"
          >
            <Box mb="8px">
              <img
                src={imgPerfil}
                width={collapsed ? 60 : 120}
                alt="Imagem Perfil"
              />
            </Box>

            <S.StudentName className={collapsed ? 'hidden' : ''}>
              <Text
                fontSize={collapsed ? '14px' : '22px'}
                fontWeight="bold"
                color="#545454"
              >
                {auth.user.name.split(' ')[0]}
              </Text>
            </S.StudentName>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            align={collapsed ? 'center' : ''}
            m="0 auto"
            w="200px"
            mw="100%"
          >
            {loading ? (
              <Skeleton active />
            ) : (
              siderItems
                .filter((siderItem) => !siderItem.hidden)
                .map((siderItem) => {
                  const Icon = siderItem.icon
                  const { label } = siderItem
                  return (
                    <Box
                      key={siderItem.label}
                      pt="8px"
                      pb="8px"
                      style={{ borderBottom: '1px solid rgb(246 246 246)' }}
                    >
                      <Text
                        fontFamily="Open Sans"
                        color="#858585"
                        style={
                          siderItem.blockedFreemium && {
                            display: 'flex',
                            alignItems: 'center',
                          }
                        }
                      >
                        {!collapsed && <Icon style={{ marginRight: 8 }} />}

                        {collapsed && (
                          <Tooltip title={label} placement="right">
                            <Icon style={{ marginRight: 8 }} />
                          </Tooltip>
                        )}

                        {siderItem.blockedFreemium ? (
                          <div
                            style={{
                              color: '#858585',
                              fontFamily: 'Open Sans',
                              cursor: 'pointer',
                            }}
                            onClick={() => showModal()}
                            aria-hidden="true"
                          >
                            {!collapsed && siderItem.label}
                          </div>
                        ) : (
                          <Link
                            to={siderItem.link}
                            style={{
                              color: '#858585',
                              fontFamily: 'Open Sans',
                            }}
                          >
                            {!collapsed && siderItem.label}
                          </Link>
                        )}
                      </Text>
                    </Box>
                  )
                })
            )}

            {!collapsed && (
              <Box mt="24px" style={{ marginBottom: '2rem' }}>
                <Button
                  height="45px"
                  fontSize="18px"
                  fontWeight="bold"
                  backgroundColor="#403edc"
                  color="#fff"
                  onClick={() => {
                    if (!auth.user.studentFreemium) {
                      history.push('/duvidas/tirar')
                    } else {
                      showModal()
                    }
                  }}
                >
                  Tirar uma dúvida
                </Button>
              </Box>
            )}
          </Box>
        </Sidebar>
      )}

      <Dialog
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
        maxWidth="sm"
        onClose={handleCancel}
        open={isModalVisible}
      >
        <ModalPlansSelection />
      </Dialog>

      <Layout>
        {isMobile === false && (
          <Header>
            <DefaultMenuComponent />
            <DefaultSearchcomponent />
            <DefaultUserComponent />
          </Header>
        )}

        <Content>{children}</Content>

        {isMobile === false && <Footer />}
      </Layout>
    </Layout>
  )
}

export default DefaultContainer
