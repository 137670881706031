import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import {
  UploadOutlined,
  DownloadOutlined,
  VideoCameraOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'

import { Tag } from 'antd'

const StudyPlansUploadOutlinedIcon = styled(UploadOutlined)`
  font-size: 20px;
  color: ${(props) => props.color};
`
const StudyPlansDownloadOutlinedIcon = styled(DownloadOutlined)`
  font-size: 20px;
  color: ${(props) => props.color};
`
const StudyPlansVideoCameraOutlinedIcon = styled(VideoCameraOutlined)`
  font-size: 20px;
  color: ${(props) => props.color};
`
const StudyPlansVideoCheckCircleOutlined = styled(CheckCircleOutlined)`
  font-size: 20px;
  color: ${(props) => props.color};
`

const StudyPlansTag = styled(Tag)`
  border-radius: 20px;
  padding: 10px;
  background-color: ${(props) => props.backgroundColor};
  color: #fff;
  text-align: center;
  cursor: ${(props) => props.cursor};
`

const EssayWrapper = styled.div`
  ${({ theme }) => css`
    .download-essay {
      padding: ${theme.empty} !important;
    }

    ${media.lessThan('medium')`
      .download-essay {
        display: none !important;
      }
    `}
  `}
`

export {
  StudyPlansUploadOutlinedIcon,
  StudyPlansDownloadOutlinedIcon,
  StudyPlansVideoCameraOutlinedIcon,
  StudyPlansVideoCheckCircleOutlined,
  StudyPlansTag,
  EssayWrapper,
}
