import styled from 'styled-components'
import { Button } from 'antd'

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 80px;
  overflow-y: scroll;

  @media (max-width: 575px) {
    padding: 50px 20px;
    overflow-y: scroll;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const SelectContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 33px;

  @media (max-width: 575px) {
    flex-direction: column;
  }
`

const CombosContainer = styled.ul`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 0;
  gap: 22px;
`

const BigText = styled.h1`
  max-width: 444px;
  text-align: center;
  font-family: Futura PT;
  font-size: ${(props) => props.theme.font.sizes.xsmall};
  font-weight: bold;
  letter-spacing: 0px;
  color: #404040;
  margin: 0;
`

const MediumText = styled.p`
  text-align: center;
  font-family: Futura PT;
  font-size: ${(props) => props.theme.font.sizes.xxsmall};
  font-weight: bold;
  letter-spacing: 0px;
  color: #404040;
  margin: 0;
`

const SmallText = styled.p`
  text-align: center;
  font-family: Open Sans;
  font-size: ${(props) => props.theme.font.sizes.xxxsmall};
  font-weight: ${(props) => (props.isBold ? 'bold' : 500)};
  letter-spacing: 0px;
  color: #404040;
  margin: 0;
`

const EmptySpace = styled.div`
  min-height: 35px;
  height: 35px;
`

const SelectButton = styled(Button)`
  background-color: ${(props) =>
    props.isSelected ? '#4f00e4' : props.theme.colors.white};
  border: 1px solid
    ${(props) => (props.isSelected ? props.theme.colors.white : '#d1d1d1')};
  font: normal normal bold 18px/24px Open Sans;
  color: ${(props) =>
    props.isSelected ? props.theme.colors.white : '#404040'};
`

const CardContainer = styled.li`
  flex: 0 0 225px;
  height: 225px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  overflow: hidden;
`

const InfoCardContent = styled.div`
  width: 100%;
  height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f8f8f8;
  border-bottom: 1px solid #cecece;
`

const AmountCardContent = styled.div`
  flex: 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ButtonCard = styled(Button)`
  width: 100%;
  height: 51px;
  background-color: #4f00e4;
  border-color: transparent;
`

export {
  Container,
  Header,
  SelectContainer,
  CombosContainer,
  BigText,
  MediumText,
  SmallText,
  EmptySpace,
  SelectButton,
  CardContainer,
  InfoCardContent,
  AmountCardContent,
  ButtonCard,
}
