import StudyPlansContainer from './containers/study-plans.container'

const routes = [
  {
    path: '/painel',
    component: StudyPlansContainer,
    authenticated: true,
  },
]

export default routes
