import DisciplinesContainer from './containers/disciplines.container'

const routes = [
  {
    path: '/disciplina/:discipline?',
    component: DisciplinesContainer,
    authenticated: true,
  },
]

export default routes
