import React, { useState } from 'react'
import { Row, Col } from 'react-flexbox-grid'

import useData from '~/pages/subscription/hooks/useData'
import LoaderButton from '~/shared/LoaderButton'

import { useHistory } from 'react-router-dom'

import * as S from './styles'

function StepsButton(props) {
  const { validateInputs, currentStep, previousStep, nextStep, style } = props

  const history = useHistory()

  const {
    checkoutFinishStep,
    studentSubscriptionCouponCheck,
    setStudentSubscriptionCouponCheck,
    storageStudentSubscriptionCouponCode,
  } = useData()

  setStudentSubscriptionCouponCheck(false)

  function previous(event) {
    event.preventDefault()
    setStudentSubscriptionCouponCheck(false)
   // previousStep()
    history.push('/renovacao')
  }

  async function next(event) {
    event.preventDefault()

    setStudentSubscriptionCouponCheck(false)
    if (!studentSubscriptionCouponCheck && 
        storageStudentSubscriptionCouponCode) {
          setStudentSubscriptionCouponCheck(true)
           
    }  
  
     if (await validateInputs()) {
       if (
         !studentSubscriptionCouponCheck &&
         storageStudentSubscriptionCouponCode
       )
         setStudentSubscriptionCouponCheck(true)

      nextStep()
    }
  }

  return (
    <S.StepsButtonWrapper>
      <Row end="xs" style={style}>
        <Col xs={6} md={2}>
          <S.StepsButtonPrevious
            disabled={currentStep === 1}
            onClick={previous}
          >
            Voltar
          </S.StepsButtonPrevious>
        </Col>

        <Col xs={6} md={3} className={checkoutFinishStep && 'loading-active'}>
            {currentStep === 2 ? (
              <S.StepsButtonNext type="submit" disabled={checkoutFinishStep}>
                {checkoutFinishStep && <LoaderButton />} Finalizar Compra
              </S.StepsButtonNext>
            ) : (
              <S.StepsButtonNext onClick={next}>Avançar</S.StepsButtonNext>
            )}
        </Col>
      </Row>
    </S.StepsButtonWrapper>
  )
}

export default StepsButton
