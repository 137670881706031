import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { Collapse, Select as AntdSelect, Tag } from 'antd'
import {
  UpOutlined,
  DownOutlined,
  CheckCircleFilled,
  LockOutlined,
} from '@ant-design/icons'

const StudyPlansCollapsePanel = styled(Collapse.Panel)`
  && {
    .ant-collapse-header {
      padding-left: 0;
    }

    .ant-collapse-content-box {
      padding: 0;
    }
  }
`

const StudyPlansTag = styled(Tag)`
  width: 48px;
  margin-left: 8px;
  margin-right: 8px;

  border-radius: 20px;
  background-color: ${(props) => props.backgroundColor};
  color: #fff;
  text-align: center;
`

const StudyPlansUpOutlinedIcon = styled(UpOutlined)`
  position: absolute;
  top: 16px;

  font-size: 20px;
`

const StudyPlansDownOutlinedIcon = styled(DownOutlined)`
  position: absolute;
  top: 16px;

  font-size: 20px;
`

const StudyPlansCheckOutlinedIcon = styled(CheckCircleFilled)`
  font-size: 20px;
  color: ${(props) => props.color};
`

const StudyPlansLockOutlinedIcon = styled(LockOutlined)`
  font-size: 20px;
  color: ${(props) => props.color};
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${media.lessThan('medium')`
      .position-unset {
        position: unset;
        width: ${theme.width.large};
      }

      .go-next-week {
        width: ${theme.width.large};
        display: flex;
        flex-direction: column-reverse;
      }

      .go-next-week .select-go-next-week {
        padding-top: ${theme.empty} !important;
        width: ${theme.width.large} !important;
      }

      .go-next-week
        .ant-col.ant-col-xs-24.ant-col-md-24
        .ant-row.ant-row-end.ant-row-middle {
        margin-bottom: ${theme.empty} !important;
      }

      .study-plans-view-action {
        position: absolute;
        top: 100px;
        right: 0;
        padding-left: ${theme.padding.small} !important;
        padding-bottom: ${theme.empty} !important;
      }
    `}

    ${media.between('medium', '1024px')`
      .position-unset,
      .go-next-week .select-go-next-week,
      .go-next-week
        .ant-col.ant-col-xs-24.ant-col-md-24
        .ant-row.ant-row-end.ant-row-middle .ant-col-xxl-22 {
        padding-right: ${theme.empty} !important;
        max-width: ${theme.width.large} !important;
      }

      .go-next-week
        .ant-col.ant-col-xs-24.ant-col-md-24
        .ant-row.ant-row-end.ant-row-middle {
        width: ${theme.width.large} !important;
        display: block;
      }

      .study-plans-view-action {
        top: 110px;
      }
    `}
    }
  `}
`

const Select = styled(AntdSelect)`
  ${({ theme }) => css`
    .ant-select-selection-item {
      display: flex;
      justify-content: center;
      font-weight: bold;
    }

    .ant-select-selector {
      width: 175px !important;
      border-radius: 50px !important;
    }

    &.box-select {
      padding: 3px 20px 3px 10px;
      border: 1px solid #e8e8e8;
      border-radius: 40px;
      box-shadow: 0px 6px 6px #0000000a;
      background: ${theme.colors.white};
    }
  `}
`

export {
  StudyPlansCollapsePanel,
  StudyPlansTag,
  StudyPlansUpOutlinedIcon,
  StudyPlansDownOutlinedIcon,
  StudyPlansCheckOutlinedIcon,
  StudyPlansLockOutlinedIcon,
  Wrapper,
  Select,
}
