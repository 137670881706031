import SubscriptionContainer from './containers/subscription-plans.container'
import CheckoutContainer from './containers/checkout.container'
import TermsConditionsContainer from './containers/terms-conditions.container'
import DetailsContainer from './containers/details.container'

const routes = [
  {
    path: '/renovacao',
    component: SubscriptionContainer,
    authenticated: true,
    external: true,
    subscription: true,
    routeBack: '/extrato-assinatura',
  },
  {
    path: '/renovacao/finalizar-assinatura',
    component: CheckoutContainer,
    authenticated: true,
    external: true,
    routeBack: '/extrato-assinatura',
  },
  {
    path: '/renovacao/termos-condicoes',
    component: TermsConditionsContainer,
    authenticated: true,
    external: true,
    withoutHeader: true,
    subscription: true,
    routeBack: '/extrato-assinatura',
  },
  {
    path: '/renovacao/detalhes-assinatura',
    component: DetailsContainer,
    authenticated: true,
    external: true,
    routeBack: '/extrato-assinatura',
  },
]

export default routes
