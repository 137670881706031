import React, { useState, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

import * as S from './styles'

import ErrorMessage from '../ErrorMessage'

function Input(props) {
  const [type, setType] = useState(props.type)

  const isTypePassword = useRef(type === 'password')

  const { register, errors } = useFormContext()

  const {
    name,
    placeholder,
    maxLength,
    value,
    defaultChecked,
    onChange,
    onBlur,
    onFocus,
    children,
  } = props

  function toggleVisibility() {
    if (type === 'text') setType('password')
    else setType('text')
  }

  return (
    <S.InputWrapper>
      {type === 'radio' || type === 'checkbox' ? (
        <S.InputCheckbox
          type={type}
          name={name}
          defaultChecked={defaultChecked}
          value={value}
          ref={register}
        />
      ) : (
        <S.Input
          type={type || 'text'}
          name={name}
          value={value}
          placeholder={placeholder}
          aria-label={placeholder}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          ref={register}
        />
      )}

      {isTypePassword.current && (
        <S.InputVisilibityWrapper onClick={toggleVisibility}>
          {type === 'password' ? (
            <S.InputVisilibityEye />
          ) : (
            <S.InputVisilibityEyeSlash />
          )}
        </S.InputVisilibityWrapper>
      )}

      {children}

      {errors[name] && <ErrorMessage>{errors[name].message}</ErrorMessage>}
    </S.InputWrapper>
  )
}

export default Input
