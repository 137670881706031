
import subscriptionService from '../pages/extractsignature/extractsignature.services'

const delightedSurvey = async (name, email) =>{ 
  const subscription = await subscriptionService.getStudentSubscription()
  
  window.delighted.survey({
    email: email, // customer email (optional)
    name: name,               // customer name (optional)
    createdAt: new Date().toISOString(),// time of initial visit (optional)
    properties: {                       // extra context (optional)
      plan: subscription.data[0].subscriptionPlanName,      
    }
  });
}
 

export default delightedSurvey