import styled, { css } from 'styled-components'

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};
  `}
`

export { CardWrapper }
