import * as yup from 'yup'
import { cpf as cpfValidator } from 'cpf-cnpj-validator'

const schema = yup.object().shape({
  cpfCheck: yup
    .string()
    .test('cpfValidator', 'CPF inválido.', cpfValidator.isValid),
})

export default schema
