import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import { Skeleton } from 'antd'
import services from '../test.services'
import ComponentQuestionsTest from '../components/test-questions.component'

function TestQuestionsContainer(props) {
  const history = useHistory()
  const [loadData, setLoadingData] = useState(true)
  const [data, setData] = useState({ questions: [] })
  const testId = localStorage.getItem('testId')

  async function getQuestions(id) {
    try {
      const responseStart = await services.startTest({ testId })
      const dataStart = responseStart.data
      const { studentTestId } = dataStart
      const id = studentTestId

      localStorage.setItem('studentTestId', id)
      const response = await services.startedTest(id)
      const { data } = response

      setData(data)
      setLoadingData(false)
    } catch {
      localStorage.removeItem('testId')
      localStorage.removeItem('studentTestId')
      history.push('/simulado')
    }
  }

  useEffect(() => {
    setLoadingData(true)
    getQuestions(testId)
  }, [testId])

  return (
    <Fragment>
      {loadData && <Skeleton active />}
      {!loadData && (
        <ComponentQuestionsTest data={data} testId={testId} {...props} />
      )}
    </Fragment>
  )
}

export default TestQuestionsContainer
