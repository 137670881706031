import styled from 'styled-components'
import { Button } from 'antd'

const EssayWrapper = styled.div`
  width: 100%;
`

const ListContainer = styled.ul`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-top: 33px;

  @media (max-width: 575px) {
    justify-content: center;
  }
`

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonLoadMore = styled(Button)`
  background-color: transparent;
  border: 1px solid #333333;
  margin-bottom: 32px;
`

const CardContainer = styled.li`
  flex: 0 0 325px;
  height: 325px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  margin: 0 40px 40px 0;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 5px 5px 30px #00000015;
  border: 1px solid #e0e0e0;
  border-radius: 20px;

  @media (max-width: 575px) {
    flex: 0 0 310px;
    height: 310px;
    margin: 0 0 40px 0;
  }
`

const AvatarCard = styled.img`
  width: 135px;
  height: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 50%;
  overflow: hidden;
`

const TitleCard = styled.h1`
  text-align: center;
  font-family: Futura PT;
  font-size: ${(props) => props.theme.font.sizes.xxsmall};
  font-weight: bold;
  letter-spacing: 0px;
  color: #404040;
`

const TextCard = styled.p`
  text-align: left;
  font-family: Futura PT;
  font-size: ${(props) => props.theme.font.sizes.xxxsmall};
  font-weight: 500;
  letter-spacing: 0px;
  color: #404040;
`

const ButtonCard = styled(Button)`
  width: 100%;
  background-color: #4f00e4;
  border-color: transparent;
  margin-top: 20px;
`

const ViewTeacherContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid #70707058;
  border-radius: 5px;

  .calendly-inline-widget {
    min-width: 250px !important;
    height: 600px !important;
  }

  @media (max-width: 575px) {
    padding: 20px;
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-bottom: 70px;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`

const TeacherInfo = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }
`

const InfoContent = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  margin: 0 60px 0 24px;

  @media (max-width: 575px) {
    margin: 20px 0 0 0;
    align-items: center;
  }
`

const BioContent = styled.div`
  flex: 1;
  display: flex;
  background-color: #f8f8f8;
  padding: 28px 40px;
`

const BioText = styled.p`
  font: normal normal normal 18px/28px Open Sans;
  letter-spacing: 0px;
  color: #404040;
`

const ContentCalendar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ButtonGoBack = styled(Button)`
  background-color: #4f00e4;
  border-color: transparent;
  margin-bottom: 32px;
`

export {
  EssayWrapper,
  CardContainer,
  AvatarCard,
  TitleCard,
  TextCard,
  ButtonCard,
  ListContainer,
  ViewTeacherContainer,
  Content,
  TeacherInfo,
  InfoContent,
  BioContent,
  BioText,
  ContentCalendar,
  ButtonGoBack,
  ButtonLoadMore,
  Footer,
}
