import styled from 'styled-components'

const ShiftsWrapper = styled.div`
  .card-col .ant-card {
    min-height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-zoom {
    margin-top: 15px;
  }

  @media (max-width: 1550px) {
    .card-col .ant-card {
      min-height: 265px;
    }
  }

  @media (max-width: 768px) {
    .card-col .ant-card {
      min-height: auto;
    }

    .row-alert,
    .ant-row {
      margin-left: 0 !important;
    }

    .ant-row-center {
      margin-bottom: 0 !important;
    }

    .text-bottom {
      margin-bottom: 10px !important;
    }
  }
`

const ChildrenWrapper = styled.div`
  @media (max-width: 768px) {
    .ant-col.ant-col-xs-24 {
      padding: 0 !important;
      margin-bottom: 20px;
    }
  }
`

const InformationShifts = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1550px) {
    display: block;
  }
`

const InformationDate = styled.div`
  margin-left: ${(props) => props.marginLeft};

  .ant-typography {
    display: block;
  }
`

export { ShiftsWrapper, ChildrenWrapper, InformationShifts, InformationDate }
