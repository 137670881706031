import styled from 'styled-components'
import { Menu as AntdMenu, Input, AutoComplete } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

const Menu = styled(AntdMenu)`
  max-width: 100%;
`

const MenuItem = styled(AntdMenu.Item)``

const RoundSearch = styled(AutoComplete)`
  border-radius: 25px;
  width: 350px;
`

const StudentName = styled.div`
  @media (max-width: 1192px) {
    display: none;
  }

  &.hidden {
    display: none;
  }
`

export { Menu, MenuItem, RoundSearch, StudentName }
