import React, { useEffect, useState } from 'react'

import MentorShipComponent from '../components/mentorship.component'
import MentorShipViewComponent from '../components/mentorship-view.component'

const teacherList = []

function MentorShipContainer() {
  const [step, setStep] = useState(1)
  const [selectData, setSelectData] = useState()

  useEffect(() => {
    teacherList.length = 0
  }, [])

  return (
    <>
      {step === 1 && (
        <MentorShipComponent
          teacherList={teacherList}
          setStep={setStep}
          setSelectData={setSelectData}
        />
      )}
      {step === 2 && (
        <MentorShipViewComponent data={selectData} setStep={setStep} />
      )}
    </>
  )
}

export default MentorShipContainer
