import React from 'react'

import { notification } from 'antd'

const notificationMessages = {
  success(description) {
    const config = {
      message: 'Sucesso',
      description,
    }

    notification.success(config)
  },

  info(description, link) {
    const config = {
      message: 'Informação',
      description: (
        <>
          {description} {link ? <a href={link}>clique aqui.</a> : ''}
        </>
      ),
    }

    notification.info(config)
  },

  warning(description) {
    const config = {
      message: 'Atenção',
      description,
    }
    notification.warning(config)
  },

  error(description) {
    const config = {
      message: 'Erro',
      description,
    }

    notification.error(config)
  },
}

export default notificationMessages
