import { useState, useEffect, useCallback } from 'react'

function useLocalStorage(key, defaultValue = null) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const urlParams = new URLSearchParams(window.location.search)

  const [value, setValue] = useState(() => {
    if (urlParams.get(key)) {
      if (key === 'user')
        return {
          name: urlParams.get(key),
        }

      return urlParams.get(key)
    }

    const storagedValue = localStorage.getItem(key)
    return storagedValue ? JSON.parse(storagedValue) : null
  })

  useEffect(() => {
    if (defaultValue) {
      localStorage.setItem(key, JSON.stringify(defaultValue))
      setValue(defaultValue)
    }
  }, [defaultValue, urlParams, key])

  const updateValue = useCallback(
    (newValue) => {
      localStorage.setItem(key, JSON.stringify(newValue))
      setValue(newValue)
    },
    [key],
  )

  const removeValue = useCallback(() => {
    localStorage.removeItem(key)
    setValue(null)
  }, [key])

  return [value, (newValue) => updateValue(newValue), () => removeValue()]
}

export default useLocalStorage
