import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import ExtractSignatureComponent from '../components/extractsignature.component'
import services from '../extractsignature.services'

function ExtractSignatureContainer() {
  const [data, setData] = useState([])
  const [display, setDisplay] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    async function getData() {
      const response = await services.getStudentSubscription()
      const { data } = response
      setData(data)
      setLoading(false)
    }

    async function getDisplay() {
      const response = await services.displayRenew()
      const { data } = response
      setDisplay(data)
      setLoading(false)
    }
    getData()
    getDisplay()
  }, [])
  if (loading) return <Skeleton active />
  return <ExtractSignatureComponent data={data} display={display} />
}

export default ExtractSignatureContainer
