import api from '~/services/api'

const services = {
  getDisciplinesCombobox() {
    return api.get('/student/study-plan/discipline/combobox')
  },
  getDisciplines(filters) {
    const { disciplineId, lessonTypeId, filter } = filters

    return api.get('/student/study-plan/discipline', {
      params: {
        disciplineId,
        lessonTypeId,
        filter,
      },
    })
  },
}

export default services
