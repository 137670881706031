import React, { useState, useEffect } from 'react'
import { Row, Col, Tooltip, Grid, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

import { v4 as uuid } from 'uuid'

import { CreditCard, Upc } from '@styled-icons/bootstrap'
import { InfoCircleOutlined, CopyOutlined } from '@ant-design/icons'
import * as S from '../extractsignature.styles'

import formatCurrency from '../../subscription/utils/format-currency'

import useData from '~/pages/subscription/hooks/useData'

import services from '../extractsignature.services'

import notification from '~/shared/Notification'
import Modal from '~/shared/Modal'

import useAuth from '~/hooks/useAuth'
import messageNotificationVerifyUserDataInSubscription from '~/utils/messageNotificationVerifyUserDataInSubscription'

function ExtractSignatureComponent(props) {
  const { data } = props

  const { useBreakpoint } = Grid
  const screen = useBreakpoint()
  const { md } = screen

  const { Text } = Typography
  const history = useHistory()

  const {
    handleSubscriptionPlanClick,
    setStorageStudentSubscriptionCouponCode,
  } = useData()

  const auth = useAuth()

  const { verifyUserDataInSubscription } = auth

  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    if (redirect) window.location.href = '/extrato-assinatura'
  }, [redirect])

  async function handlePlanId(planId, couponCode) {
    if (verifyUserDataInSubscription)
      notification.info(
        messageNotificationVerifyUserDataInSubscription(),
        '/minha-conta',
      )

    if (!verifyUserDataInSubscription) {
      const response = await services.getStudentSubscriptionId(planId)
      const { data } = response

      setStorageStudentSubscriptionCouponCode(couponCode)
      handleSubscriptionPlanClick(data)
    }
  }

  function messageContentTasting(endsAt) {
    const dateEndsAt = new Date(endsAt).toLocaleDateString('pt-br', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })

    return (
      <>
        <br />
        Após o dia {dateEndsAt} você perderá o acesso às aulas e demais
        conteúdos pagos.
        <br />
        <br />
        De qualquer forma, você poderá assinar novamente a qualquer momento.
      </>
    )
  }

  async function handleTastingCancel(subscriptionNumber, endsAt) {
    Modal.confirmation(
      'Tem certeza que deseja cancelar sua assinatura?',
      async () => {
        try {
          await services.tastingCancel(subscriptionNumber)

          notification.success('Assinatura cancelada com sucesso')

          setTimeout(() => {
            setRedirect(true)
          }, 1100)
        } catch (err) {
          notification.error('Houve algum problema, contate nosso suporte')
          setRedirect(true)
        }
      },
      true,
      messageContentTasting(endsAt),
      'Cancelar',
      'Continuar com minha Assinatura',
    )
  }

  function handleNewSignatureButtonClick() {
    if (!verifyUserDataInSubscription) history.push('/renovacao')

    if (verifyUserDataInSubscription)
      notification.info(
        messageNotificationVerifyUserDataInSubscription(),
        '/minha-conta',
      )
  }

  return (
    <S.DetailsWrapper>
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Col xs={18} md={12} lg={16} xl={16} sm={18}>
          <S.Wrapper lineBottom>Extrato de Assinatura </S.Wrapper>
        </Col>

        <Col xs={12} md={12} lg={4} xl={3} sm={6}>
          <S.DetailsTag
            backgroundColor="#403edc"
            cursor="pointer"
            padding="10px"
            width="170px"
            marginBottom="15px"
            onClick={() => handleNewSignatureButtonClick()}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text style={{ color: '#fff', fontSize: '15px' }}>
              Nova Assinatura
            </Text>
          </S.DetailsTag>
        </Col>
      </Row>

      {data.map((item) => {
        const {
          subscriptionPlanId,
          subscriptionNumber,
          subscriptionPlanName,
          numberOfMonths,
          startsAt,
          endsAt,
          paymentSlipExpirationDate,
          paymentCreditCardInfo,
          paymentInstallments,
          paymentInstallmentAmount,
          paymentStatusMessage,
          paidAmount,
          paymentMethod,
          paymentStatus,
          paymentSlipBarcode,
          paymentSlipUrl,
          discountCouponCode,
          discountCouponAmount,
          canRenew,
          canCancel,
        } = item

        return (
          <S.DetailsCard key={uuid()}>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Col
                xs={24}
                md={10}
                style={{
                  marginBottom: !md ? '20px' : '',
                }}
              >
                <S.Wrapper>Dados assinatura</S.Wrapper>
                {subscriptionNumber && (
                  <S.DetailsInfo>
                    <S.DetailsInfoStrong>
                      Número Assinatura:
                    </S.DetailsInfoStrong>{' '}
                    {subscriptionNumber}
                  </S.DetailsInfo>
                )}
                <S.DetailsInfo>
                  <S.DetailsInfoStrong>Plano Assinatura:</S.DetailsInfoStrong>{' '}
                  {subscriptionPlanName}
                </S.DetailsInfo>
                {paymentStatus && (
                  <S.DetailsInfo>
                    <S.DetailsInfoStrong>Período:</S.DetailsInfoStrong>{' '}
                    {paymentStatus === 'Confirmada'
                      ? `${new Date(startsAt).toLocaleDateString('pt-br', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        })} a ${new Date(endsAt).toLocaleDateString('pt-br', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        })}`
                      : `${numberOfMonths} meses`}
                  </S.DetailsInfo>
                )}

                {!!discountCouponCode && (
                  <S.DetailsInfo>
                    <S.DetailsInfoStrong>
                      Cupom de Desconto:
                    </S.DetailsInfoStrong>{' '}
                    {discountCouponCode}
                  </S.DetailsInfo>
                )}

                {!!discountCouponAmount && (
                  <S.DetailsInfo>
                    <S.DetailsInfoStrong>
                      Valor do Desconto:
                    </S.DetailsInfoStrong>{' '}
                    R$ {formatCurrency(discountCouponAmount)}
                  </S.DetailsInfo>
                )}

                <S.DetailsInfo>
                  <S.DetailsInfoStrong>Valor Pago:</S.DetailsInfoStrong> R$
                  {formatCurrency(paidAmount || '0,00')}
                </S.DetailsInfo>
              </Col>

              <Col xs={24} md={10}>
                <S.Wrapper>Forma de pagamento</S.Wrapper>

                {paymentMethod && paymentMethod === 'Cartão de Crédito' && (
                  <>
                    <S.DetailsInfo>
                      <CreditCard
                        width="1em"
                        height="1em"
                        style={{ marginRight: 8 }}
                      />
                      {paymentCreditCardInfo}
                    </S.DetailsInfo>

                    <S.DetailsInfo>
                      {paymentInstallments}x de R$
                      {formatCurrency(paymentInstallmentAmount)}
                    </S.DetailsInfo>
                  </>
                )}

                {paymentMethod && paymentMethod === 'Boleto' && (
                  <>
                    <S.DetailsInfo
                      cursor="pointer"
                      hover
                      onClick={() => window.open(paymentSlipUrl)}
                    >
                      <Upc
                        width="1.4em"
                        height="1.4em"
                        style={{ marginRight: 4 }}
                      />
                      {paymentMethod}
                    </S.DetailsInfo>

                    {!!paymentSlipExpirationDate && (
                      <S.DetailsInfo>
                        <S.DetailsInfoStrong>
                          Data de Vencimento:{' '}
                        </S.DetailsInfoStrong>
                        {paymentSlipExpirationDate?.replace(
                          /(\d*)-(\d*)-(\d*).*/,
                          '$3/$2/$1',
                        )}
                      </S.DetailsInfo>
                    )}

                    {paidAmount && (
                      <S.DetailsInfo>
                        {' '}
                        <S.DetailsInfoStrong>Valor: </S.DetailsInfoStrong> R$
                        {formatCurrency(paidAmount)}
                      </S.DetailsInfo>
                    )}

                    {paymentStatus === 'Em Processamento' && (
                      <S.DetailsInfo>
                        <S.DetailsInfoStrong>
                          Linha digitável:
                        </S.DetailsInfoStrong>{' '}
                        {paymentSlipBarcode}{' '}
                        <>
                          <Tooltip title="Copiar linha digitável">
                            <CopyOutlined
                              onClick={() => {
                                const textArea = document.createElement(
                                  'textarea',
                                )
                                textArea.value = paymentSlipBarcode
                                document.body.appendChild(textArea)
                                textArea.select()
                                document.execCommand('Copy')
                                textArea.remove()
                              }}
                            />
                          </Tooltip>
                        </>
                      </S.DetailsInfo>
                    )}
                  </>
                )}
                {paymentStatus && (
                  <S.DetailsInfo>
                    <S.DetailsInfoStrong>Status Pagamento:</S.DetailsInfoStrong>{' '}
                    {paymentStatus}{' '}
                    <Tooltip title={paymentStatusMessage}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </S.DetailsInfo>
                )}

                {!!canRenew && (
                  <S.DetailsTag
                    backgroundColor="#403edc"
                    cursor="pointer"
                    padding="10px"
                    width="170px"
                    marginBottom="15px"
                    onClick={() =>
                      handlePlanId(subscriptionPlanId, discountCouponCode)
                    }
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Text style={{ color: '#fff', fontSize: '15px' }}>
                      Renovar
                    </Text>
                  </S.DetailsTag>
                )}

                {!!canCancel && (
                  <S.DetailsTag
                    backgroundColor="#ff4d4f"
                    cursor="pointer"
                    padding="10px"
                    width="170px"
                    marginBottom="15px"
                    onClick={() =>
                      handleTastingCancel(subscriptionNumber, endsAt)
                    }
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Text style={{ color: '#fff', fontSize: '15px' }}>
                      Cancelar
                    </Text>
                  </S.DetailsTag>
                )}
              </Col>
            </Row>
          </S.DetailsCard>
        )
      })}
    </S.DetailsWrapper>
  )
}

export default ExtractSignatureComponent
