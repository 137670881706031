/* eslint-disable react/jsx-props-no-spreading */
import { RightOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import GoogleButton from 'react-google-button'
import { GoogleLogin } from 'react-google-login'
import { FormProvider, useForm } from 'react-hook-form'
import useAuth from '~/hooks/useAuth'
import imageLogo from '~/assets/img/coruja-sabia.svg'
import imageCadastro from '~/assets/img/img_cadastro.png'
import api from '~/services/api'
import notification from '~/shared/Notification'
import {
  Header,
  TextSingIn,
  Input,
  Button,
  InnerButton,
  TextCreatedSingIn,
  ContainerImg,
  ContainerText,
  TextInfo,
  TextSubInfo,
  TextTerms,
} from './styles'

function Register() {
  const auth = useAuth()
  const [firstName, setStudentFirstName] = useState('')
  const [lastName, setStudentLastName] = useState('')
  const [email, setStudentEmail] = useState('')
  const [password, setPassword] = useState('')
  const [dataCompleted, setDateCompleted] = useState(false)

  async function createUser(data) {
    const dataLogin = {
      email: data?.email,
      googleId: data?.googleId,
      password: data?.password,
    }

    const checkEmail = await api.get(
      `/student/check-email?email=${dataLogin?.email}`,
    )

    const { emailExists } = checkEmail?.data

    if (emailExists === true) {
      auth.signIn(dataLogin)
    } else {
      try {
        const createdata = {
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          googleId: data?.googleId,
          password: data?.password,
        }

        await api.post('/student/register/freemium/', createdata)

        notification.success(
          'Cadastro realizado com sucesso, redirecionando para o portal.',
        )

        auth.signIn(dataLogin)
      } catch {
        notification.error('Erro ao finalizar cadastro.')
      }
    }
  }

  const responseGoogle = async (response) => {
    const infos = response?.profileObj

    const dataGoogle = {
      firstName: infos?.givenName,
      lastName: infos?.familyName,
      email: infos?.email,
      googleId: infos?.googleId,
    }

    if (infos?.email) await createUser(dataGoogle)
  }

  useEffect(() => {
    if ((firstName && lastName && email && password) !== '') {
      setDateCompleted(true)

      document.getElementById('btnSubmit').disabled = false
    } else {
      document.getElementById('btnSubmit').disabled = true

      setDateCompleted(false)
    }
  }, [firstName, lastName, email, password])

  const formMethods = useForm({
    mode: 'onBlur',
  })

  async function onSubmit() {
    const dataSubmit = {
      firstName,
      lastName,
      email,
      password,
    }

    createUser(dataSubmit)
  }

  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header md={12} sm={12}>
          <ContainerImg sm={6} md={6}>
            <img
              src={imageLogo}
              style={{ width: 100, height: 100, marginBottom: 10 }}
              alt="Logo Coruja Sábia"
            />
          </ContainerImg>
          <ContainerText sm={6} md={6}>
            <TextSingIn>
              Já tem uma conta?{' '}
              <a
                style={{ color: '#403EDC', fontWeight: 'bold' }}
                href="https://aluno.corujasabia.com.br/login"
              >
                Entre já
              </a>{' '}
            </TextSingIn>
          </ContainerText>
        </Header>

        <Row
          md={12}
          sm={12}
          xs={12}
          style={{ marginRight: 40, marginLeft: 40 }}
        >
          <Col sm={6} md={6}>
            <div style={{ flex: 1 }}>
              <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                <TextCreatedSingIn>Crie sua conta gratuita</TextCreatedSingIn>
              </div>

              <Row md={12} sm={12} xs={12}>
                <Col sm={12} md={12}>
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    render={(renderProps) => (
                      <GoogleButton
                        label="Entre com o Google"
                        style={{
                          width: '100%',
                          borderRadius: '4px',
                          boxShadow: '0 3px 4px 0 rgba(0,0,0,.25)',
                          marginBottom: 40,
                          marginTop: 20,
                        }}
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      />
                    )}
                    back="#4285F4"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy="single_host_origin"
                  />
                </Col>
              </Row>

              <Row md={12} sm={12} xs={12}>
                <Col sm={6} md={6}>
                  <Input
                    name="firstName"
                    placeholder="Nome"
                    onChange={(e) => setStudentFirstName(e.target.value)}
                  />
                </Col>

                <Col sm={6} md={6}>
                  <Input
                    name="lastName"
                    fontSize="10px"
                    placeholder="Sobrenome"
                    onChange={(e) => setStudentLastName(e.target.value)}
                  />
                </Col>
              </Row>

              <Row md={12}>
                <Col sm={12} md={12}>
                  <Input
                    name="email"
                    placeholder="Email"
                    onChange={(e) => setStudentEmail(e.target.value)}
                  />
                </Col>
              </Row>

              <Row md={12}>
                <Col sm={12} md={12}>
                  <Input
                    name="password"
                    placeholder="Senha"
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    maxLength={16}
                  />
                </Col>
              </Row>

              <Row md={12}>
                <Col sm={12} md={12}>
                  <Button
                    id="btnSubmit"
                    type="submit"
                    style={{
                      cursor: dataCompleted ? 'pointer' : '',
                      backgroundColor: dataCompleted ? '#4454CD' : '#EAF0F6',
                    }}
                  >
                    <InnerButton>
                      <span
                        style={{
                          color: dataCompleted ? '#fff' : '#34485C69',
                          fontSize: 16,
                        }}
                      >
                        Cadastre-se
                      </span>
                      <div
                        style={{
                          alignItems: 'center',
                          paddingTop: 5,
                          paddingLeft: 10,
                        }}
                      >
                        <RightOutlined
                          style={{
                            fontSize: 15,
                            color: dataCompleted ? '#fff' : '#34485C69',
                          }}
                        />
                      </div>
                    </InnerButton>
                  </Button>
                </Col>
                <div
                  style={{
                    paddingTop: '18px',
                    marginBottom: 40,
                  }}
                >
                  <TextTerms>
                    Ao continuar, você concorda com o{' '}
                    <b>
                      <a
                        style={{ color: '#000' }}
                        href="https://www.corujasabia.com.br/termos-de-uso/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Termos de Serviço do Cliente do Coruja Sábia{' '}
                      </a>
                    </b>
                    e{' '}
                    <b>
                      <a
                        style={{ color: '#000' }}
                        href="https://www.corujasabia.com.br/politica-de-privacidade/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Política de privacidade.{' '}
                      </a>
                    </b>
                  </TextTerms>
                </div>
              </Row>
            </div>
          </Col>

          <Col md={6} xs={12}>
            <div style={{ textAlign: 'center', marginBottom: 100 }}>
              <img
                src={imageCadastro}
                style={{ width: '60%', height: '35%' }}
                // alt="Imagem de uma mulher na área cadastro do freemium"
                alt=""
              />
              <div style={{ paddingTop: '35px', textAlign: 'center' }}>
                <TextInfo>
                  Sua conta no Coruja Sábia será 100% gratuita.
                  <br /> Para sempre.
                </TextInfo>
                <TextSubInfo>Sem necessidade de cartão de crédito.</TextSubInfo>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </FormProvider>
  )
}

export default Register
