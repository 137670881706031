import React from 'react'
import { CreditCard, Upc } from '@styled-icons/bootstrap'

import * as S from '../styles'

import Heading from '../../../shared/Heading'

import SubscriptionTastingMessage from '~/pages/subscription/components/Checkout/components/SubscriptionTastingMessage'

import formatCurrency from '../../../utils/format-currency'

function PaymentInfo(props) {
  const { payment } = props

  const getDiscount = () => {
    return (
      <>
        {!!payment.discountCouponCode && (
          <S.DetailsInfo>
            <S.DetailsInfoStrong>Cupom de Desconto:</S.DetailsInfoStrong>{' '}
            {payment.discountCouponCode}
          </S.DetailsInfo>
        )}

        {!!payment.discountCouponAmount && (
          <S.DetailsInfo>
            <S.DetailsInfoStrong>Valor do Desconto:</S.DetailsInfoStrong> R${' '}
            {formatCurrency(payment.discountCouponAmount)}
          </S.DetailsInfo>
        )}
      </>
    )
  }

  return (
    <>
      <Heading lineBottom>Forma de Pagamento</Heading>

      <S.DetailsCard>
        {payment.paymentMethod === 'Cartão de Crédito' && (
          <>
            <S.DetailsInfo>
              <CreditCard width="1em" height="1em" style={{ marginRight: 8 }} />
              {payment.creditCardMask}
            </S.DetailsInfo>

            {getDiscount()}

            <S.DetailsInfo>
              {payment.installments}x de R$
              {formatCurrency(payment.installmentAmount)}
            </S.DetailsInfo>

            {!!payment?.subscriptionPlanTrial && (
              <S.DetailsInfo>
                <SubscriptionTastingMessage
                  initialDate={payment.trialPeriodStart}
                  endDate={payment.trialPeriodEnd}
                  billingDate={payment.trialPeriodSubscriptionChargeDate}
                  canUseSubscriptionPlanTrial={
                    payment.canUseSubscriptionPlanTrial
                  }
                />
              </S.DetailsInfo>
            )}
          </>
        )}

        {payment.paymentMethod === 'Boleto' && (
          <>
            <S.DetailsInfo>
              <Upc width="1.4em" height="1.4em" style={{ marginRight: 4 }} />
              {payment.paymentMethod}
            </S.DetailsInfo>

            {!!payment.paymentSlipExpirationDate && (
              <S.DetailsInfo>
                <S.DetailsInfoStrong>Data de Vencimento: </S.DetailsInfoStrong>
                {payment.paymentSlipExpirationDate?.replace(
                  /(\d*)-(\d*)-(\d*).*/,
                  '$3/$2/$1',
                )}
              </S.DetailsInfo>
            )}

            {getDiscount()}

            <S.DetailsInfo>
              {' '}
              <S.DetailsInfoStrong>Valor: </S.DetailsInfoStrong> R$
              {formatCurrency(payment.amount)}
            </S.DetailsInfo>
          </>
        )}
      </S.DetailsCard>
    </>
  )
}

export default PaymentInfo
