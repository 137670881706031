import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import * as S from './styles'

import ErrorMessage from '../ErrorMessage'

function InputMask(props) {
  const { control, errors } = useFormContext()
  const {
    type,
    inputMode,
    name,
    placeholder,
    mask,
    beforeMaskedValueChange,
    onChange,
    onFocus,
    defaultValue,
  } = props

  return (
    <S.InputMaskWrapper>
      <Controller
        as={
          <S.InputMask
            type={type}
            inputMode={inputMode}
            name={name}
            placeholder={placeholder}
            mask={mask}
            maskChar={null}
            beforeMaskedValueChange={beforeMaskedValueChange}
            onChange={onChange}
            onFocus={onFocus}
          />
        }
        name={name}
        defaultValue={defaultValue}
        control={control}
      />

      {errors[name] && <ErrorMessage>{errors[name]['message']}</ErrorMessage>}
    </S.InputMaskWrapper>
  )
}

export default InputMask
