import React from 'react'
import * as S from './styles'

function Layout(props) {
  const { children, className } = props

  return <S.Layout className={className}>{children}</S.Layout>
}

export default Layout
