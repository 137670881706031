import React, { useEffect, useState } from 'react'
import { Avatar, Typography, Skeleton } from 'antd'
import { UserOutlined, LeftOutlined } from '@ant-design/icons'
import { useCalendlyEventListener, InlineWidget } from 'react-calendly'
import Dialog from '@material-ui/core/Dialog'
import services from '../private-classes.services'
import ModalLessonsCombo from '../../ModalLessonsCombo/containers/modalLessonsCombo.container'
import * as S from '../private-classes.style'
import { refreshToken } from '~/services/api'

function PrivateClassesViewComponent(props) {
  const { data, setStep } = props
  const { Title, Text } = Typography

  const storagedValue = JSON.parse(localStorage.getItem('user'))

  const [dataInfo, setDataInfo] = useState([])
  const [loading, setLoading] = useState(false)
  const [isDoneScheduling, setDoneScheduling] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [userStorage, setUserStorage] = useState(storagedValue)

  useEffect(() => {
    refreshToken()
    const storagedValue = JSON.parse(localStorage.getItem('user'))
    setUserStorage(storagedValue)
  }, [])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setStep(1)
  }

  async function getUserPerfilDetails() {
    setLoading(true)
    const response = await services.getUserDetails()
    const { data } = response

    setDataInfo(data.info)
    setLoading(false)
  }

  useEffect(() => {
    getUserPerfilDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useCalendlyEventListener({
    onEventTypeViewed: () => {
      refreshToken()
      const storagedValue = JSON.parse(localStorage.getItem('user'))
      setUserStorage(storagedValue)
    },
    onDateAndTimeSelected: () => {
      if (userStorage.qtdPrivateClass <= 0) {
        showModal()
      }
    },
    onEventScheduled: async (e) => {
      // console.log(e.data.payload)
      setDoneScheduling(true)
      await services.dataUpdateCredits({
        qtdPrivateClasses: 1,
        qtdMentorShip: 0,
      })
    },
  })

  const prefill = {
    name: `${`${dataInfo.firstName} ${dataInfo.lastName}`}`,
    email: dataInfo.email,
  }

  const pageSettings = {
    primaryColor: '4f00e4',
    textColor: '404040',
    backgroundColor: 'fffff',
    hideGdprBanner: true,
    hideLandingPageDetails: true,
    hideEventTypeDetails: true,
  }

  function getCalendlyHeightToEnsureNoCrop() {
    if (isDoneScheduling) return { height: 450 }
    return { height: 1060 }
  }

  // const renderCalendly = () => {
  //   setLoading(true)
  //   const head = document.querySelector('head')
  //   const script = document.createElement('script')
  //   script.setAttribute(
  //     'src',
  //     'https://assets.calendly.com/assets/external/widget.js',
  //   )
  //   head.appendChild(script)
  //   setLoading(false)
  // }
  // useEffect(() => {
  //   renderCalendly()
  // }, [])

  // function isCalendlyEvent(e) {
  //   return (
  //     e.origin === 'https://calendly.com' &&
  //     e.data.event &&
  //     e.data.event.indexOf('calendly.') === 0
  //   )
  // }

  // window.addEventListener('message', function (e) {
  //   if (isCalendlyEvent(e)) {
  //     /* Example to get the name of the event */
  //     console.log('Event name:', e.data.event)

  //     /* Example to get the payload of the event */
  //     console.log('Event details:', e.data.payload)
  //   }
  // })

  if (loading) return <Skeleton active />

  return (
    <>
      <S.EssayWrapper>
        <S.ButtonGoBack
          type="primary"
          size="large"
          shape="round"
          icon={<LeftOutlined />}
          onClick={() => setStep(1)}
        >
          Voltar para Professores
        </S.ButtonGoBack>
        <S.ViewTeacherContainer>
          <S.Content>
            <S.TeacherInfo style={{ display: 'flex' }}>
              <Avatar size={135} icon={<UserOutlined />} />
              <S.InfoContent>
                <S.TitleCard>{data.name}</S.TitleCard>
                <S.TextCard>{data.discipline}</S.TextCard>
                <span role="img" aria-label="estrelas">
                  ⭐️ ⭐️ ⭐️ ⭐️ ⭐️
                </span>
              </S.InfoContent>
            </S.TeacherInfo>
            <S.BioContent>
              <S.BioText>{data.biography}</S.BioText>
            </S.BioContent>
          </S.Content>
          <S.ContentCalendar>
            <Text style={{ color: '#404040' }}>
              Selecione um dos horários disponíveis:
            </Text>
          </S.ContentCalendar>
          {/*           <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/corujasabia?hide_gdpr_banner=1&primary_color=4f00e4"
            style={{ minWidth: '320px', height: '630px' }}
          /> */}
          {data.calendlySlug ? (
            <InlineWidget
              url={`https://calendly.com/${data.calendlySlug}/aula-particular`}
              pageSettings={pageSettings}
              prefill={prefill}
              styles={{ width: '100%', maxWidth: '1000px' }}
            />
          ) : (
            <Title level={4} style={{ color: '#404040' }}>
              Ainda não foi cadastrado nenhum horário
            </Title>
          )}
        </S.ViewTeacherContainer>
      </S.EssayWrapper>
      <Dialog
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
        fullWidth
        maxWidth="md"
        onClose={handleCancel}
        open={isModalVisible}
      >
        <ModalLessonsCombo setIsModalVisible={setIsModalVisible} />
      </Dialog>
    </>
  )
}

export default PrivateClassesViewComponent
