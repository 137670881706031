import React from 'react'
import { useHistory } from 'react-router-dom'
import { Avatar, Dropdown } from 'antd'
import { UserOutlined, DownOutlined } from '@ant-design/icons'

import * as S from '../../default.styles'

import Box from '~/shared/Box'
import { Text } from '~/shared/Typography'

import useAuth from '~/hooks/useAuth'
import useData from '~/pages/subscription/hooks/useData'

function DefaultUserComponent() {
  const auth = useAuth()
  const history = useHistory()
  const { setAllowLiveFaq } = useData()

  const handleSignOut = () => {
    setAllowLiveFaq(false)
    auth.signOut()
  }

  const menu = (
    <S.Menu>
      <S.MenuItem onClick={() => history.push('/alterar-senha')}>
        Alterar senha
      </S.MenuItem>
      <S.MenuItem onClick={handleSignOut}>Sair</S.MenuItem>
    </S.Menu>
  )

  return (
    <Box display="flex" align="center">
      <Box display="flex" mr="16px">
        <Avatar size={48} icon={<UserOutlined />} />
      </Box>

      <S.StudentName>
        <Box mr="8px">
          <Text fontWeight="bold" color="#fff">
            {auth.user.name.split(' ')[0]}
          </Text>
        </Box>
      </S.StudentName>

      <Dropdown placement="bottomRight" overlay={menu}>
        <DownOutlined style={{ fontSize: 16, color: '#fff' }} />
      </Dropdown>
    </Box>
  )
}

export default DefaultUserComponent
