import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'

import StudyPlansModeComponent from '../../studyplans/components/study-plans-mode.component'
import StudyPlansButtonComponent from '../../studyplans/components/study-plans-button.component'

import DisciplinesHandlerComponent from '../components/disciplines-handler.component'

import { studyPlansModeEnum } from '../../studyplans/study-plans.enums'

import { DisciplinesFormCol } from '../disciplines.styles'

import Space from '~/shared/Space'
import InputSearch from '~/shared/InputSearch'
import { Text } from '~/shared/Typography'
import Select from '~/shared/Select'
import notification from '~/shared/Notification'
import services from '../disciplines.services'
import studyPlansServices from '../../studyplans/study-plans.services'
import useData from '~/pages/subscription/hooks/useData'

function DisciplinesContainer() {
  const [loadingStudyPlans, setLoadingStudyPlans] = useState(true)
  const [loadingDisciplines, setLoadingDisciplines] = useState(true)

  const [studyPlans, setStudyPlans] = useState([])
  const [disciplinesCombobox, setDisciplinesCombobox] = useState([])

  const [disciplines, setDisciplines] = useState([])
  const [isDiscipline, setIsDiscipline] = useState(false)
  const [isWeek, setIsWeek] = useState(false)
  const [selectedStudyPlan, setSelectedStudyPlan] = useState(null)
  const [selectedStudentStudyPlan, setSelectedStudentStudyPlan] = useState(null)

  const { setAccessLiveClasses } = useData()

  const [filters, setFilters] = useState({
    disciplineId: null,
    lessonTypeId: null,
  })

  useEffect(() => {
    async function getStudyPlans() {
      const response = await studyPlansServices.getStudyPlansDisciplines()
      setLoadingStudyPlans(false)

      const studyPlans = response.data
      if (response.data.length === 0) {
        setLoadingDisciplines(false)
      }

      setStudyPlans(studyPlans)
      setSelectedStudyPlan(studyPlans.length && studyPlans[0].studyPlanId)
      setSelectedStudentStudyPlan(
        studyPlans.length && studyPlans[0].studentStudyPlanId,
      )

      if (studyPlans.length && studyPlans[0]?.accessLiveClasses)
        setAccessLiveClasses(true)
    }

    getStudyPlans()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getDisciplinesCombobox() {
      const response = await services.getDisciplinesCombobox()
      setDisciplinesCombobox(response.data)
    }
    if (selectedStudyPlan) getDisciplinesCombobox()
  }, [selectedStudyPlan])

  useEffect(() => {
    async function getDisciplines() {
      const response = await services.getDisciplines(filters)

      setLoadingDisciplines(false)
      setDisciplines(response.data)
    }

    if (selectedStudyPlan) {
      setLoadingDisciplines(true)
      getDisciplines()
    }
  }, [selectedStudyPlan, filters])

  function onSubmit(values) {
    if (values.filter && values.filter !== '')
      setFilters((filters) => ({ ...filters, values }))
    else setFilters((filters) => ({ ...filters, filter: values }))
  }

  return (
    <Space size="large" direction="vertical">
      <StudyPlansModeComponent
        selectedMode={studyPlansModeEnum.subjects}
        isDiscipline={isDiscipline}
      />
      {!!studyPlans.length && (
        <>
          <Row gutter={16} align="middle" justify="space-between">
            <Col
              xs={24}
              sm={24}
              md={14}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <DisciplinesFormCol>
                <Col xs={24} sm={14} md={12} className="discipline-col-form">
                  <Form.Item
                    label={
                      <Text fontFamily="Open Sans">Filtrar por disciplina</Text>
                    }
                    style={{
                      margin: '0',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Select
                      size="large"
                      defaultValue={null}
                      bordered={false}
                      onChange={(disciplineId) =>
                        setFilters((filter) => ({ ...filter, disciplineId }))
                      }
                      listItemHeight={10}
                      listHeight={250}
                    >
                      <Select.Option value={null}>Todas</Select.Option>
                      {disciplinesCombobox.map((discipline) => (
                        <Select.Option
                          key={discipline.id}
                          value={discipline.id}
                        >
                          {discipline.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={14} md={12} className="discipline-col-form">
                  <Form.Item
                    label={<Text fontFamily="Open Sans">Duração da aula</Text>}
                    style={{
                      margin: '0',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Select
                      size="large"
                      defaultValue={null}
                      bordered={false}
                      onChange={(lessonTypeId) =>
                        setFilters((filter) => ({ ...filter, lessonTypeId }))
                      }
                    >
                      <Select.Option value={null}>Todas</Select.Option>
                      <Select.Option value={2}>3 min</Select.Option>
                      <Select.Option value={5}>8 min</Select.Option>
                      <Select.Option value={4}>15 min</Select.Option>
                      <Select.Option value={1}>30 min</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </DisciplinesFormCol>
            </Col>

            <Col xs={24} sm={12} md={7}>
              <Form>
                <Form.Item name="filter" style={{ marginBottom: 0 }}>
                  <InputSearch
                    placeholder="Buscar uma aula ou termo"
                    onSearch={onSubmit}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </>
      )}
      <DisciplinesHandlerComponent
        loadingDisciplines={loadingDisciplines}
        disciplines={disciplines}
      />
    </Space>
  )
}

export default DisciplinesContainer
