import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col } from 'react-flexbox-grid'

import * as S from './styles'

import StatusInfo from './components/StatusInfo'
import PlanInfo from './components/PlanInfo'
import PaymentAction from './components/PaymentAction'

import Container from '~/pages/subscription/shared/Container'

import api from '~/services/api'

import GlobalStyles from '~//styles/global'

function Details({ resSubscriptionId, setIsModalVisible }) {
  const [paymentDetails, setPaymentDetails] = useState(null)

  const getPaymentDetails = useCallback(async () => {
    const response = await api.get('/student/subscription/status', {
      params: {
        subscriptionId: resSubscriptionId,
      },
    })

    setPaymentDetails(response.data)
  }, [resSubscriptionId])

  useEffect(() => {
    getPaymentDetails()
  }, [getPaymentDetails])

  useEffect(() => {
    if (paymentDetails) {
      if (
        paymentDetails.payment.status === 1 ||
        paymentDetails.payment.status === 2 ||
        paymentDetails.payment.status === 3
      ) {
        const getPaymentInterval = setInterval(getPaymentDetails(), 5000)

        clearInterval(getPaymentInterval)
      }
    }
  }, [getPaymentDetails, paymentDetails])

  return (
    <>
      <Container size="small" noPadding>
        {paymentDetails && (
          <Row>
            <Col xs={12}>
              <S.DetailsWrapper>
                <StatusInfo
                  id={resSubscriptionId}
                  payment={paymentDetails.payment}
                />
              </S.DetailsWrapper>
            </Col>

            <Col xs={12}>
              <S.DetailsWrapper>
                <PlanInfo
                  subscription={paymentDetails.subscription}
                  studyPlans={paymentDetails.studyPlans}
                />
              </S.DetailsWrapper>
            </Col>

            <Col xs={12}>
              <S.DetailsWrapper>
                <PaymentAction
                  payment={paymentDetails.payment}
                  setIsModalVisible={setIsModalVisible}
                />
              </S.DetailsWrapper>
            </Col>
          </Row>
        )}
        <GlobalStyles />
      </Container>
    </>
  )
}

export default Details
