import React, { useState, useEffect } from 'react'
import { Row, Col, Skeleton } from 'antd'
import StudyPlansModeComponent from '~/pages/studyplans/components/study-plans-mode.component'
import { Text as TextShared } from '~/shared/Typography'
import LiveClassesVimeo from './liveClasses-vimeo.component'
import LiveClassesTimeline from './liveClasses-timeline.component'
import LessonsRatingComponent from './../../lessons/components/lessons-rating.component'
import * as S from '../liveClasses.styles'
import services from '../liveClasses.services'
import LessonsServices from './../../lessons/lessons.services'

function LiveClassesComponent() {
  const [loading, setLoading] = useState(true)
  const [video, setVideo] = useState(false)
  const [timelineData, setTimelineData] = useState(false);
  const [nextLive, setNextLive] = useState(false);

  //Busca Próxima Live   
  function getNextLive(timelineData) {

    let yearObj = timelineData?.filter((yearObj) => {
      if(yearObj.year == new Date().getFullYear()) return yearObj;
    })   

    if( yearObj[0] && yearObj[0].listMonth) {

      let monthObj = yearObj[0]?.listMonth?.filter((monthObj) => {
        if(monthObj.month >= ( new Date().getMonth() + 1 ) ) return monthObj;
      })  
      
      if(monthObj[0] && monthObj[0].lives) {

        let liveList = monthObj[0]?.lives?.filter((videoObj) => {

          const now = new Date()
          const live = new Date(videoObj.dateLiveClass)

          if(live > now) return videoObj
          
        })
        
        if(liveList[0]) return liveList[0]
      }
    }
    return false
    
  }

  //Busca Lista de Lives  
  services.getLessons().then( data => {
    if(!timelineData) setTimelineData(data.data);
    if(timelineData && !nextLive) setNextLive(getNextLive(timelineData));
  })

  const formatData = (dateLiveClass) => {
    const dateObj = new Date(dateLiveClass);
    const date = dateObj.toLocaleDateString('pt-br')                            
    const time = ("0" + dateObj.getHours()).slice(-2) + 'h' + ("0" + dateObj.getMinutes()).slice(-2)
    let day = dateObj.toLocaleDateString('pt-br', { weekday: 'long' })
    day =  day.toLowerCase().replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g, s => s.toUpperCase())  

    return day + ", " + date + " às " + time;
  }
  
  if(nextLive && !video) setVideo(nextLive)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2500)
  }, [])
  
  return (
    <S.Wrapper>
      <StudyPlansModeComponent selectedMode={3} />

      <S.Content>
        <S.Header>
          <TextShared fontSize="32px" fontWeight="bold" color="#434343">
            Aulas Ao-Vivo
          </TextShared>
        </S.Header>

        <Row gutter={24} justify="space-between">

          {!video &&
            <Col xs={24} sm={24} md={24} lg={16}>
              <S.Text>Não há aulas Ao Vivo</S.Text>
            </Col>
          }
          {video && 
            <Col xs={24} sm={24} md={24} lg={16}>

              { !video.videoUrl &&
              <>
                <S.Text  style={{ marginBottom: '0px'}}>Aula ao Vivo:</S.Text> 
                <p>{formatData(video.dateLiveClass)}</p>
              </>              
                
              }
              { video.videoUrl &&
              <>
                <S.Text style={{ marginBottom: '0px'}}>Aula Gravada:</S.Text>
                <p>{formatData(video.dateLiveClass)}</p>
              </>
              }

              {loading && <Skeleton active />}

              <LiveClassesVimeo
                id={video.videoId}
                isLive={video.videoUrl ? false : true}
                className={loading ? 'loading' : ''}
              />

              { video.videoUrl &&
                <LessonsRatingComponent 
                  lessonId={video.id} 
                  services={LessonsServices}
                  isLive={true}
                />
              }
            </Col>
          }

          {timelineData && video &&
            <Col xs={24} sm={24} md={24} lg={8}>
              <S.Text>Cronograma:</S.Text>

              <LiveClassesTimeline 
                setVideo={setVideo}
                timelineData={timelineData}
                video={video}
              />
            </Col>
          }
          { timelineData && !video &&

            <Col xs={24} sm={24} md={24} lg={8}>
            <S.Text>Cronograma:</S.Text>

            <LiveClassesTimeline 
              setVideo={setVideo}
              timelineData={timelineData}
              video={null}
            />
            </Col>
                      
          }
        </Row>
      </S.Content>
    </S.Wrapper>
  )
}

export default LiveClassesComponent
