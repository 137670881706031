import LiveClasses from './containers/liveClasses-list.container'

const routes = [
  {
    path: '/aulas-ao-vivo',
    title: 'Aulas Ao-Vivo',
    authenticated: true,
    component: LiveClasses,
  },
]

export default routes
