import React from 'react'
import * as S from './styles'

function Container(props) {
  const { id, size, noPadding, children } = props

  return (
    <S.Container id={id} size={size} noPadding={noPadding}>
      {children}
    </S.Container>
  )
}

export default Container
