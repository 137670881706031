import api from '~/services/api'

const services = {
  getLessons(data) {
    const { idStudyPlan, id } = data

    return api.get(`/student/study-plan/${idStudyPlan}/tree-view/${id}`)
  },

  getStudPlanType(idStudyPlan) {
    return api.get(`/student/study-plan/getStudPlanType/${idStudyPlan}`)
  },

  getPlanTreeViewById(id, filters) {
    return api.get(`/student/study-plan/${id}`, { params: filters })
  },

  getById(idStudyPlan, id) {
    return api.get(`/student/study-plan/${idStudyPlan}/lesson/${id}`)
  },

  start(idStudyPlan, id) {
    return api.post(`/student/study-plan/${idStudyPlan}/lesson/${id}/start`)
  },

  pause(idStudyPlan, id, seconds) {
    const intSeconds = parseInt(seconds, 10)

    return api.post(
      `/student/study-plan/${idStudyPlan}/lesson/${id}/pause?t=${intSeconds}`,
    )
  },

  finish(idStudyPlan, id) {
    return api.post(`/student/study-plan/${idStudyPlan}/lesson/${id}/finish`)
  },

  getDiscipline(id) {
    return api.get(`/student/study-plan/discipline/lessons/${id}`)
  },

  getFeedback(id, isLive) {
    return api.post(`/feedback/filter`, {
      disciplineLessonId: isLive ? null : id,
      disciplineLiveClassId: isLive ? id : null,
    })
  },

  setFeedback(id, score, feedbackMessage, isLive) {
    return api.post(
      '/feedback',
      isLive
        ? { disciplineLiveClassId: parseInt(id), score, feedbackMessage }
        : { disciplineLessonId: parseInt(id), score, feedbackMessage },
    )
  },

  logMaterialAccess(lesson, user) {
    const objLogMaterial = {
      studentId: user.studentId,
      studentEmail: user.email,
      disciplineId: lesson.disciplineId,
      disciplineName: lesson.disciplineName,
      disciplineSubjectName: lesson.disciplineSubjectName,
      disciplineLessonId: lesson.disciplineLessonId,
      disciplineLessonName: lesson.title,
    }

    return api.post(
      'lesson-access-log/create-material-access-log',
      objLogMaterial,
    )
  },

  logExerciseAccess(lesson, user) {
    const objLogExercise = {
      studentId: user.studentId,
      studentEmail: user.email,
      disciplineId: lesson.disciplineId,
      disciplineName: lesson.disciplineName,
      disciplineSubjectName: lesson.disciplineSubjectName,
      disciplineLessonId: lesson.disciplineLessonId,
      disciplineLessonName: lesson.title,
    }
    return api.post(
      'lesson-access-log/create-excercise-access-log',
      objLogExercise,
    )
  },
}

export default services
