import React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const modalMessages = {
  confirmation(
    message,
    action,
    cancelWithoutAction,
    content,
    okText,
    cancelText,
  ) {
    const config = {
      title: message,
      icon: <ExclamationCircleOutlined />,
      okText: okText || 'Confirmar',
      okType: 'danger',
      cancelText: cancelText || 'Cancelar',
      content,
      onOk() {
        return new Promise((resolve) => {
          if (action) action(true)
          resolve()
        })
      },

      onCancel() {
        if (!cancelWithoutAction) {
          if (action) action()
        }
      },
    }

    Modal.confirm(config)
  },
}

export default modalMessages
