import React from 'react'
import { Redirect, Route as ReactRoute } from 'react-router-dom'
import useAuth from '~/hooks/useAuth'
import { AuthLayout, DefaultLayout, ExternalLayout } from '~/pages/_layouts'

function Route(props) {
  const {
    path,
    title,
    breadcrumb,
    routeBack,
    external,
    subscription,
    withoutHeader,
    component: Component,
    authenticated: authenticatedRoute,
  } = props
  const auth = useAuth()

  const isSigned = auth.signed

  if (!isSigned && authenticatedRoute) return <Redirect to="/login" />
  if (isSigned && !authenticatedRoute) return <Redirect to="/painel" />

  return (
    <ReactRoute
      path={path}
      render={(props) => {
        if (path?.includes('/aula/')) {
          return <Component />
        }
        if (external) {
          return (
            <ExternalLayout
              routeBack={routeBack}
              subscription={subscription}
              withoutHeader={withoutHeader}
            >
              <Component />
            </ExternalLayout>
          )
        }

        const Layout = isSigned ? DefaultLayout : AuthLayout

        return (
          <Layout breadcrumb={breadcrumb} title={title}>
            <Component />
          </Layout>
        )
      }}
    />
  )
}

export default Route
