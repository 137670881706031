import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Grid, Collapse, Tooltip } from 'antd'

import * as S from '../study-plans.styles'

import StudyPlansExpandComponent from './study-plans-expand.component'
import StudyPlansProgressComponent from './study-plans-progress.component'

import Space from '~/shared/Space'
import Box from '~/shared/Box'
import Card from '~/shared/Card'
import { Text } from '~/shared/Typography'

function StudyPlansCalendarComponent(props) {
  const history = useHistory()
  const breakpoint = Grid.useBreakpoint()

  const { selectedStudyPlanContent } = props

  const getGridColumns = useCallback(() => {
    if (breakpoint.xxl || breakpoint.xl) return 5
    if (breakpoint.lg) return 3
    if (breakpoint.md) return 2
    if (breakpoint.sm || breakpoint.xs) return 1

    return null
  }, [breakpoint])

  const redirectLesson = useCallback(
    (idStudyPlan, id) => {
      history.push(`${idStudyPlan}/aula/${id}`)
    },
    [history],
  )

  useEffect(() => {
    localStorage.setItem('isStudyPlan', true)
  })

  history.listen((location, action) => {
    if (
      !location.pathname.includes('/disciplina') &&
      !location.pathname.includes('/painel') &&
      !location.pathname.includes('/aula/')
    ) {
      localStorage.removeItem('isStudyPlan')
    }
  })

  const MemoizedCalendar = useMemo(() => {
    return selectedStudyPlanContent ? (
      <Collapse
        defaultActiveKey={selectedStudyPlanContent.steps.map(
          (studyPlanContentStep) => studyPlanContentStep.id,
        )}
        expandIcon={(expandIcon) => (
          <StudyPlansExpandComponent isActive={expandIcon.isActive} />
        )}
        ghost
      >
        {selectedStudyPlanContent.steps.map((studyPlanContentStep) => (
          <S.StudyPlansCollapsePanel
            key={studyPlanContentStep.id}
            header={
              <>
                <Row
                  align="middle"
                  justify="space-between"
                  id={studyPlanContentStep.label.split(' ').join('-')}
                >
                  <Box ml="32px">
                    <Text fontSize="18px" fontWeight="bold" color="#434343">
                      {studyPlanContentStep.label}
                    </Text>
                  </Box>

                  <Text fontFamily="Open Sans" fontSize="12px" color="#434343">
                    {studyPlanContentStep.lessonsDone} de{' '}
                    {studyPlanContentStep.totalLessons} aulas assistidas
                    {/* |{' '}
                    {studyPlanContentStep.pointsErned} de{' '}
                    {studyPlanContentStep.totalPoints} pontos ganhos */}
                  </Text>
                </Row>

                <StudyPlansProgressComponent
                  percent={studyPlanContentStep.completation * 10}
                />
              </>
            }
          >
            <Space size="large" direction="vertical">
              <Box
                display="grid"
                gridGap="16px"
                gridTemplateColumns={`repeat(${getGridColumns()}, 1fr)`}
              >
                {studyPlanContentStep.daysOfWeek.map(
                  (studyPlanDay) =>
                    studyPlanDay.lessons.length > 0 && (
                      <Box key={studyPlanDay.id}>
                        <Card size="small">
                          <Row justify="center">
                            <Box mb="16px">
                              <Text
                                fontSize="18px"
                                fontWeight="bold"
                                color="#403edc"
                              >
                                {studyPlanDay.dayOfWeek}
                              </Text>
                            </Box>
                          </Row>

                          {studyPlanDay.lessons.map((studyPlanLesson) => (
                            <Box key={studyPlanLesson.id} mb="8px">
                              <Row align="middle" justify="center">
                                { ((studyPlanContentStep.studyPlanStepTypeId ===
                                  3 ||
                                  (studyPlanContentStep.studyPlanStepTypeId ===
                                    2 &&
                                    !studyPlanContentStep.isLastStepDone) ) || !studyPlanLesson.canAccessLesson  ) && (
                                  <S.StudyPlansLockOutlinedIcon
                                    fontSize="20px"
                                    color={'#939393'}
                                  />
                                )}

                                {((studyPlanContentStep.studyPlanStepTypeId ===
                                  1 ||
                                  (studyPlanContentStep.studyPlanStepTypeId ===
                                    2 &&
                                    studyPlanContentStep.isLastStepDone)) && studyPlanLesson.canAccessLesson  )  && (
                                  <S.StudyPlansCheckOutlinedIcon
                                    fontSize="20px"
                                    color={
                                      studyPlanLesson.done
                                        ? '#52c41a'
                                        : '#939393'
                                    }
                                  />
                                )}

                                <S.StudyPlansTag
                                  backgroundColor={
                                    studyPlanLesson.done ? '#403edc' : '#939393'
                                  }
                                >
                                  {studyPlanLesson.shortDisciplineName}  
                                </S.StudyPlansTag>
                                {(studyPlanContentStep.studyPlanStepTypeId ===
                                  3 ||
                                  (studyPlanContentStep.studyPlanStepTypeId ===
                                    2 &&
                                    !studyPlanContentStep.isLastStepDone)) && (
                                  <Text
                                    fontFamily="Open Sans"
                                    fontWeight="bold"
                                    cursor="pointer"
                                    disabled={true}
                                  >
                                    {studyPlanLesson.shortTitle} 
                                  </Text>
                                )}
                                {(studyPlanContentStep.studyPlanStepTypeId ===
                                  1 ||
                                  (studyPlanContentStep.studyPlanStepTypeId ===
                                    2 &&
                                    studyPlanContentStep.isLastStepDone)) && (
                                  <Tooltip
                                    title={studyPlanLesson.title}
                                    trigger={['hover', 'click']}
                                  > {(studyPlanLesson.canAccessLesson) && (<Text
                                    fontFamily="Open Sans"
                                    fontWeight="bold"
                                    cursor="pointer"
                                    onClick={() =>
                                      
                                      redirectLesson(
                                        selectedStudyPlanContent.studyPlanId,
                                        studyPlanLesson.id,
                                      )
                                    } 
                                  >
                                    {studyPlanLesson.shortTitle} 
                                  </Text>)}
                                  {(!studyPlanLesson.canAccessLesson) && (<Text
                                    fontFamily="Open Sans"
                                    fontWeight="bold"
                                  >
                                    {studyPlanLesson.shortTitle} 
                                  </Text>)}
                                    
                                  </Tooltip>
                                )}
                              </Row>
                            </Box>
                          ))}
                        </Card>

                        {/* <Box mt="8px" textAlign="center">
                          <Text
                            fontWeight="bold"
                            color={studyPlanDay.done ? '#434343' : '#939393'}
                          >
                            {studyPlanDay.totalPoints} pontos
                          </Text>
                        </Box> */}
                      </Box>
                    ),
                )}
              </Box>
            </Space>
          </S.StudyPlansCollapsePanel>
        ))}
      </Collapse>
    ) : null
  }, [getGridColumns, redirectLesson, selectedStudyPlanContent])

  return MemoizedCalendar
}

export default StudyPlansCalendarComponent
