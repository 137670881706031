import React, { Fragment, useState } from 'react'
import { Button, Upload, Modal, Row, Col } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import api from '../essay.services'
import notification from '~/shared/Notification'

function EssayModalComponent(props) {
  const { visible, handleOk, handleCancel, item } = props
  const [file, setFile] = useState(null)

  async function beforeUpload(file) {
    setFile(file)
    return true
  }

  const onOk = async () => {
    const {
      studyPlanId,
      disciplineId,
      disciplineSubjectId,
      disciplineLessonId,
    } = item

    const obj = {
      StudyPlanId: studyPlanId,
      DisciplineId: disciplineId,
      file,
      DisciplineSubjectId: disciplineSubjectId,
      DisciplineLessonId: disciplineLessonId,
    }
    try {
      await api.upload(obj)
      notification.success('Redação enviada com sucesso.')
      await handleOk()
    } catch (er) {}
  }
  const onCancel = () => {
    setFile(null)
    handleCancel()
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  return (
    <Modal
      title="Enviar redação"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        <Upload
          name="pdf"
          accept=".doc"
          accept=".docx"
          customRequest={dummyRequest}
          showUploadList={false}
          beforeUpload={beforeUpload}
          style={{ marginRight: '10px' }}
        >
          <Button icon={<UploadOutlined />}>Carregar redação</Button>
        </Upload>
      </Row>
      {file?.name && (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Col>
            <Fragment>Documento: {file?.name}</Fragment>
          </Col>
        </Row>
      )}
    </Modal>
  )
}

export default EssayModalComponent
