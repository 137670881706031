import styled from 'styled-components'
import { Card, Layout } from 'antd'

import {
  CheckCircleFilled,
  HourglassOutlined,
  FileTextOutlined,
  TrophyOutlined,
  PercentageOutlined,
  LockOutlined,
} from '@ant-design/icons'

import Vimeo from '@u-wave/react-vimeo'

const StyledLayout = styled(Layout)`
  background-color: #fff;

  @media (max-width: 1000px) {
    .row-alternative .ant-form-item .ant-card.ant-card-bordered.ant-card-small,
    .row-alternative .ant-space.ant-space-vertical,
    .row-alternative .ant-card.ant-card-bordered {
      width: 100% !important;
    }

    .row-alternative .ant-radio-group.ant-radio-group-outline,
    .row-alternative
      .ant-space.ant-space-vertical
      .ant-space-item
      .ant-space.ant-space-horizontal.ant-space-align-center,
    .row-alternative .ant-radio-group .ant-space {
      display: block;
    }

    .row-alternative .radio-alternative {
      margin: 0;
      height: auto !important;
    }

    .row-alternative .ant-space-item .ant-typography p {
      margin-bottom: 0;
    }
  }
`
const StudyPlansCardFeedback = styled(Card)`
  border: ${(props) => props.border};
  width: ${(props) => props.width};
  margin-bottom: ${(props) => props.marginBottom};

  @media (max-width: 1030px) {
    width: 900px;
  }
  @media (max-width: 980px) {
    width: 880px;
  }
  @media (max-width: 965px) {
    width: 840px;
  }
  @media (max-width: 950px) {
    width: 820px;
  }
  @media (max-width: 935px) {
    width: 760px;
  }
  @media (max-width: 920px) {
    width: 700px;
  }
  @media (max-width: 800px) {
    width: 630px;
  }
  @media (max-width: 700px) {
    width: 530px;
  }
  @media (max-width: 570px) {
    width: 400px;
  }

  @media (max-width: 500px) {
    width: 380px;
  }

  @media (max-width: 450px) {
    width: 350px;
  }

  @media (max-width: 380px) {
    width: 300px;
  }

  @media (max-width: 330px) {
    width: 260px;
  }

  @media (max-width: 280px) {
    width: 220px;
  }
`

const StudyPlansCheckOutlinedIcon = styled(CheckCircleFilled)`
  font-size: 20px;
  color: ${(props) => props.color};
`

const StudyPlansLockOutlinedIcon = styled(LockOutlined)`
  font-size: 20px;
  color: ${(props) => props.color};
`

const StudyPlansHourglassOutlinedIcon = styled(HourglassOutlined)`
  color: ${(props) => props.color};
`

const StudyPlansFileTextOutlinedIcon = styled(FileTextOutlined)`
  color: ${(props) => props.color};
  margin-left: ${(props) => props.marginLeft};
`

const StudyPlansTrophyOutlinedIcon = styled(TrophyOutlined)`
  color: ${(props) => props.color};
  margin-left: ${(props) => props.marginLeft};
`

const StudyPlansPercentageOutlined = styled(PercentageOutlined)`
  margin-left: ${(props) => props.marginLeft};
  color: ${(props) => props.color};
`
const TestPlayer = styled(Vimeo)`
  position: relative;
  overflow: hidden;

  height: 0;

  padding-top: 0;
  padding-bottom: 56.25%;

  margin-bottom: 24px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
`

const TextWrapper = styled.div`
  margin-right: ${(props) => (props.marginRightEmpty ? 0 : '10px')};
  display: inline-block;
`

export {
  StudyPlansCardFeedback,
  StudyPlansCheckOutlinedIcon,
  StudyPlansHourglassOutlinedIcon,
  StudyPlansFileTextOutlinedIcon,
  StudyPlansTrophyOutlinedIcon,
  StudyPlansLockOutlinedIcon,
  StudyPlansPercentageOutlined,
  StyledLayout,
  TestPlayer,
  TextWrapper,
}
