import UserAccount from './containers/userAccount-list.container'

const routes = [
  {
    path: '/minha-conta',
    title: 'Minha conta',
    authenticated: true,
    component: UserAccount,
  },
]

export default routes
