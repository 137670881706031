import Register from './Register'
const routes = [
  {
    path: '/freemium/registro/',
    title: 'Cadastre-se',
    component: Register,
    external: true,
    withoutHeader: true,
    subscription: true,
  },
]

export default routes
