import React, { useState } from 'react'

import ModalLessonsComboComponent from '../components/modalLessonsCombo.component'
import Checkout from '../Checkout/index'
import Details from '../Details/index'

function ModalLessonsComboContainer({ setIsModalVisible }) {
  const [currentStep, setCurrentStep] = useState(0)
  const [resSubscriptionId, setResSubscriptionId] = useState(0)

  function stepRender() {
    switch (currentStep) {
      case 0:
      default:
        return <ModalLessonsComboComponent setCurrentStep={setCurrentStep} />
      case 1:
        return (
          <Checkout
            setCurrentStep={setCurrentStep}
            setResSubscriptionId={setResSubscriptionId}
          />
        )
      case 2:
        return (
          <Details
            resSubscriptionId={resSubscriptionId}
            setIsModalVisible={setIsModalVisible}
          />
        )
    }
  }

  return <>{stepRender()}</>
}

export default ModalLessonsComboContainer
