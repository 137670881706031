import React from 'react'

import StudyPlanEmptyComponent from './study-plans-empty.component'
import StudyPlanListComponent from './study-plans-list.component'
import StudyPlanCalendarComponent from './study-plans-calendar.component'

import { studyPlansViewEnum } from '../study-plans.enums'

import Loader from '~/shared/Loader'

function StudyPlansHandlerComponent(props) {
  const {
    loadingStudyPlansContent,
    studyPlans,
    selectedView,
    selectedStudyPlanContent,
  } = props

  if (!loadingStudyPlansContent && !studyPlans.length)
    return <StudyPlanEmptyComponent />

  if (selectedView === studyPlansViewEnum.calendar)
    return (
      <Loader loading={loadingStudyPlansContent}>
        <StudyPlanCalendarComponent
          selectedStudyPlanContent={selectedStudyPlanContent}
        />
      </Loader>
    )

  return (
    <Loader loading={loadingStudyPlansContent}>
      <StudyPlanListComponent
        selectedStudyPlanContent={selectedStudyPlanContent}
      />
    </Loader>
  )
}

export default StudyPlansHandlerComponent
