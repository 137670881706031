import MentorShip from './containers/mentorship.container'

const routes = [
  {
    path: '/mentoria',
    title: 'Mentoria',
    authenticated: true,
    component: MentorShip,
  },
]

export default routes
