import React, { useState, useEffect } from 'react'

import {
  Row,
  Col,
  Form,
  Input,
  Divider,
  Space,
  Button,
  Select,
  Skeleton,
  Empty,
} from 'antd'

import Layout from '~/shared/Layout'
import { Text } from '~/shared/Typography'
import api from '../student-form.services'
import * as S from '../student-form.styles'
import EditorTinyAlternatives from './editor-tinyAlternatives'
import notification from '~/shared/Notification'

function StudentFormTakeQuestions(props) {
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState(null)
  const [discipline, setDiscipline] = useState([])
  const [form] = Form.useForm()
  const { isView } = props

  const requiredRule = [{ required: true }]

  const onFinish = async (values) => {
    try {
      if (!message) {
        notification.error('Campo de dúvida é obrigatório.')
      } else {
        const data = {
          message,
          disciplineId: values.disciplineId,
          subject: values.subject,
        }

        await api.post(data)
        notification.success('Cadastrado com sucesso.')
        form.resetFields()
        setMessage(null)
      }

      setLoadingSubmit(false)
    } catch (err) {
      //
    }
  }

  const getDiscipline = async () => {
    try {
      const response = await api.getDiscipline()
      const { data } = response

      setDiscipline(data)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDiscipline()
  }, [])

  return (
    <>
      {loading && <Skeleton />}

      {!loading && !!discipline.length > 0 ? (
        <S.DisciplineWrapper>
          <Layout className="min-height-empty">
            <Text fontSize="32px" fontWeight="bold" color="#434343">
              Tirar uma dúvida
            </Text>

            <S.StyledContentWrapper className="take-question">
              <Form
                form={form}
                layout="vertical"
                initialValues={{ active: true }}
                onFinish={onFinish}
              >
                <Row gutter={16} align="middle">
                  <Col xs={24} md={24}>
                    <Form.Item
                      name="subject"
                      label="Título"
                      rules={requiredRule}
                    >
                      <Input
                        placeholder="Título"
                        maxLength={50}
                        disabled={isView}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {!!discipline && (
                  <Row>
                    <Col xs={24} md={8}>
                      <Form.Item
                        name="disciplineId"
                        label="Disciplina"
                        rules={requiredRule}
                      >
                        <Select
                          size="large"
                          placeholder="Disciplina"
                          defaultValue={null}
                          bordered={false}
                        >
                          {discipline.map((item) => {
                            return (
                              <Select.Option key={item.id} value={item.id}>
                                {item.name}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col xs={24} md={24}>
                    <Form.Item name="message" label="Dúvida">
                      <EditorTinyAlternatives
                        onEditorChange={(content) => {
                          setMessage(content)
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />

                <Row justify="end">
                  <Space>
                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={loadingSubmit}
                    >
                      Confirmar
                    </Button>
                  </Space>
                </Row>
              </Form>
            </S.StyledContentWrapper>
          </Layout>
        </S.DisciplineWrapper>
      ) : (
        !loading && (
          <Empty
            description={
              <Text fontSize="16px">
                A sua assinatura não possui acesso ao fórum de dúvidas.
              </Text>
            }
          />
        )
      )}
    </>
  )
}

export default StudentFormTakeQuestions
