import { Layout, Menu } from 'antd'
import styled from 'styled-components'

const { Header, Content } = Layout
const { SubMenu } = Menu

const StyledLayout = styled(Layout)`
  @media (max-width: 768px) {
    max-width: 35%;
  }
`

const StyledHeader = styled(Header)`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ant-menu-item.ant-menu-item-only-child,
  .ant-menu-submenu.ant-menu-submenu-horizontal {
    color: #fff;
    font-weight: bold;
  }
`

const StyledMenu = styled(Menu)`
  width: 100%;
`
const StyledSubMenu = styled(SubMenu)``

const StyledContent = styled(Content)``

const StyledLink = styled.a``

export {
  StyledLayout,
  StyledHeader,
  StyledMenu,
  StyledSubMenu,
  StyledContent,
  StyledLink,
}
