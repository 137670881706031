import styled, { css } from 'styled-components'
import { Select as AntdSelect } from 'antd'

const Select = styled(AntdSelect)`
  ${({ theme }) => css`
    width: 100%;

    .ant-select-selection-item {
      font-weight: bold;
    }

    &.box-select {
      padding: 3px 20px 3px 10px;
      border: 1px solid #e8e8e8;
      border-radius: 40px;
      box-shadow: 0px 6px 6px #0000000a;
      background: ${theme.colors.white};
    }

    &.box-select .ant-select-selector .ant-select-selection-item {
      color: ${theme.colors.placeholder};
      font-size: ${theme.font.sizes.x14};
      font-weight: normal;
    }
  `}
}
`

export default Select
