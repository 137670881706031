import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import NewPasswordFormComponent from '../components/newpassword-form.component'

import services from '../newpassword.services'

import notification from '~/shared/Notification'

function NewPasswordContainer() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const [codeUser, setCodeUser] = useState('')

  useEffect(() => {
    const parameters = new URLSearchParams(window.location.search)
    if (parameters) {
      setCodeUser(parameters.get('codigo'))
    }
  }, [])

  async function onSubmit(values) {
    const data = {
      resetPasswordCode: values.resetPasswordCode,
      newPassword: values.newPassword,
    }

    setLoading(true)

    try {
      await services.newPassword(data)
      notification.success('Senha alterada com sucesso.')

      history.push('/login')
    } catch {
      setLoading(false)
    }
  }

  return (
    <NewPasswordFormComponent
      loading={loading}
      onSubmit={onSubmit}
      codeUser={codeUser != null ? codeUser : null}
    />
  )
}

export default NewPasswordContainer
