import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import useData from '~/pages/subscription/hooks/useData'
import useAuth from '~/hooks/useAuth'

import * as S from './default-menu-styles'

function DefaultMenuComponent() {
  const history = useHistory()
  const auth = useAuth()
  const { allowLiveFaq } = useData()

  const appItems = [
    {
      key: '/painel',
      label: 'Painel',
      hasChildren: false,
      children: null,
    },
    {
      key: '/simulado',
      label: 'Simulados',
      hasChildren: false,
      children: null,
    },
    {
      key: '/duvidas',
      label: 'Dúvidas',
      hasChildren: false,
      children: null,
    },
    // { key: '', label: 'Tirar uma dúvida', hasChildren: false, children: null },
    {
      key: '/plantoes',
      label: 'Plantões',
      hasChildren: false,
      children: null,
      hidden: !allowLiveFaq && auth.user.studentFreemium,
    },
    // {
    //   key: '/extrato/assinatura',
    //   label: 'Extrato Assinaturas',
    //   hasChildren: false,
    //   children: null,
    // },
    // {
    //   key: '',
    //   label: 'Fale conosco',
    //   hasChildren: true,
    //   children: [
    //     {
    //       key: '',
    //       label: 'Entre em Contato',
    //       target: '',
    //     },
    //     {
    //       key: 'https://www.corujasabia.com.br/termos-de-uso/',
    //       label: 'Termos de Uso',
    //       target: '_blank',
    //     },
    //     {
    //       key: 'https://www.corujasabia.com.br/cancelamento/',
    //       label: 'Cancelamento',
    //       target: '_blank',
    //     },
    //   ],
    // },
  ]

  const onClick = (route, target) => {
    if (target) {
      window.open(route, target)
    } else {
      history.push(route)
    }
  }

  const subMenu = ({ children, label }) => {
    return (
      <S.StyledSubMenu title={label}>
        {children.map((menu) =>
          menu.hasChildren && !menu.hidden ? (
            <Fragment key={menu.key}>{subMenu(menu)}</Fragment>
          ) : (
            !menu.hidden && (
              <S.StyledMenu.Item
                onClick={() => {
                  if (menu.key) onClick(menu.key, menu.target)
                }}
                key={menu.key}
              >
                {menu.label}
              </S.StyledMenu.Item>
            )
          ),
        )}
      </S.StyledSubMenu>
    )
  }

  const renderMenu = (menus) => {
    return menus.map((menu) =>
      menu.hasChildren && !menu.hidden ? (
        <Fragment key={menu.key}>{subMenu(menu)}</Fragment>
      ) : (
        !menu.hidden && (
          <S.StyledMenu.Item
            onClick={() => {
              if (menu.key) onClick(menu.key, menu.target)
            }}
            key={menu.key}
          >
            {menu.label}
          </S.StyledMenu.Item>
        )
      ),
    )
  }

  return (
    <S.StyledLayout>
      <S.StyledHeader>
        <S.StyledMenu
          theme="dark"
          mode="horizontal"
          selectedKeys={[history.location.pathname]}
        >
          {appItems && renderMenu(appItems)}
        </S.StyledMenu>
      </S.StyledHeader>
    </S.StyledLayout>
  )
}

export default DefaultMenuComponent
